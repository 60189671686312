import React from 'react';

export const SpinnerIcon = ({
  size = 'medium',
  type = 'primary',
  className,
}: {
  size?: 'medium' | 'small';
  type?: 'primary' | 'secondary';
  className?: string;
}) => {
  return (
    <div className={`spinner spinner-${type} spinner-${size} ${className ? className : ''}`}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};
