import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { PieChartItem } from 'core/types';

export const PieChart = ({
  data,
  width = 205
}: {
  data: PieChartItem[];
  width?: number;
}) => {
  const options = {
    accessibility: {
      enabled: false
    },
    credits: {
      enabled: false
    },
    legend: {
      enabled: false
    },
    chart: {
      type: 'pie',
      width: width,
      height: width,
      backgroundColor: 'none',
      border: 'none'
    },
    title: {
      text: '',
      disable: true
    },
    tooltip: {
      valueSuffix: '%'
    },
    plotOptions: {
      pie: {
        shadow: false
      },
      series: {
        borderWidth: 0,
        colorByPoint: true,
        type: 'pie',
        size: '100%',
        innerSize: '50%',
        dataLabels: {
          enabled: false,
          crop: false,
          style: {
            fontWeight: 'bold',
            fontSize: '16px'
          },
          connectorWidth: 0
        }
      }
    },
    series: [
      {
        name: 'Percentage',
        colorByPoint: true,
        data: data
      }
    ]
  };

  return (
    <>
      <div className="relative">
        <div className="relative z-10">
          <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
      </div>
    </>
  );
};
