import React from 'react';

type Props = {
  className?: string;
};

export const ArrowDownBig = ({ className }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      className={className}
    >
      <path d="M13 17.3155L6.75 10.8423H19.25L13 17.3155Z" fill="#F2F4F7" />
    </svg>
  );
};
