import React, { useEffect, useState } from 'react';

import Highcharts, { Pointer } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

type TimeChartProps = {
  data?: any;
  height?: number;
  change?: number;
  last_price?: string;
};
export const TimeChart = ({ data, change, height = 80, last_price }: TimeChartProps) => {
  const [options, setOptions] = useState<any>({
    accessibility: {
      enabled: false
    },
    chart: {
      height,
      backgroundColor: 'none'
    },
    title: {
      text: '',
      disable: true
    },
    legend: {
      enabled: false
    },
    xAxis: [
      {
        visible: false
      }
    ],
    yAxis: [
      {
        visible: false
      }
    ],
    credits: {
      enabled: false
    }
  });
  useEffect(() => {
    let dataChart: any = [];

    let colorTop = '#FFC92F';
    let colorBottom = '#FFC92F';
    if (Number(change) < 0) {
      colorTop = '#F04438';
      colorBottom = '#D92D20';
    } else if (Number(change) > 0) {
      colorTop = '#00C802';
      colorBottom = '#61CB2F';
    }

    data?.forEach((item: any) => {
      dataChart.push([item.x, item.y]);
    });
    if (data?.length <= 0) {
      dataChart = [0, 0, 0];
    }

    setOptions({
      accessibility: {
        enabled: false
      },
      chart: {
        height,
        backgroundColor: 'none'
      },
      title: {
        text: '',
        disable: true
      },
      plotOptions: {
        area: {
          fillColor: {
            linearGradient: {
              x1: 0.69,
              y1: -0.75,
              x2: 0.69,
              y2: 1
            },
            stops: [
              [0, colorTop],
              [1, Highcharts.color(colorBottom).setOpacity(0).get('rgba')]
            ]
          },
          marker: {
            radius: 2,
            enabled: false
          },
          lineWidth: 2,
          lineColor: colorTop,
          states: {
            hover: {
              lineWidth: 2
            }
          },
          threshold: null
        }
      },
      legend: {
        enabled: false
      },
      xAxis: [
        {
          visible: false
        }
      ],
      yAxis: [
        {
          visible: false
        }
      ],
      credits: {
        enabled: false
      },
      tooltip: {
        /*@ts-ignore*/
        formatter: function () {
          /*@ts-ignore*/
          return this?.y || 0;
        },
        backgroundColor: colorTop,
        borderRadius: '6',
        borderColor: colorTop,
        padding: 4,
        shadow: false,
        style: {
          color: '#ffffff',
          fontSize: '12px',
          border: 'none'
        }
      },
      series: [
        {
          type: 'area',
          data: dataChart,
          linecap: 'round'
        }
      ]
    });
  }, [last_price, data]);

  return (
    <>
      <div className="relative">
        <div className="relative z-10">{<HighchartsReact highcharts={Highcharts} options={options} />}</div>
      </div>
    </>
  );
};
