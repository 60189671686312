import React from 'react';
import img505 from 'assets/svg/505.svg';

export const Page505 = () => {
  return (
    <>
      <div className="flex items-center my-20">
        <div className="w-1/2 pt-10">
          <h2 className="text-[100px] font-bold text-primary">505</h2>
          <p className="text-[50px] font-medium text-primary">
            Không tìm thấy trang
          </p>
        </div>
        <div className="w-1/2">
          <img src={img505} alt="" className="max-w-full" />
        </div>
      </div>
    </>
  );
};
