import React, { useEffect, useState, useRef, useCallback } from 'react';
import {
  OnboardingCard,
  CameraLivenessCheck,
  AddBankAccount,
  CreatePinCode,
  Signature,
  ContractNormal,
} from 'components/onboarding';
import CccdFront from 'assets/images/cccd_front.png';
import CccdBackside from 'assets/images/cccd_backside.png';
import PhotoIcon from 'assets/images/camera_icon.png';
import CameraImg from 'assets/images/camera_img.png';
import FaceIcon from 'assets/images/face_icon.png';
import CccdError1 from 'assets/images/cccd-error-1.png';
import CccdError2 from 'assets/images/cccd-error-2.png';
import CccdError3 from 'assets/images/cccd-error-3.png';
import CccdError4 from 'assets/images/cccd-error-4.png';
import CccdError5 from 'assets/images/cccd-error-5.png';
import { Button } from 'components';
import Webcam from 'react-webcam';
import { OnboardingService } from 'core/services';
import { getUser } from 'core/services/restful.service';
import Switch from 'react-switch';
import { useForm } from 'react-hook-form';
import Select, { type DropdownIndicatorProps, components } from 'react-select';
import TooltipArrowIcon from 'assets/images/tooltip_arrow_icon.png';
import { DatePicker } from 'antd';
import { RiCloseLine } from 'react-icons/ri';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { GENERAL_SERVER_ERROR, STEP_EKYC } from 'core/constant';
import { RiArrowDownSFill, RiInformationLine } from 'react-icons/ri';
import dayjs from 'dayjs';
import { Spinner } from 'components/spinner/Spinner';
import { SpinnerIcon } from 'components/spinner/SpinnerIcon';

const videoConstraints = {
  width: 480,
  height: 270,
  facingMode: 'environment',
};

const IMAGE_TYPE = {
  FRONT: 'FRONT',
  BEHIND: 'BEHIND',
};

const IdentificationDocuments = () => {
  const webcamRef = useRef<any>(null);
  const [isCameraPermission, setIsCameraPermission] = useState(true);
  const [dataID, setDataID] = useState<any>();
  const [imageFace, setImageFace] = useState<string>();
  const [imgFrontSrc, setImgFrontSrc] = useState<{
    path: string;
    url: string;
  }>();
  const [imgBehindSrc, setImgBehindSrc] = useState<{
    path: string;
    url: string;
  }>();
  const [isSubmitAddBank, setIsSubmitAddBank] = useState(false);
  const [isSubmitSign, setIsSubmitSign] = useState(false);
  const [showCamera, setShowCamera] = useState<string>();
  const [showCameraLivenessCheck, setShowCameraLivenessCheck] = useState(false);
  const [genderSelected, setgenderSelected] = useState<any>();

  const [isShowTaxToolTip, setIsShowTaxToolTip] = useState<boolean>(false);
  const [isShowEmailToolTip, setIsShowEmailToolTip] = useState<boolean>(false);

  const [expireDateSelect, setExpireDateSelect] = useState<any>();

  const [verifyIdStatus, setVerifyIdStatus] = useState<
    'undefined' | 'loading' | 'success' | 'error'
  >('undefined');

  const [livenessCheckStatus, setLivenessCheckStatus] = useState<
    'undefined' | 'loading' | 'success' | 'error'
  >('undefined');

  const [errorMessage, setErrorMessage] = useState<any>('');
  const [searchParams, setSearchParams] = useSearchParams();

  const userInit = getUser();
  const step = searchParams.get('step');

  const {
    register,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onBlur',
  });

  const [isSameAddress, setIsSameAddress] = useState(true);

  const setValuesForm = (data: any) => {
    if (data) {
      setValue('name', data?.full_name);
      setValue('dob', data?.dob);
      setValue('hometown', data?.hometown);
      setValue('cccd', data?.cccd);
      setValue('ngaycap', data?.issue_date);
      setValue('noicap', data?.issue_place);
      setValue('expireDate', data?.expire_date);
      setValue('phone_number', data?.phone_number);
      setValue('address_permanent', data?.address_permanent);
      setValue('address_contact', data?.address_permanent);
      if (data?.gender === 'F') {
        setgenderSelected({
          value: 'F',
          label: 'Nữ',
        });
        setValue('gender', data?.gender);
      }
      if (data?.gender === 'M') {
        setgenderSelected({
          value: 'M',
          label: 'Nam',
        });
        setValue('gender', data?.gender);
      }
    }
  };

  useEffect(() => {
    let constraints = { video: true };
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then(stream => {})
      .catch(err => {
        if (err.name == 'NotAllowedError') {
          setIsCameraPermission(false);
        }
      });
  }, []);

  const openCameraFront = async () => {
    setShowCamera(IMAGE_TYPE.FRONT);
  };

  const openCameraBehind = async () => {
    setShowCamera(IMAGE_TYPE.BEHIND);
  };

  const captureFrontId = useCallback(async () => {
    const cam = webcamRef as any;
    const imageSrc = cam?.current?.getScreenshot();
    const urlRes = await OnboardingService.fetchUrlUpload('CccdFront');
    const upload_url = urlRes?.url;
    const base64EncodedImageString = imageSrc.replace(/^data:image\/\w+;base64,/, '');
    const fileBuffer = Buffer.from(base64EncodedImageString, 'base64');
    fetch(upload_url, {
      method: 'PUT',
      body: fileBuffer,
    })
      .then(async () => {
        setImgFrontSrc({
          url: urlRes?.url_image,
          path: urlRes?.file_path,
        });
        setShowCamera('');
      })
      .catch(() => {
        console.log('Upload failed');
      });
  }, [webcamRef, setImgFrontSrc]);

  const captureBackId = useCallback(async () => {
    const cam = webcamRef as any;
    const imageSrc = cam?.current?.getScreenshot();
    const urlRes = await OnboardingService.fetchUrlUpload('CccdBack');
    const upload_url = urlRes?.url;
    const base64EncodedImageString = imageSrc.replace(/^data:image\/\w+;base64,/, '');
    const fileBuffer = Buffer.from(base64EncodedImageString, 'base64');
    fetch(upload_url, {
      method: 'PUT',
      body: fileBuffer,
    })
      .then(async () => {
        setImgBehindSrc({
          url: urlRes?.url_image,
          path: urlRes?.file_path,
        });
        setShowCamera('');
      })
      .catch(() => {
        console.log('Upload failed');
      });
  }, [webcamRef, setImgBehindSrc]);

  const nextStepInfoId = async () => {
    if (imgBehindSrc && imgFrontSrc) {
      const transactionId = sessionStorage.getItem('TRANSACTION_ID');
      const data = {
        image_front: imgFrontSrc.path,
        image_back: imgBehindSrc.path,
        transaction_id: transactionId ? transactionId : undefined,
      };
      setVerifyIdStatus('loading');
      const res: any = await OnboardingService.fetchInfoId(data);
      if (res?.data) {
        if (res?.data?.transaction_id) {
          sessionStorage.setItem('TRANSACTION_ID', res?.data?.transaction_id);
        }
        setDataID(res?.data);
        setValuesForm(res?.data);
      }
      if (res?.status?.toString() === 'Success') {
        setVerifyIdStatus('success');
        setTimeout(() => {
          setSearchParams({ step: '1' });
        }, 300);
      }
      if (res?.status?.toString() === 'Fail') {
        setVerifyIdStatus('error');
        setErrorMessage(res?.error_message?.split('|')[1]);
      }
    }
  };

  const getTitleStep = () => {
    if (step === '4') {
      return 'Hợp đồng mở tài khoản';
    }
    if (step === '5' || step === '6') {
      return 'Khách hàng ký tên';
    }
    return 'Xác thực tài khoản';
  };

  const isDisableNext = () => {
    if ((!step || step === STEP_EKYC.ID_CHECK) && (!imgFrontSrc || !imgFrontSrc)) {
      return true;
    }

    if (step === STEP_EKYC.FACE_CHECK && livenessCheckStatus !== 'success') {
      return true;
    }

    return false;
  };

  const isDisablePrev = () => {
    if (step === STEP_EKYC.ID_CHECK) {
      return true;
    }

    if (step === STEP_EKYC.ADD_BANK) {
      return true;
    }

    if (step === STEP_EKYC.SIGNATURE) {
      return true;
    }

    if (step === STEP_EKYC.CREATE_PIN) {
      return true;
    }

    return false;
  };

  const onLivenessCheck = async (images: any[]) => {
    setImageFace(`data:image/jpeg;base64,${images?.[0]?.image}`);
    setLivenessCheckStatus('loading');
    let imagesData: {
      image: string;
      index: number;
    }[] = [];

    for (const img of images) {
      const urlRes = await OnboardingService.fetchUrlUpload('FaceLiveness');
      const upload_url = urlRes?.url;
      const fileBuffer = Buffer.from(img?.image, 'base64');
      await fetch(upload_url, {
        method: 'PUT',
        body: fileBuffer,
      });
      const imageData = {
        image: urlRes?.file_path,
        index: img?.index,
      };
      imagesData.push(imageData);
    }

    const transactionId = sessionStorage.getItem('TRANSACTION_ID') || '';
    const data = {
      transaction_id: transactionId,
      images: [...imagesData],
    };

    const res: any = await OnboardingService.checkFace(data);
    if (res?.status?.toString() === 'Success') {
      setLivenessCheckStatus('success');
    }
    if (res?.status?.toString() === 'Fail') {
      setLivenessCheckStatus('error');
      setErrorMessage(
        res?.error_message
          ? res?.error_message?.split('|')[1]
          : GENERAL_SERVER_ERROR
      );
    }
  };

  const validateEpireDate = (date: string): boolean => {
    const patternDate = /^\d{2}\/\d{2}\/\d{4}$/g;
    if (!date) {
      setError('expireDate', {
        type: 'required',
        message: 'Vui lòng nhập ngày hết hạn',
      });
      return false;
    }

    if (date && !patternDate.test(date)) {
      setError('expireDate', {
        type: 'pattern',
        message: 'Ngày hết hạn không đúng định dạng DD/MM/YYYY',
      });
      return false;
    }

    return true;
  };

  const onNextStep = async () => {
    if (step === STEP_EKYC.ID_CHECK) {
      nextStepInfoId();
      return;
    }
    if (step === STEP_EKYC.ID_INFO) {
      clearErrors();
      const address = getValues('address_contact');
      const expireDate = !dataID?.expire_date
        ? getValues('expireDate')
          ? dayjs(new Date(getValues('expireDate'))).format('DD/MM/YYYY')
          : ''
        : dataID?.expire_date;
      const email = getValues('email');
      const gender = getValues('gender');
      const taxNumber = getValues('tax_number');
      const transactionId = sessionStorage.getItem('TRANSACTION_ID') || '';
      let isValidData = true;
      if (!gender) {
        setError('gender', {
          type: 'required',
        });
        isValidData = false;
      }
      if (!address) {
        setError('address', {
          type: 'required',
        });
        isValidData = false;
      }
      if (!expireDate) {
        setError('expireDate', {
          type: 'required',
          message: 'Vui lòng nhập ngày hết hạn',
        });
        isValidData = false;
      }

      if (!validateEpireDate(expireDate)) {
        isValidData = false;
      }

      const patternEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

      if (!email) {
        setError('email', {
          type: 'required',
        });
        isValidData = false;
      }
      if (email && !patternEmail.test(email)) {
        setError('email', {
          type: 'pattern',
          message: 'Email không đúng định dạng, vui lòng kiểm tra lại.',
        });
        isValidData = false;
      }

      if (taxNumber && !isValidTaxCode(taxNumber)) {
        setError('tax_number', {
          type: 'pattern',
          message: 'Mã số thuế không đúng định dạng, vui lòng kiểm tra lại.',
        });
        isValidData = false;
      }

      if (!isValidData) {
        return;
      }

      const params = {
        transaction_id: transactionId,
        address: address,
        gender: gender,
        expire_date: expireDate.split('/').reverse().join('-'),
        email: email,
        tax_number: taxNumber,
      };

      if (isValidData) {
        await OnboardingService.updateProfile(params);
      }
      const nextStep = step ? Number(step) + 1 : 1;
      setSearchParams({ step: `${nextStep}` });
      return;
    }
    if (step === STEP_EKYC.ADD_BANK) {
      setIsSubmitAddBank(true);
      return;
    }
    if (step === STEP_EKYC.SIGNATURE) {
      setIsSubmitSign(true);
      return;
    }

    const nextStep = step ? Number(step) + 1 : 1;
    setSearchParams({ step: `${nextStep}` });
  };
  const onPrevStep = async () => {
    const nextStep = step ? Number(step) - 1 : 0;
    setSearchParams({ step: `${nextStep}` });
  };

  const pureTaxCode = (value: string) => {
    return value.replaceAll('-', '');
  };

  const isValidTaxCode = (value: string) => {
    const formatedStr = pureTaxCode(value);
    if (!Number(formatedStr)) {
      return false;
    }
    if (formatedStr.length !== 10 && formatedStr.length !== 13) {
      return false;
    }
    // var n1n2 = formatedStr.substring(0, 2);
    const n3n4n5n6n7n8n9 = formatedStr.substring(2, 9);
    if (n3n4n5n6n7n8n9 === '0000000') return false;
    if (formatedStr.length === 10) return true;
    // var n10 = formatedStr[9];
    const n11n12n13 = formatedStr.substring(10);
    return n11n12n13 !== '000';
  };

  const DropdownIndicator: React.FC<DropdownIndicatorProps> = props => {
    return (
      <components.DropdownIndicator {...props}>
        <RiArrowDownSFill size={24} color="#FFFFFF" />
      </components.DropdownIndicator>
    );
  };

  const handleChangeDate = (date: any) => {
    setExpireDateSelect(date);
    setValue('expireDate', date);
    setError('expireDate', {});
  };

  const renderContent = () => {
    if (step === STEP_EKYC.ID_CHECK) {
      return (
        <div className="flex flex-col">
          <div className="text-center">
            <h2 className=" text-xl font-bold leading-[26px]">Giấy tờ tùy thân</h2>
            <div className=" text-base font-normal leading-[22px] mt-1">
              Vui lòng chụp ảnh gốc CMND/CCCD
            </div>
          </div>
          <div className="order-2 flex gap-6 justify-center mt-8 min-h-[180px] pb-4 lg:order-1">
            <div>
              <div
                className={`border border-[#D0D5DD] rounded-[6px] w-[320px] h-[180px] flex items-center justify-center cursor-pointer p-4 ${
                  verifyIdStatus === 'error' ? 'border-[#F04438]' : ''
                }`}
                onClick={openCameraFront}
              >
                {!imgFrontSrc && (
                  <div>
                    <img src={CccdFront} alt="CccdFront" className="w-[160px] h-auto" />
                  </div>
                )}
                {!!imgFrontSrc && (
                  <div>
                    <img
                      src={imgFrontSrc.url}
                      width={250}
                      height={148}
                      alt="CccdFront"
                      className="w-[250px] h-[148px] rounded object-contain"
                    />
                  </div>
                )}
              </div>
              {verifyIdStatus === 'undefined' && (
                <div
                  className="text-primary font-bold text-sm leading-[24px] mt-3 text-center takePicture cursor-pointer"
                  onClick={openCameraFront}
                >
                  Chụp mặt trước
                </div>
              )}
              {verifyIdStatus === 'error' && (
                <div className="mt-3 flex items-center justify-center gap-2">
                  <img src={PhotoIcon} width={20} height={16} alt="PhotoIcon" />
                  <div
                    className="text-primary font-bold text-sm leading-[24px] takePicture cursor-pointer"
                    onClick={openCameraFront}
                  >
                    Chụp lại
                  </div>
                </div>
              )}
            </div>
            <div>
              <div
                className={`border border-[#D0D5DD] rounded-[6px] w-[320px] h-[180px] flex items-center justify-center cursor-pointer p-4 ${
                  verifyIdStatus === 'error' ? 'border-[#F04438]' : ''
                }`}
                onClick={openCameraBehind}
              >
                {!imgBehindSrc && (
                  <div>
                    <img src={CccdBackside} alt="CccdBackside" className="w-[160px] h-auto" />
                  </div>
                )}
                {!!imgBehindSrc && (
                  <div>
                    <img
                      src={imgBehindSrc.url}
                      width={250}
                      height={148}
                      alt="CccdBackside"
                      className="w-[250px] h-[148px] rounded object-contain"
                    />
                  </div>
                )}
              </div>
              {verifyIdStatus === 'undefined' && (
                <div
                  className="text-primary font-bold text-sm leading-[24px] mt-3 text-center takePicture cursor-pointer"
                  onClick={openCameraBehind}
                >
                  Chụp mặt sau
                </div>
              )}
              {verifyIdStatus === 'error' && (
                <div className="mt-3 flex items-center justify-center gap-2">
                  <img src={PhotoIcon} width={20} height={16} alt="PhotoIcon" />
                  <div
                    className="text-primary font-bold text-sm leading-[24px] takePicture cursor-pointer"
                    onClick={openCameraBehind}
                  >
                    Chụp lại
                  </div>
                </div>
              )}
            </div>
          </div>
          <div
            className={`max-w-[664px] mx-auto mb-[42px] order-1 lg:order-2 ${
              verifyIdStatus === 'loading' ? 'mt-[1px]' : errorMessage ? 'mt-[15px]' : 'mt-[42px]'
            }`}
          >
            {verifyIdStatus === 'error' && (
              <div className="text-sm font-medium text-[#F04438] text-center leading-[22px] mt-[10px]">
                {errorMessage}
              </div>
            )}
            {verifyIdStatus === 'loading' && (
              <div className="flex flex-col items-center mt-2">
                <SpinnerIcon className="scale-90" />
                <div className="text-sm font-medium leading-[22px] mt-2">
                  Đang xác thực hình ảnh
                </div>
              </div>
            )}
            {verifyIdStatus === 'success' && (
              <div className="flex flex-col items-center mt-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="33"
                  height="32"
                  viewBox="0 0 33 32"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.5 16C0.5 7.16344 7.66344 0 16.5 0C25.3366 0 32.5 7.16344 32.5 16C32.5 24.8366 25.3366 32 16.5 32C7.66344 32 0.5 24.8366 0.5 16ZM15.5865 22.8494L24.7996 11.3329L23.1338 10.0003L15.2803 19.8172L9.71625 15.1805L8.35052 16.8194L15.5865 22.8494Z"
                    fill="#61CB2F"
                  />
                </svg>
                <div className="text-sm font-medium leading-[22px] mt-2">
                  Hoàn tất xác thực giấy tờ tùy thân
                </div>
              </div>
            )}
            <div className="bg-black rounded border border-[#0BA5EC] border-opacity-[32%] p-4 mt-[11px]">
              <span className="text-xs leading-[18px] text-[#0BA5EC]">
                Lưu ý khi chụp ảnh CCCD/CMND:
              </span>
              <div className="flex">
                <span className="text-xs leading-[18px] text-[#0BA5EC] w-4">1.</span>
                <span className="text-xs leading-[18px] text-[#0BA5EC] flex-1">
                  Giấy tờ còn hạn sử dụng, không sử dụng bản scan, photocopy.
                </span>
              </div>
              <div className="flex">
                <span className="text-xs leading-[18px] text-[#0BA5EC] w-4">2.</span>
                <span className="text-xs leading-[18px] text-[#0BA5EC] flex-1">
                  Nghiêm cấm việc sử dụng CCCD/CMND giả mạo, không chính chủ. Khách hàng chịu hoàn
                  toàn trách nhiệm trước pháp luật về hình ảnh cung cấp.
                </span>
              </div>
            </div>
            <div className="flex gap-x-6 mt-5">
              <div className="flex-1">
                <img src={CccdError1} alt="Ảnh CCCD trong bọc nilon" className="w-[160px] h-auto" />
                <div className="text-[10px] text-gray-500 font-semibold text-center px-3 mt-1">
                  Ảnh CCCD trong bọc nilon
                </div>
              </div>
              <div className="flex-1">
                <img src={CccdError2} alt="Ảnh CCCD bị mờ" className="w-[160px] h-auto" />
                <div className="text-[10px] text-gray-500 font-semibold text-center px-3 mt-1">
                  Ảnh CCCD bị
                  <br /> mờ
                </div>
              </div>
              <div className="flex-1">
                <img src={CccdError3} alt="Ảnh CCCD bị tối" className="w-[160px] h-auto" />
                <div className="text-[10px] text-gray-500 font-semibold text-center px-3 mt-1">
                  Ảnh CCCD bị tối
                </div>
              </div>
              <div className="flex-1">
                <img src={CccdError4} alt="Ảnh CCCD bị chói sáng" className="w-[160px] h-auto" />
                <div className="text-[10px] text-gray-500 font-semibold text-center px-3 mt-1">
                  Ảnh CCCD bị <br />
                  chói sáng
                </div>
              </div>
              <div className="flex-1">
                <img
                  src={CccdError5}
                  alt="Không hiển thị đầy đủ các góc cạnh của CCCD"
                  className="w-[160px] h-auto"
                />
                <div className="text-[10px] text-gray-500 font-semibold text-center px-3 mt-1">
                  Không hiển thị đầy đủ các góc cạnh của CCCD
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    if (step === STEP_EKYC.ID_INFO) {
      return (
        <>
          <div className="text-center">
            <h2 className=" text-xl font-bold leading-[26px]">Xác nhận thông tin</h2>
            <div className=" text-base font-normal leading-[22px] mt-1">
              Vui lòng nhập thông tin cá nhân để hoàn tất xác nhận tài khoản
            </div>
          </div>
          <form noValidate autoComplete="off">
            <div className="p-5 rounded-[6px] my-6 lg:grid lg:grid-cols-2 lg:gap-x-6 lg:gap-y-3">
              <div className="flex flex-col gap-3">
                <div className="form-input">
                  <label htmlFor="" className="text-sm font-normal leading-5">
                    Họ và tên
                  </label>
                  <input
                    type="text"
                    className={`form-input__input ${errors.pin_code && 'form-input__input_error'}`}
                    placeholder="Nhập họ và tên"
                    {...register('name', {
                      required: true,
                    })}
                    readOnly
                  />
                </div>
                <div className="form-input">
                  <label htmlFor="" className="text-sm font-normal leading-5">
                    Nguyên quán
                  </label>
                  <input
                    type="text"
                    className={`form-input__input ${errors.pin_code && 'form-input__input_error'}`}
                    placeholder="Nhập nguyên quán"
                    {...register('hometown', {
                      required: false,
                    })}
                    readOnly
                  />
                </div>
                <div className="form-input">
                  <label htmlFor="" className="text-sm font-normal leading-5">
                    Ngày cấp
                  </label>
                  <input
                    type="text"
                    className={`form-input__input ${errors.pin_code && 'form-input__input_error'}`}
                    placeholder="Nhập ngày cấp"
                    {...register('ngaycap', {
                      required: true,
                    })}
                    readOnly
                  />
                </div>
                <div className="form-input">
                  <label htmlFor="" className="text-sm font-normal leading-5">
                    Giới tính
                  </label>
                  <Select
                    className="react-select"
                    classNamePrefix="select"
                    placeholder="Chọn giới tính"
                    options={[
                      {
                        value: 'M',
                        label: 'Nam',
                      },
                      {
                        value: 'F',
                        label: 'Nữ',
                      },
                    ]}
                    isDisabled={dataID?.gender === 'F' || dataID?.gender === 'M'}
                    onChange={v => {
                      setgenderSelected(v);
                      setValue('gender', v?.value);
                      setError('gender', {});
                    }}
                    value={genderSelected}
                    components={{ DropdownIndicator }}
                  />
                </div>
                <div className="form-input">
                  <label
                    htmlFor=""
                    className="text-sm font-normal leading-5 flex items-center gap-2 relative"
                  >
                    <span>
                      Email<span className="text-carminePink">*</span>
                    </span>
                    <button
                      onMouseOver={() => setIsShowEmailToolTip(true)}
                      onMouseEnter={() => setIsShowEmailToolTip(true)}
                      onMouseOut={() => setIsShowEmailToolTip(false)}
                    >
                      <RiInformationLine className="text-sm" />
                    </button>
                    {isShowEmailToolTip && (
                      <>
                        <div
                          className="absolute z-10 left-[63px] w-[362px] h-auto top-[-55px]"
                          onMouseEnter={() => setIsShowEmailToolTip(true)}
                          onMouseOver={() => setIsShowEmailToolTip(true)}
                          onMouseLeave={() => setIsShowEmailToolTip(false)}
                          onMouseOut={() => setIsShowEmailToolTip(false)}
                        >
                          <div className="bg-[#F2F4F7] relative ml-[15px] text-sm font-normal p-4 rounded-[6px] text-[#344054] w-[352px] h-auto shadow-tooltip">
                            <img
                              src={TooltipArrowIcon}
                              alt="TooltipArrowIcon"
                              className=" absolute left-[-15px] top-[58px] w-[15px] h-[17px]"
                            />
                            <div>
                              Thông tin email được yêu cầu để tạo tại khoản giao dịch chứng khoán,
                              và sẽ được dùng để thông báo kết quả giao dịch và các báo cáo liên
                              quan của Quý khách một cách nhanh chóng nhất.
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </label>
                  <input
                    type="text"
                    className={`form-input__input ${errors.pin_code && 'form-input__input_error'}`}
                    placeholder="Nhập email"
                    {...register('email', {
                      required: true,
                      validate: v => {
                        const patternEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
                        if (!patternEmail.test(v)) {
                          return 'Email không đúng định dạng, vui lòng kiểm tra lại.';
                        }
                      },
                    })}
                  />

                  {errors.email?.type === 'pattern' && (
                    <p className="text-sm font-normal leading-5 text-carminePink mt-1">
                      {errors.email?.message as string}
                    </p>
                  )}
                  {errors.email?.type === 'validate' && (
                    <p className="text-sm font-normal leading-5 text-carminePink mt-1">
                      {errors.email?.message as string}
                    </p>
                  )}
                  {errors.email?.type === 'required' && (
                    <p className="text-sm font-normal leading-5 text-carminePink mt-1">
                      Vui lòng nhập email
                    </p>
                  )}
                </div>
                <div className="form-input">
                  <label
                    htmlFor=""
                    className="text-sm font-normal leading-5 flex items-center gap-2 relative"
                  >
                    <span>Mã số thuế</span>
                    <button
                      data-tooltip-id="my-tooltip-tax_number"
                      onMouseOver={() => setIsShowTaxToolTip(true)}
                      onMouseEnter={() => setIsShowTaxToolTip(true)}
                      onMouseOut={() => setIsShowTaxToolTip(false)}
                    >
                      <RiInformationLine className="text-sm" />
                    </button>
                    {isShowTaxToolTip && (
                      <>
                        <div
                          className="absolute z-10 left-[95px] w-[362px] h-auto top-[-80px]"
                          onMouseEnter={() => setIsShowTaxToolTip(true)}
                          onMouseOver={() => setIsShowTaxToolTip(true)}
                          onMouseLeave={() => setIsShowTaxToolTip(false)}
                          onMouseOut={() => setIsShowTaxToolTip(false)}
                        >
                          <div className="bg-[#F2F4F7] relative ml-[15px] text-sm font-normal p-4 rounded-[6px] text-[#344054] w-[352px] h-auto shadow-tooltip">
                            <img
                              src={TooltipArrowIcon}
                              alt="TooltipArrowIcon"
                              className=" absolute left-[-15px] top-[83px] w-[15px] h-[17px]"
                            />
                            <div className="mb-2">
                              Mã số thuế là thông tin quan trọng được yêu cầu bởi Tổng công ty Lưu
                              ký và bù trừ chứng khoán Việt Nam (VSD) để quản lý tài khoản giao dịch
                              chứng khoán.
                            </div>
                            <div>
                              Quý khách có thể tra cứu MST của mình ở trên trang web Thuế Việt Nam
                              của Tổng cục Thuế - Bộ Tài Chính (link ở{' '}
                              <a
                                style={{ textDecoration: 'underline' }}
                                href="http://tracuunnt.gdt.gov.vn/tcnnt/mstcn.jsp"
                                target="_blank"
                                className="text-primary"
                                rel="noreferrer"
                              >
                                đây
                              </a>
                              ).
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </label>
                  <input
                    type="text"
                    className={`form-input__input ${errors.pin_code && 'form-input__input_error'}`}
                    placeholder="Nhập mã số thuế"
                    maxLength={14}
                    {...register('tax_number', {
                      validate: v => {
                        if (!isValidTaxCode(v)) {
                          return 'Mã số thuế không đúng định dạng, vui lòng kiểm tra lại.';
                        }
                      },
                    })}
                  />
                  {errors.tax_number?.type === 'pattern' && (
                    <p className="text-sm font-normal leading-5 text-carminePink mt-1">
                      {errors.tax_number?.message as string}
                    </p>
                  )}
                  {errors.tax_number?.type === 'validate' && (
                    <p className="text-sm font-normal leading-5 text-carminePink mt-1">
                      {errors.tax_number?.message as string}
                    </p>
                  )}
                  {errors.tax_number?.type === 'required' && (
                    <p className="text-sm font-normal leading-5 text-carminePink mt-1">
                      Vui lòng nhập mã số thuế
                    </p>
                  )}
                </div>
                <div className="form-input">
                  <label htmlFor="" className="text-sm font-normal leading-5">
                    Địa chỉ liên hệ
                  </label>
                  <textarea
                    className={`form-input__input ${
                      errors.address_contact && 'form-input__input_error'
                    }`}
                    placeholder="Nhập địa chỉ liên hệ"
                    {...register('address_contact', {
                      required: false,
                    })}
                    readOnly={isSameAddress}
                  />
                </div>
              </div>
              <div className="flex flex-col gap-3">
                <div className="form-input">
                  <label htmlFor="" className="text-sm font-normal leading-5">
                    Ngày sinh
                  </label>
                  <input
                    type="text"
                    className={`form-input__input ${errors.dob && 'form-input__input_error'}`}
                    placeholder="Nhập ngày sinh"
                    onWheel={e => e.currentTarget.blur()}
                    {...register('dob', {
                      required: true,
                    })}
                    readOnly
                  />
                </div>
                <div className="form-input">
                  <label htmlFor="" className="text-sm font-normal leading-5">
                    CMND/CCCD
                  </label>
                  <input
                    type="text"
                    className={`form-input__input ${errors.cccd && 'form-input__input_error'}`}
                    placeholder="Nhập CMND/CCCD"
                    {...register('cccd', {
                      required: true,
                    })}
                    readOnly
                  />
                </div>
                <div className="form-input datepicker">
                  <label htmlFor="" className="text-sm font-normal leading-5">
                    Ngày hết hạn
                  </label>
                  <input
                    type={`${dataID?.expire_date ? 'text' : 'hidden'}`}
                    className={`form-input__input ${
                      errors.expireDate && 'form-input__input_error'
                    }`}
                    placeholder="Nhập ngày hết hạn"
                    {...register('expireDate', {
                      required: true,
                    })}
                    readOnly={dataID?.expire_date}
                  />
                  {!dataID?.expire_date && (
                    <DatePicker
                      value={expireDateSelect}
                      onChange={date => {
                        handleChangeDate(date);
                      }}
                      disabledDate={d =>
                        !d || d.isBefore(dayjs().subtract(1, 'days').endOf('day').toString())
                      }
                      format="DD/MM/YYYY"
                      placeholder="Nhập ngày hết hạn"
                      className="w-full form-input__input !py-[11px]"
                    />
                  )}
                  {errors.expireDate && (
                    <p className="text-sm font-normal leading-5 text-carminePink mt-1">
                      {errors?.expireDate?.message as string}
                    </p>
                  )}
                </div>
                <div className="form-input">
                  <label htmlFor="" className="text-sm font-normal leading-5">
                    Nơi cấp
                  </label>
                  <input
                    type="text"
                    className={`form-input__input ${errors.noicap && 'form-input__input_error'}`}
                    placeholder="Nhập nơi cấp"
                    {...register('noicap', {
                      required: true,
                    })}
                    readOnly
                  />
                </div>
                <div className="form-input">
                  <label htmlFor="" className="text-sm font-normal leading-5">
                    Nơi cấp
                  </label>
                  <input
                    type="text"
                    className={`form-input__input ${
                      errors.phone_number && 'form-input__input_error'
                    }`}
                    placeholder="Nhập số điện thoại"
                    {...register('phone_number', {
                      required: true,
                    })}
                    readOnly
                  />
                  {errors.phone_number?.type === 'pattern' && (
                    <p className="text-sm font-normal leading-5 text-carminePink mt-1">
                      Số điện thoại không hợp lệ, vui lòng kiểm tra lại
                    </p>
                  )}
                  {errors.phone_number?.type === 'required' && (
                    <p className="text-sm font-normal leading-5 text-carminePink mt-1">
                      Vui lòng nhập số điện thoại
                    </p>
                  )}
                </div>
                <div className="form-input">
                  <label htmlFor="" className="text-sm font-normal leading-5">
                    Địa chỉ thường trú
                  </label>
                  <textarea
                    className={`form-input__input ${
                      errors.address_permanent && 'form-input__input_error'
                    }`}
                    placeholder="Nhập địa chỉ thường trú"
                    {...register('address_permanent', {
                      required: false,
                    })}
                    readOnly
                  />
                  {errors.phone_number?.type === 'pattern' && (
                    <p className="text-sm font-normal leading-5 text-carminePink mt-1">
                      Số điện thoại không hợp lệ, vui lòng kiểm tra lại
                    </p>
                  )}
                  {errors.phone_number?.type === 'required' && (
                    <p className="text-sm font-normal leading-5 text-carminePink mt-1">
                      Vui lòng nhập số điện thoại
                    </p>
                  )}
                </div>
              </div>

              <div className="flex items-center gap-[9px] space-x-5 mt-3 col-span-2">
                <div className="font-normal text-sm leading-[22px]">
                  Địa chỉ liên hệ trùng với địa chỉ thường trú
                </div>
                <Switch
                  onChange={(value: any) => {
                    setIsSameAddress(value);

                    if (value) {
                      setValue('address_contact', getValues('address_permanent'));
                    }
                  }}
                  checked={isSameAddress}
                  className="react-switch !ml-0"
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  checkedIcon={false}
                  onColor="#61CB2F"
                  uncheckedIcon={false}
                  height={24}
                  handleDiameter={20}
                  width={44}
                />
              </div>
            </div>
          </form>
        </>
      );
    }
    if (step === STEP_EKYC.FACE_CHECK) {
      return (
        <>
          <div className="text-center">
            <h2 className=" text-xl font-bold leading-[26px]">Nhận diện khuôn mặt</h2>
            <div className=" text-base font-normal leading-[22px] mt-1">
              Vui lòng chụp ảnh chính diện khuôn mặt
            </div>
          </div>
          <div className="mt-6 pb-[14px]">
            <div
              className={`border border-[#D0D5DD] rounded-[6px] w-[320px] h-[180px] flex items-center justify-center cursor-pointer p-4 mx-auto`}
              onClick={() => setShowCameraLivenessCheck(true)}
            >
              {!imageFace && (
                <div>
                  <img src={FaceIcon} alt="CccdFront" className="w-[108px] h-auto rounded" />
                </div>
              )}
              {!!imageFace && (
                <div>
                  <img
                    src={imageFace}
                    width={160}
                    height={160}
                    alt="CccdFront"
                    className="w-auto max-h-[160px] h-[160px] object-contain rounded"
                  />
                </div>
              )}
            </div>
            {livenessCheckStatus === 'error' && (
              <>
                <div className="mt-3 flex items-center justify-center gap-2">
                  <img src={PhotoIcon} width={20} height={16} alt="PhotoIcon" />
                  <div
                    className="text-primary font-bold text-sm leading-[24px] takePicture"
                    onClick={() => setShowCameraLivenessCheck(true)}
                  >
                    Chụp lại
                  </div>
                </div>
                <div className="text-sm font-medium text-[#F04438] text-center leading-[22px] mt-[10px]">
                  {errorMessage}
                </div>
              </>
            )}
            {livenessCheckStatus === 'loading' && (
              <div className="flex flex-col items-center mt-2">
                <SpinnerIcon className="scale-90" />

                <div className="text-sm font-medium leading-[22px] mt-2">
                  Đang xác thực hình ảnh
                </div>
              </div>
            )}
            {livenessCheckStatus === 'success' && (
              <div className="flex flex-col items-center mt-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="33"
                  height="32"
                  viewBox="0 0 33 32"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.5 16C0.5 7.16344 7.66344 0 16.5 0C25.3366 0 32.5 7.16344 32.5 16C32.5 24.8366 25.3366 32 16.5 32C7.66344 32 0.5 24.8366 0.5 16ZM15.5865 22.8494L24.7996 11.3329L23.1338 10.0003L15.2803 19.8172L9.71625 15.1805L8.35052 16.8194L15.5865 22.8494Z"
                    fill="#61CB2F"
                  />
                </svg>
                <div className="mt-2">Hoàn tất xác thực khuôn mặt</div>
              </div>
            )}
            {!imageFace && (
              <div
                className="text-primary font-bold text-sm leading-[24px] mt-3 text-center takePicture"
                onClick={() => setShowCameraLivenessCheck(true)}
              >
                Chụp ảnh khuôn mặt
              </div>
            )}
          </div>
          <div className="bg-black rounded border border-[#0BA5EC] border-opacity-[32%] p-4 max-w-[664px] mx-auto mb-10">
            <span className="text-xs leading-[18px] text-[#0BA5EC]">
              Lưu ý khi chụp ảnh khuôn mặt:
            </span>
            <div className="flex">
              <span className="text-xs leading-[18px] text-[#0BA5EC] w-4">1.</span>
              <span className="text-xs leading-[18px] text-[#0BA5EC] flex-1">
                Không đeo khẩu trang, mặt kính râm, đội nón, hoặc các vật dụng khác che khuôn mặt.
              </span>
            </div>
            <div className="flex">
              <span className="text-xs leading-[18px] text-[#0BA5EC] w-4">2.</span>
              <span className="text-xs leading-[18px] text-[#0BA5EC] flex-1">
                Chụp trong điều kiện ánh sáng đủ.
              </span>
            </div>
            <div className="flex">
              <span className="text-xs leading-[18px] text-[#0BA5EC] w-4">3.</span>
              <span className="text-xs leading-[18px] text-[#0BA5EC] flex-1">
                Không để bóng đèn ở đằng sau.
              </span>
            </div>
          </div>
        </>
      );
    }
    if (step === STEP_EKYC.ADD_BANK) {
      return (
        <AddBankAccount
          isSubmit={isSubmitAddBank}
          handleError={() => setIsSubmitAddBank(false)}
          handleSuccess={async () => {
            setSearchParams({ step: '4' });
          }}
        />
      );
    }
    if (step === STEP_EKYC.CONTRACT) {
      return <ContractNormal />;
    }
    if (step === STEP_EKYC.SIGNATURE) {
      return (
        <Signature
          isSubmit={isSubmitSign}
          callBack={() => {
            setIsSubmitSign(false);
            setSearchParams({ step: `${STEP_EKYC.CREATE_PIN}` });
          }}
        />
      );
    }
    if (step === STEP_EKYC.CREATE_PIN) {
      return <CreatePinCode closeModal={() => onPrevStep()} />;
    }

    if (step === STEP_EKYC.REVIEW) {
      return <p className="text-center mt-10">Account của bạn đang trong quá trình review</p>;
    }
  };

  if (!step || !userInit) {
    return (
      <div className="flex justify-center my-10">
        <SpinnerIcon />
      </div>
    );
  }

  return (
    <>
      <OnboardingCard title={getTitleStep()} classNameCard="flex flex-col">
        <div className="flex-1">{renderContent()}</div>
        {step !== '7' && (
          <div className="flex gap-6 items-center justify-center">
            <Button
              variant="secondary"
              type="button"
              disabled={isDisablePrev()}
              className="min-w-[199px] py-[9px]"
              onClick={() => onPrevStep()}
            >
              Quay lại
            </Button>
            <Button
              className="min-w-[199px]"
              disabled={isDisableNext()}
              onClick={() => onNextStep()}
            >
              Tiếp theo
            </Button>
          </div>
        )}
      </OnboardingCard>
      {!!showCamera && (
        <>
          <div className="opacity-25 fixed inset-0 z-50 bg-black"></div>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full md:max-w-[640px] md:mx-auto">
              {isCameraPermission ? (
                <div className="border-0 rounded-[20px] shadow-lg relative flex flex-col w-full bg-white text-black outline-none focus:outline-none">
                  <div
                    className="absolute top-4 right-4 lg:top-6 lg:right-6"
                    onClick={() => setShowCamera('')}
                  >
                    <RiCloseLine />
                  </div>
                  <div className="p-4 pb-6 flex flex-col items-center lg:pt-6 lg:pb-8">
                    <h2 className="text-xl font-semibold mb-3">Chụp CCCD</h2>
                    <p className="text-md mb-8">Đảm bảo ảnh CCCD rõ nét để được duyệt nhanh hơn</p>
                    <Webcam
                      audio={false}
                      ref={webcamRef}
                      screenshotQuality={1}
                      screenshotFormat="image/jpeg"
                      videoConstraints={videoConstraints}
                      style={{ transform: 'scaleX(-1)' }}
                    />
                    <Button
                      className="min-w-[199px] mt-8"
                      onClick={() => {
                        if (showCamera === IMAGE_TYPE.FRONT) {
                          captureFrontId();
                        }
                        if (showCamera === IMAGE_TYPE.BEHIND) {
                          captureBackId();
                        }
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="17"
                        viewBox="0 0 20 17"
                        fill="none"
                      >
                        <path
                          d="M2.55701 15.5294H17.4431C19.1369 15.5294 20 14.6824 20 13.0049V4.47883C20 2.80131 19.1369 1.96254 17.4431 1.96254H15.114C14.4707 1.96254 14.2753 1.83225 13.9088 1.42508L13.2573 0.692184C12.8502 0.2443 12.4349 0 11.5961 0H8.34691C7.50816 0 7.09282 0.2443 6.68568 0.692184L6.03421 1.42508C5.66776 1.82411 5.46418 1.96254 4.829 1.96254H2.55701C0.855051 1.96254 0 2.80131 0 4.47883V13.0049C0 14.6824 0.855051 15.5294 2.55701 15.5294ZM10 13.127C7.53256 13.127 5.55376 11.1564 5.55376 8.67268C5.55376 6.19708 7.53256 4.22639 10 4.22639C12.4674 4.22639 14.4381 6.19708 14.4381 8.67268C14.4381 11.1564 12.4593 13.127 10 13.127ZM10 11.8893C11.7753 11.8893 13.2085 10.4642 13.2085 8.67268C13.2085 6.88926 11.7753 5.45603 10 5.45603C8.22474 5.45603 6.7834 6.88926 6.7834 8.67268C6.7834 10.4642 8.23294 11.8893 10 11.8893ZM14.9837 5.67591C14.9837 5.12216 15.4723 4.63356 16.0423 4.63356C16.6042 4.63356 17.0847 5.12216 17.0847 5.67591C17.0847 6.25408 16.6042 6.71825 16.0423 6.7264C15.4723 6.7264 14.9837 6.26223 14.9837 5.67591Z"
                          fill="#ffffff"
                        />
                      </svg>
                      <span>Bấm để chụp hình</span>
                    </Button>
                  </div>
                </div>
              ) : (
                <div className="border-0 rounded-[20px] shadow-lg relative flex flex-col w-full bg-white text-center p-6">
                  <div className="font-bold text-base mb-[44px]">Cấp quyền truy cập camera.</div>
                  <img src={CameraImg} width={100} height={100} alt="Camere" className="mx-auto" />
                  <p className="text-base text-black my-[49px]">
                    Bạn vui lòng cấp quyền truy cập camera cho trình duyệt để có thể tiệp tục xác
                    thực tài khoản của mình
                  </p>
                  <div className="flex gap-3 items-center justify-center">
                    <Button
                      variant="secondary"
                      type="button"
                      className="flex-1 py-[9px]"
                      onClick={() => setShowCamera('')}
                    >
                      Huỷ
                    </Button>
                    <Button className="flex-1" onClick={() => setShowCamera('')}>
                      Đồng ý
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}
      {showCameraLivenessCheck && (
        <>
          <div className="opacity-25 fixed inset-0 z-50 bg-black"></div>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full md:max-w-[640px] md:mx-auto">
              <div className="border-0 rounded-[20px] shadow-lg relative flex flex-col w-full bg-white text-black outline-none focus:outline-none">
                <div
                  className="absolute top-4 right-4 lg:top-6 lg:right-6"
                  onClick={() => setShowCameraLivenessCheck(false)}
                >
                  <RiCloseLine />
                </div>
                <div className="px-10 py-6 flex flex-col items-center lg:pt-6 lg:pb-8">
                  <h2 className="text-xl font-semibold mb-3">Xác thực mặt</h2>
                  <p className="text-md mb-4">
                    Giữ vững camera, cử động gương mặt tự nhiên trong vài giây.
                  </p>
                  <CameraLivenessCheck
                    onSuccess={images => {
                      onLivenessCheck(images);
                      setShowCameraLivenessCheck(false);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default IdentificationDocuments;
