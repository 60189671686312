import React from 'react';

type ProgressBarProps = {
  type: 'BUY' | 'HOLD' | 'SELL';
  percent: number;
};

export const ProgressBar = ({ type, percent }: ProgressBarProps) => {
  return (
    <div
      className={`max-w-[230px] w-[230px] flex justify-between items-center text-sm font-normal leading-5`}
    >
      <p>
        {
          {
            BUY: 'Mua',
            HOLD: 'Giữ',
            SELL: 'Bán'
          }[type]
        }
      </p>
      <div className="w-[150px] h-[8px] bg-text_main_med rounded-sm">
        <div className="h-full" style={{ width: `${percent * 100 || 0}%` }}>
          <div
            className={`rounded-sm h-full progress-bar-animation ${
              type === 'BUY'
                ? 'bg-vividMalachite'
                : type === 'HOLD'
                ? 'bg-mustardYellow'
                : 'bg-lowPriceColor'
            } 
        
        `}
          ></div>
        </div>
      </div>
      <div className="min-w-[40px]">
        <p>{percent ? (percent * 100).toFixed(0) : 0}%</p>
      </div>
    </div>
  );
};
