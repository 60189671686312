import React, { useState, ReactNode, useMemo } from 'react';
import { AccountService } from 'core/services';
import { AccountInfo, AccountItem, UserInfo } from 'core/types';

interface ContextType {
  account?: AccountItem;
  updateAccount: (data: AccountItem) => void;
  accountInfo?: AccountInfo;
  userInfo?: UserInfo;
  updateUserInfo: (data: UserInfo) => void;
  watchlist: string[];
  updateWatchlist: (data?: string[]) => void;
  updateAccountInfo: (data?: any) => void;
}

type Props = {
  children: ReactNode;
};

const AccountContext = React.createContext<ContextType>({} as ContextType);

export const AccountProvider: React.FC<Props> = ({ children }) => {
  const [account, setAccount] = useState<AccountItem>();
  const [accountInfo, setAccountInfo] = useState<AccountInfo>();
  const [userInfo, setUserInfo] = useState<UserInfo>();
  const [watchlist, setwatchlist] = useState<string[]>([]);

  const updateAccount = (data: AccountItem) => {
    setAccount(data);
  };

  const updateAccountInfo = (data: AccountInfo) => {
    setAccountInfo(data);
  };

  const updateUserInfo = (data: UserInfo) => {
    setUserInfo(data);
  };

  const updateWatchlist = async (data?: string[]) => {
    if (data) {
      setwatchlist(data);
      return;
    }
    const response = await AccountService.getwatchlist();
    if (response?.data) {
      const newdata = response?.data?.data || [];
      setwatchlist(newdata);
    }
  };

  const value = useMemo(
    () => ({
      account,
      updateAccount,
      accountInfo,
      updateAccountInfo,
      userInfo,
      updateUserInfo,
      watchlist,
      updateWatchlist,
    }),
    [
      account,
      updateAccount,
      accountInfo,
      updateAccountInfo,
      userInfo,
      updateUserInfo,
      watchlist,
      updateWatchlist,
    ]
  );

  return <AccountContext.Provider value={value}>{children}</AccountContext.Provider>;
};

export function useAccount() {
  return React.useContext(AccountContext);
}

export default AccountContext;
