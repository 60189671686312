import React from 'react';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { RiInformationLine } from 'react-icons/ri';
import { styled } from '@mui/material/styles';

type Props = {
  title: string;
  placement?: any;
  className?: string;
  color?: string;
};

export const InfoTooltip = styled(
  ({
    title,
    placement = 'bottom-start',
    className,
    color,
    ...props
  }: Props) => (
    <Tooltip
      {...props}
      classes={{ popper: className }}
      title={
        <div
          className="text-sm"
          dangerouslySetInnerHTML={{
            __html: title
              ?.replaceAll('**:', ': </strong>')
              .replaceAll(':**', ': </strong>')
              .replaceAll('**', '<strong>')
              .replace(/\n/g, '<br>')
          }}
        ></div>
      }
    >
      <button className="pt-[2px]">
        <RiInformationLine
          className={`${!color ? 'text-white' : color} text-xs`}
        />
      </button>
    </Tooltip>
  )
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#0C111D',
    color: '#fff',
    maxWidth: 220,
    border: '1px solid #475467',
    borderRadius: 6
  }
}));
