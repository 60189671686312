import { ArrowDownBig, InfomationIcon } from 'assets/icons';
import React, { useState } from 'react';
import StockSelectCommandOptions from './StockSelectCommandOptions';
import useComponentVisible from 'core/hooks/UseComponentVisible';
import OrderTypeInfo from './OrderTypeInfo';

const StockSelectCommand = ({
  backgroundColor
}: {
  backgroundColor?: string;
}) => {
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  return (
    <div ref={ref} className="relative">
      <div className="flex justify-between rounded-md border-style h-10">
        <OrderTypeInfo />
        <div
          className={`flex justify-center items-center cursor-pointer flex-1 ${backgroundColor}`}
          onClick={() => {
            setIsComponentVisible((o: boolean) => !o);
          }}
        >
          <p className="text-sm font-semibold leading-5">Lệnh thường</p>
        </div>
        <div
          className="flex justify-center items-center rounded-r-md p-2 bg-yankeesBlue w-10 cursor-pointer"
          onClick={() => {
            setIsComponentVisible((o: boolean) => !o);
          }}
        >
          <ArrowDownBig />
        </div>
      </div>
      {isComponentVisible && (
        <StockSelectCommandOptions
          onSelectItem={() => {
            setIsComponentVisible(false);
          }}
        />
      )}
    </div>
  );
};

export default StockSelectCommand;
