import React from 'react';
import { useAccount } from 'core/contexts';
import Flaticon from 'assets/images/flaticon.png';
import { Button } from 'components';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { EKYC, STEP_EKYC } from 'core/constant';

type Props = {
  className?: string;
  isShowCTA?: boolean;
  imgClassName?: string;
};

export const AccountNotification = ({ className, isShowCTA, imgClassName }: Props) => {
  const navigate = useNavigate();
  const { accountInfo, userInfo } = useAccount();

  const isEkycRegisted =
    userInfo?.ekyc_status === EKYC.SUCCESS ||
    (userInfo?.ekyc_status === EKYC.MANUAL &&
      accountInfo &&
      accountInfo?.pin &&
      accountInfo?.sign_contract);

  const title = isEkycRegisted
    ? 'SBBS đang xem xét yêu cầu của bạn. Chúng tôi sẽ gửi thông báo cho bạn khi hoàn tất.'
    : 'Vui lòng hoàn thiện yêu cầu mở tài khoản';

  const handleNavigate = () => {
    if (userInfo) {
      if (userInfo?.ekyc_status === EKYC.SUCCESS || userInfo?.ekyc_status === EKYC.MANUAL) {
        if (!accountInfo) {
          navigate({
            pathname: '/register',
            search: createSearchParams({
              step: STEP_EKYC.ADD_BANK,
            }).toString(),
          });
          return;
        }

        if (accountInfo && !accountInfo?.pin) {
          navigate({
            pathname: '/register',
            search: createSearchParams({
              step: STEP_EKYC.CREATE_PIN,
            }).toString(),
          });
          return;
        }
      } else {
        navigate({
          pathname: '/register',
          search: createSearchParams({
            step: STEP_EKYC.ID_CHECK,
          }).toString(),
        });
      }
    }
  };

  return (
    <div className={`flex flex-col items-center text-center ${className}`}>
      <img src={Flaticon} className={`w-20 ${imgClassName}`} alt={title} />
      <p className="text-sm font-normal leading-[22px] text-text_main_light mt-4 mb-8">{title}</p>
      {isShowCTA && !isEkycRegisted ? (
        <Button variant="primary" className="lg:min-w-[311px]" onClick={handleNavigate}>
          Hoàn thiện ngay
        </Button>
      ) : null}
    </div>
  );
};
