import React from 'react';

export const BuildingIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M13.9998 12.6667H15.3332V14H0.666504V12.6667H1.99984V2.66667C1.99984 2.48986 2.07008 2.32029 2.1951 2.19526C2.32012 2.07024 2.48969 2 2.6665 2H9.33317C9.50998 2 9.67955 2.07024 9.80457 2.19526C9.9296 2.32029 9.99984 2.48986 9.99984 2.66667V12.6667H12.6665V7.33333H11.3332V6H13.3332C13.51 6 13.6796 6.07024 13.8046 6.19526C13.9296 6.32029 13.9998 6.48986 13.9998 6.66667V12.6667ZM3.33317 3.33333V12.6667H8.6665V3.33333H3.33317ZM4.6665 7.33333H7.33317V8.66667H4.6665V7.33333ZM4.6665 4.66667H7.33317V6H4.6665V4.66667Z"
        fill="#F9FAFB"
      />
    </svg>
  );
};