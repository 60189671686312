import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { OnboardingService } from 'core/services';
import Select, { type DropdownIndicatorProps, components } from 'react-select';
import { RiArrowDownSFill, RiInformationLine } from 'react-icons/ri';
import { GENERAL_SERVER_ERROR } from 'core/constant';

type Props = {
  isSubmit: boolean;
  handleError: () => void;
  handleSuccess: (res: any) => void;
};

export const AddBankAccount = ({
  isSubmit,
  handleSuccess,
  handleError
}: Props) => {
  const [bankSelected, setBankSelected] = useState<any>();
  const [bankList, setBankList] = useState<any>([]);

  const {
    register,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm({
    mode: 'onChange'
  });

  const fetchListBank = async () => {
    const res = await OnboardingService.fetchListBank();
    setBankList(res);
  };

  useEffect(() => {
    fetchListBank();
  }, []);

  const handleBankChange = async (data: any) => {
    clearErrors();
    setBankSelected(data);
    setValue('bank_id', data?.value, { shouldValidate: true });
    const accountNumber = getValues('account_number');
    if (accountNumber && data?.value) {
      const res = await OnboardingService.fetchBankInfo(
        data?.value,
        accountNumber
      );
      setValue('account_name', res?.account_name);
    }
  };

  const handleAccountChange = async (data: any) => {
    clearErrors();
    const bankId = getValues('bank_id');
    const accNumber = data?.target.value;
    if (bankId && accNumber) {
      const res = await OnboardingService.fetchBankInfo(bankId, accNumber);
      setValue('account_name', res?.account_name);
    }
  };

  useEffect(() => {
    if (isSubmit) {
      onSubmit();
    }
  }, [isSubmit, getValues]);

  const onSubmit = async () => {
    let isValid = true;
    const data = getValues();
    const params = {
      bank_key: data?.bank_id,
      branch_id: '',
      account_number: data?.account_number,
      account_name: data?.account_name
    };
    if (!data?.account_name) {
      isValid = false;
      setError('account_number', {
        type: 'custom',
        message: 'Không tìm thấy thông tin tài khoản'
      });
    }
    if (!data?.bank_id) {
      isValid = false;
      setError('bank_id', {
        type: 'required'
      });
    }
    if (!data?.account_number) {
      isValid = false;
      setError('account_number', {
        type: 'required'
      });
    }

    if (!isValid) {
      handleError();
      return;
    }
    const res = await OnboardingService.addBankUser(params);
    if (res?.data) {
      handleSuccess(res?.data);
      return;
    }
    setError('account_number', {
      type: 'custom',
      message: res?.error_message?.split('|')[1] || GENERAL_SERVER_ERROR
    });
  };

  const DropdownIndicator: React.FC<DropdownIndicatorProps> = props => {
    return (
      <components.DropdownIndicator {...props}>
        <RiArrowDownSFill size={24} color="#FFFFFF" />
      </components.DropdownIndicator>
    );
  };

  return (
    <form noValidate>
      <div className="bg-[#F5F8FF] bg-opacity-[13%] rounded border border-[#0BA5EC] border-opacity-[32%] px-[18.82px] py-[9.5px]">
        <span className="text-sm leading-[22px] text-[#0BA5EC]">
          Vui lòng nhập tài khoản ngân hàng cùng tên để nhận tiền khi thực hiện
          chuyển tiền từ tài khoản chứng khoán.
        </span>
      </div>
      <div className="mt-[18px] lg:grid lg:grid-cols-2 lg:gap-x-6 lg:gap-y-3">
        <div>
          <div className="form-input">
            <label htmlFor="" className="text-sm font-normal leading-5">
              Ngân hàng<span className="text-carminePink">*</span>
            </label>
            <Select
              className="react-select"
              classNamePrefix="select"
              isSearchable={true}
              name="bank_id"
              placeholder="Chọn hoặc tìm kiếm ngân hàng"
              options={bankList}
              onChange={handleBankChange}
              value={bankSelected}
              components={{ DropdownIndicator }}
            />
            <input
              type="hidden"
              className="w-full form-input"
              placeholder="Tên ngân hàng"
              {...register('bank_id', {
                required: true
              })}
            />
            {errors.bank_id?.type === 'required' && (
              <p className="text-sm font-normal leading-5 text-carminePink">
                Vui lòng chọn ngân hàng
              </p>
            )}
          </div>
        </div>
        <div></div>
        <div>
          <div className="form-input">
            <label htmlFor="" className="text-sm font-normal leading-5">
              Số tài khoản<span className="text-carminePink">*</span>
            </label>
            <input
              type="text"
              className={`form-input__input ${
                errors.account_number && 'form-input__input_error'
              }`}
              placeholder="Nhập số tài khoản"
              {...register('account_number', {
                required: true,
                onBlur: e => {
                  handleAccountChange(e);
                }
              })}
              onMouseLeave={e => handleAccountChange(e)}
            />

            {errors.account_number?.type === 'required' && (
              <p className="text-sm font-normal leading-5 text-carminePink">
                Vui lòng nhập số tài khoản
              </p>
            )}
          </div>
        </div>
        <div>
          <div className="form-input">
            <label htmlFor="" className="text-sm font-normal leading-5">
              Tên chủ tài khoản
            </label>
            <input
              type="text"
              className={`form-input__input ${
                errors.account_name && 'form-input__input_error'
              }`}
              readOnly
              {...register('account_name', {
                required: true
              })}
            />
          </div>
        </div>
      </div>
      {errors.account_number?.type === 'custom' && (
        <p className="text-sm font-normal leading-5 text-carminePink">
          {errors.account_number?.message as string}
        </p>
      )}
    </form>
  );
};
