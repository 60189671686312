import { LoadingBarProcess, MasterLayout } from 'components'
import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { RequireAuth } from './Routes'
import CashAdvancePage from 'pages/money/CashAdvancePage'

const CashAdvanceRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<MasterLayout />}>
        <Route
          index
          element={
            <React.Suspense fallback={<LoadingBarProcess />}>
              <RequireAuth>
                <CashAdvancePage />
              </RequireAuth>
            </React.Suspense>
          }
        />
      </Route>
    </Routes>
  )
}

export default CashAdvanceRoutes