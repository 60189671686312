import React, { useState, useEffect } from 'react';
import { useAccount } from 'core/contexts/AccountContext';
import { TitleSection, ComingSoonFeature } from 'components';
import { AssetsService } from 'core/services';
import { PieChart } from 'components';
import { formatCurrency, formatDate } from 'core/utils';
import { RiArrowRightSLine } from 'react-icons/ri';

import CashHistories from './components/MarginHistories';

const AssetsPage = () => {
  const { account } = useAccount();
  const [cashAsset, setcashAsset] = useState<any>();

  const dataPieChart = [
    {
      y: cashAsset?.stock_amount,
      name: 'Giải ngân',
      color: '#00629B'
    },
    {
      y: cashAsset?.available_cash_balance,
      name: 'Chưa giải ngân',
      color: '#36BFFA'
    },
    {
      y: 0,
      name: 'Còn lại',
      color: '#E0F2FE'
    }
  ];

  const fetchcashAsset = async () => {
    if (account) {
      const res = await AssetsService.getCashPortfolio(account.account_id);
      const data = res?.data?.data;
      setcashAsset(data);
    }
  };

  useEffect(() => {
    fetchcashAsset();
  }, [account]);

  return (
    <div className="min-h-full flex items-center justify-center">
      <ComingSoonFeature />
      {/* <div className="border-l border-r border-gray flex flex-col">
        <TitleSection title="Tổng nợ" />
        <div className="flex p-4">
          <div className="flex-1">
            <div className="text-sm mb-2">Tổng nợ</div>
            <div className="text-base font-medium">
              {formatCurrency(80000000)}
            </div>
          </div>
          <div className="flex-1">
            <div className="text-sm mb-2">Nợ giải ngân</div>
            <div className="text-base font-medium">
              {formatCurrency(80000000)}
            </div>
          </div>
          <div className="flex-1">
            <div className="text-sm mb-2">Nợ sắp giải ngân</div>
            <div className="text-base font-medium">
              {formatCurrency(80000000)}
            </div>
          </div>
          <div className="flex-1">
            <div className="text-sm mb-2">Lãi vay chưa thu</div>
            <div className="text-base font-medium">
              {formatCurrency(80000000)}
            </div>
          </div>
          <div className="flex-1">
            <div className="text-sm mb-2">Phí chưa thu</div>
            <div className="text-base font-medium">
              {formatCurrency(80000000)}
            </div>
          </div>
        </div>
      </div>
      <div className="lg:flex">
        <div className="flex-1 border-t border-r border-l border-gray">
          <TitleSection title="Quản lý ký quỹ" />
          <div className="p-4">
            <div className="pr-5 md:flex md:items-center xl:gap-10 xl:pl-[50px] xl:pr-[70px]">
              <PieChart data={dataPieChart} />
              <div className="flex-1">
                <ul>
                  {dataPieChart.map((i, idx) => (
                    <li
                      key={`chart-desc-${idx}`}
                      className="flex justify-between items-center"
                    >
                      <div className="flex items-center gap-2">
                        <div
                          className="w-2 h-2 rounded-full"
                          style={{ backgroundColor: i.color }}
                        ></div>
                        <span className="text-sm">{i.name}</span>
                      </div>
                      <div className="text-sm">{formatCurrency(i.y)}</div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div>
            <TitleSection title="Phân bổ tài sản" />
            <div className="p-4">
              <ul>
                <li className="flex justify-between border-b border-gray py-3">
                  <span className="text-sm">Tổng tài sản đảm bảo</span>
                  <span className="text-base font-semibold">
                    {formatCurrency(cashAsset?.withdraw_cash_bal)}
                  </span>
                </li>

                <li className="flex justify-between border-b border-gray py-3">
                  <span className="text-sm">Tổng nợ</span>
                  <span className="text-base font-semibold">
                    {formatCurrency(cashAsset?.bought_consideration)}
                  </span>
                </li>
                <li className="flex justify-between border-b border-gray py-3">
                  <span className="text-sm">Tài sản đảm bảo thực có</span>
                  <span className="text-base font-semibold">
                    {formatCurrency(cashAsset?.buy_consideration)}
                  </span>
                </li>
                <li className="flex justify-between border-b border-gray py-3">
                  <span className="text-sm">
                    Giá trị chứng khoán trong danh mục
                  </span>
                  <span className="text-base font-semibold">
                    {formatCurrency(cashAsset?.under_due_receive)}
                  </span>
                </li>
                <li className="flex justify-between border-b border-gray py-3">
                  <span className="text-sm">Sức mua tối thiểu</span>
                  <span className="text-base font-semibold">
                    {formatCurrency(cashAsset?.bought_consideration)}
                  </span>
                </li>
                <li className="flex justify-between border-b border-gray py-3">
                  <span className="text-sm">Sức mua tối đa</span>
                  <span className="text-base font-semibold">
                    {formatCurrency(cashAsset?.bought_consideration)}
                  </span>
                </li>
                <li className="flex justify-between border-b border-gray py-3">
                  <span className="text-sm">Tỷ lệ ký quỹ hiện tại</span>
                  <span className="text-base font-semibold">
                    {formatCurrency(cashAsset?.pending_withdraw_cash_bal)}
                  </span>
                </li>
                <li className="flex justify-between border-b border-gray py-3">
                  <span className="text-sm">Tài sản đảm bảo cần duy trì</span>
                  <span className="text-base font-semibold">
                    {formatCurrency(cashAsset?.pending_withdraw_cash_bal)}
                  </span>
                </li>
              </ul>
              <div className="mt-3 bg-blackBlue p-4">
                <p className="text-text_main_light text-sm mb-2">
                  Giao dịch ký quỹ là dịch vụ cho phép nhà đầu tư vay tiền với
                  tỷ lệ hỗ trợ của công ty chứng khoán mua cổ phiếu có sử dụng
                  hạn mức tín dụng bằng cách thế chấp tài sản đảm bảo. Câu chữ
                  cần chỉnh lại.
                </p>
                <a
                  className="text-primary text-sm flex items-center hover:underline"
                  href="#"
                  title="Tìm hiểu thêm"
                >
                  Tìm hiểu thêm
                  <RiArrowRightSLine className="text-[20px]" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-1 border-l border-gray">
          <TitleSection title="Lịch sử ký quỹ" />
          <CashHistories />
        </div>
      </div> */}
    </div>
  );
};

export default AssetsPage;
