import React, { useEffect, useState } from 'react';
import { usePrevious } from 'core/hooks';
import { formatCurrency } from 'core/utils';

type Props = {
  value: number;
};

export const StockVolume = ({ value }: Props) => {
  const [isVolumeChange, setIsVolumeChange] = useState(false);
  const prevValue = usePrevious(value);

  useEffect(() => {
    if (!prevValue) {
      return;
    }
    if (prevValue !== value) {
      setIsVolumeChange(true);
    }
  });

  useEffect(() => {
    if (isVolumeChange) {
      const timer = setTimeout(() => {
        setIsVolumeChange(false);
        return () => {
          clearTimeout(timer);
        };
      }, 100);
    }
  }, [isVolumeChange]);

  return (
    <span
      className={`text-center transition rounded m-[-2px] px-[2px] py-1 ${
        isVolumeChange ? 'bg-yellowOrange' : ''
      }`}
    >
      {formatCurrency(value)}
    </span>
  );
};
