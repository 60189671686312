import React, { useEffect } from 'react';
import SettingIcon from 'assets/images/setting_icon.png';
import { useNavigate } from 'react-router-dom';
import { FirestoreService } from 'core/services/firestore.service';

export const Maintain = () => {
  const navigate = useNavigate();

  const init = async () => {
    const maintainFlag =
      (await FirestoreService.getAppMaintainFlag()) === 'true';

    if (!maintainFlag) {
      navigate('/');
      return;
    }
  };
  useEffect(() => {
    init();
  }, []);

  return (
    <div className="flex flex-col h-screen min-h-96 justify-center items-center text-center max-w-[471px] mx-auto">
      <img
        src={SettingIcon}
        width={120}
        alt="Hiện tại chúng tôi đang bảo trì để nâng cấp hệ thống. Hệ thống sẽ quay
        trở lại sớm nhất khi dữ liệu hoàn thành!"
        className="max-w-full"
      />
      <p className="text-base leading-[20px] text-gray_dark mt-4">
        Hiện tại chúng tôi đang bảo trì để nâng cấp hệ thống. Hệ thống sẽ quay
        trở lại sớm nhất khi dữ liệu hoàn thành!
      </p>
    </div>
  );
};
