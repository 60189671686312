import React from 'react';

type RecommendationsCircleProps = {
  type: 'BUY' | 'HOLD' | 'SELL' | undefined;
};

export const RecommendationsCircle = ({ type }: RecommendationsCircleProps) => {
  return (
    <div
      className={`w-[100px] h-[100px] rounded-full flex items-center justify-center
        ${
          type === 'BUY'
            ? 'bg-green_light text-high-price'
            : type === 'HOLD'
            ? 'bg-yellow_light text-mustardYellow'
            : 'bg-red_main text-lowPriceColor'
        }
      `}
    >
      <p className="text-base font-semibold leading-[22px]">
        {type === 'BUY' ? 'Mua' : type === 'HOLD' ? 'Giữ' : 'Bán'}
      </p>
    </div>
  );
};
