import { TitleSection } from 'components/TitleSection';
import { PieChart } from 'components/charts/PieChart';
import { AssetsService } from 'core/services';
import { PieChartItem } from 'core/types';
import {
  AccountCashDistributionType,
  AccountCashOrderType
} from 'core/types/Money.type';
import { getAccountName } from 'core/utils';
import React, { useEffect, useState } from 'react';

type AccountCashPieChartProps = {
  account: any;
  isReload: boolean;
};

const AccountCashPieChart = ({
  account,
  isReload
}: AccountCashPieChartProps) => {
  const [accountCashDistribution, setAccountCashDistribution] = useState<
    AccountCashDistributionType[]
  >([]);
  const defaultBgColors = [
    '#00629B',
    '#0086C9',
    '#0BA5EC',
    '#7CD4FD',
    '#E0F2FE'
  ];
  const [pieChartData, setPieChartData] = useState<PieChartItem[]>([]);

  useEffect(() => {
    const getAccountCashDistribution = async (accountNo: any) => {
      const res = await AssetsService.getAccountCashDistribution(accountNo);
      if (res?.data?.status === 'Success') {
        setAccountCashDistribution(res?.data?.data);
      }
    };

    if (account?.account_id) {
      getAccountCashDistribution(account?.account_id);
    }
  }, [isReload]);

  useEffect(() => {
    if (accountCashDistribution) {
      const data = accountCashDistribution?.map((item: any, index: number) => {
        return {
          y: item?.percent,
          name: '',
          color: defaultBgColors[index]
        };
      });
      setPieChartData(data);
    }
  }, [accountCashDistribution]);

  return (
    <div className='first:mb-2 bg-black rounded-lg border-style !border-t-0'>
      <TitleSection
        title={`Tiểu khoản ${getAccountName(
          account?.account_type_id
        )?.toLocaleLowerCase()}`}
      />
      <div className="h-[282px] pr-[21px] py-4">
        {accountCashDistribution && (
          <div className="flex justify-center items-center w-full h-full">
            <div className="w-2/5 flex items-center justify-center ml-[-60px]">
              <PieChart width={170} data={pieChartData} />
            </div>
            <div className="w-3/5 flex flex-col items-center gap-4 mt-1 text-sm font-normal leading-5">
              {accountCashDistribution?.map((item: any, index: number) => (
                <div key={item?.key} className="flex justify-between w-full">
                  <div className="flex gap-2 justify-center items-center">
                    <div
                      className={`w-2 h-2`}
                      style={{ backgroundColor: defaultBgColors[index] }}
                    ></div>
                    <div>{`${item?.name} (${item?.percent}%)`}</div>
                  </div>
                  <div>{item?.amount?.toLocaleString('en-US')}</div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AccountCashPieChart;
