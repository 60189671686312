import dayjs from 'dayjs';
import React from 'react';

type OrderHistoryTableProps = {
  cashHistory: any;
};

const OrderHistoryTable = ({ cashHistory }: OrderHistoryTableProps) => {
  return (
    <>
      <table className={`w-full stock-table money-history-table`}>
        <thead>
          <tr>
            <th className="flex items-center">
              <div>Loại giao dịch</div>
            </th>
            <th className="flex items-center !pl-0">
              <div>Ngày giao dịch</div>
            </th>
            <th className="flex items-center text-right">
              <div>Số tiền</div>
            </th>
          </tr>
        </thead>
        <tbody>
          {cashHistory ? (
            cashHistory?.map((item: any) => {
              return (
                <tr key={item?.index}>
                  <td>{item?.sub_type_name}</td>
                  <td>{dayjs(item?.date).format('DD/MM/YYYY')}</td>
                  <td>
                    {item?.amount > 0 ? '+' : ''}
                    {item?.amount.toLocaleString('en-US')}đ
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td>-</td>
              <td>-</td>
              <td>-</td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
};

export default OrderHistoryTable;
