import React, { useState } from 'react';
import ForgotPinModal from 'components/modal/ForgotPinModal';

export const ForgotPinButton = () => {
  const [showChangePin, setShowChangePin] = useState(false);

  return (
    <>
      <button
        className="text text-primary text-xs"
        onClick={() => setShowChangePin(true)}
      >
        Quên mã PIN
      </button>
      <ForgotPinModal isShow={showChangePin} setIsShow={setShowChangePin} />
    </>
  );
};
