import React from 'react';
import { SpinnerIcon } from './SpinnerIcon';

export const Spinner = () => {
  return (
    <div className="bg-black/50 flex items-center justify-center fixed top-0 left-0 w-full h-full">
      <SpinnerIcon className="scale-125" />
    </div>
  );
};
