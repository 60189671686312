import { LoadingBarProcess, MasterLayout } from 'components';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { RequireAuth } from './Routes';
import MoneyHistory from 'pages/cash-history/CashHistory';

export const CashHistoryRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<MasterLayout />}>
        <Route
          index
          element={
            <React.Suspense fallback={<LoadingBarProcess />}>
              <RequireAuth>
                <MoneyHistory />
              </RequireAuth>
            </React.Suspense>
          }
        />
      </Route>
    </Routes>
  );
};