import React from 'react';

type StockPercentProps = {
  percent: number;
  last_price: number;
  ref_price: number;
  ceiling: number;
  floor: number;
};

export const StockPercent = ({
  percent,
  last_price,
  ceiling,
  floor,
  ref_price
}: StockPercentProps) => {
  if (percent === undefined) {
    return '-';
  }

  const getColorText = () => {
    if (!last_price) {
      return 'text-mustardYellow';
    }
    if (last_price === ceiling) {
      return 'text-ceilingPriceColor';
    }
    if (last_price === floor) {
      return 'text-floorPriceColor';
    }
    if (last_price > ref_price) {
      return 'text-high-price';
    }
    if (last_price < ref_price) {
      return 'text-lowPriceColor';
    }
    return 'text-mustardYellow';
  };

  return (
    <span className={`${getColorText()}`}>
      {percent > 0 ? '+' : ''}
      {percent
        ? `${percent.toLocaleString('en-US', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2
          })}`
        : '0.0'}
      %
    </span>
  );
};
