import React, { useState, useRef, useCallback, useEffect } from 'react';
import dayjs from 'dayjs';
import { COUNTDOWN_STATUS } from 'core/constant';
import utc from 'dayjs/plugin/utc';
import duration from 'dayjs/plugin/duration';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);

interface CountdownProps {
  endTime: number;
  status: COUNTDOWN_STATUS;
  onStart?: () => void;
  onEnd?: () => void;
}

const twoDP = (n: number) => (n > 9 ? n : '0' + n);
const timeOut = '00:00';
const interval = 1000;

export const Countdown = ({
  status,
  endTime,
  onStart,
  onEnd
}: CountdownProps) => {
  const [time, setTime] = useState<string>(timeOut);
  const intervalIDRef = useRef<any>();

  const stopTimer = useCallback(() => {
    clearInterval(intervalIDRef.current);
    intervalIDRef.current = null;
  }, []);

  const startTimer = useCallback(() => {
    if (endTime) {
      let duration = dayjs.duration(endTime, 'milliseconds');

      intervalIDRef.current = setInterval(() => {
        duration = dayjs.duration(
          duration.asMilliseconds() - interval,
          'milliseconds'
        );

        if (duration.seconds() < 0) {
          setTime(timeOut);
          stopTimer();
          onEnd && onEnd();
          return;
        }
        const timestamp = `${twoDP(duration.minutes())}:${twoDP(
          duration.seconds()
        )}`;
        setTime(timestamp);
      }, 1000);
    } else {
      setTime(timeOut);
    }
  }, [endTime, stopTimer]);

  useEffect(() => {
    if (status === COUNTDOWN_STATUS.START) {
      if (intervalIDRef.current) {
        stopTimer();
      }
      startTimer();
      onStart && onStart();
    }
  }, [startTimer, stopTimer, status]);

  useEffect(() => {
    return () => {
      setTime(timeOut);
      stopTimer();
    };
  }, [stopTimer]);

  return <>{time}</>;
};
