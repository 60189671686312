import React, { useState, ReactNode, useEffect } from 'react';
import {
  MARKET_STATUS,
  MarketStatusType,
  STOCK_MARKET_ID
} from 'core/constant';
import { ConfigService } from 'core/services/config.service';

interface ContextType {
  statusMarketHNX: MarketStatusType;
  statusMarketHOSE: MarketStatusType;
  statusMarketUPCOM: MarketStatusType;
  getStatuByMarket: (id: string) => MarketStatusType;
  getMarketName: (id?: string) => string;
}

type Props = {
  children: ReactNode;
};

const Context = React.createContext<ContextType>({} as ContextType);

export const MarketProvider: React.FC<Props> = ({ children }) => {
  const [statusMarketHNX, setStatusMarketHNX] = useState(MARKET_STATUS.CLOSE);
  const [statusMarketHOSE, setStatusMarketHOSE] = useState(MARKET_STATUS.CLOSE);
  const [statusMarketUPCOM, setStatusMarketUPCOM] = useState(
    MARKET_STATUS.CLOSE
  );
  const [systemConfig, setSystemConfig] = useState<any>();

  const fetchSystemConfig = async () => {
    const data = await ConfigService.getSystemConfig();
    const systemConfigData = data
      ? JSON.parse(data?.['ORDER_EXCHANGE_MATCHING_TYPE'])
      : '';
    setSystemConfig(systemConfigData);
  };

  useEffect(() => {
    fetchSystemConfig();
  }, []);

  const parseTime = (timeStr: string) => {
    const [hours, minutes] = timeStr?.split(':')?.map(Number);
    const now = new Date();
    const parsedTime = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      hours,
      minutes
    );
    return parsedTime;
  };

  const handleStatusHOSE = () => {
    const hoseConfig = systemConfig?.HOSE;
    const now = new Date();
    // ATO time -> FromTime: "09:00", ToTime: "09:15"
    let startATOTime = parseTime(hoseConfig[0]?.FromTime);
    let endATOTime = parseTime(hoseConfig[0]?.ToTime);

    // Pending time -> FromTime: "11:30", ToTime: "13:00"
    let startPendingTime = parseTime(hoseConfig[2]?.FromTime);
    let endPendingTime = parseTime(hoseConfig[2]?.ToTime);

    // ATC time -> FromTime: "14:30", ToTime: "14:45"
    let startATCTime = parseTime(hoseConfig[4]?.FromTime);
    let endATCTime = parseTime(hoseConfig[4]?.ToTime);

    // check time and 0h - 9h and 14h45 - 0h: Close
    if (now >= endATCTime || now < startATOTime) {
      setStatusMarketHOSE(MARKET_STATUS.CLOSE);
      return;
    }

    // check time 9h - 9h15: ATO
    if (now >= startATOTime && now < endATOTime) {
      setStatusMarketHOSE(MARKET_STATUS.ATO);
      return;
    }

    // check time 11h30 - 13h: Pending
    if (now >= startPendingTime && now < endPendingTime) {
      setStatusMarketHOSE(MARKET_STATUS.PENDING);
      return;
    }

    // check time 14h30 - 14h45: ATC
    if (now >= startATCTime && now < endATCTime) {
      setStatusMarketHOSE(MARKET_STATUS.ATC);
      return;
    }

    setStatusMarketHOSE(MARKET_STATUS.OPEN);
  };

  const handleStatusHNX = () => {
    const hnxConfig = systemConfig?.HASTC || [];
    const now = new Date();

    // Open time -> FromTime: "09:00"
    let startOpenTime = parseTime(hnxConfig[0]?.FromTime);

    // Pending time -> FromTime: "11:30", ToTime: "13:00"
    let startPendingTime = parseTime(hnxConfig[1]?.FromTime);
    let endPendingTime = parseTime(hnxConfig[1]?.ToTime);

    // ATC time -> FromTime: "14:30", ToTime: "14:45"
    let startATCTime = parseTime(hnxConfig[3]?.FromTime);
    let endATCTime = parseTime(hnxConfig[3]?.ToTime);

    // check time and 0h - 9h and 14h45 - 0h: Close
    if (now >= endATCTime || now < startOpenTime) {
      setStatusMarketHNX(MARKET_STATUS.CLOSE);
      return;
    }

    // check time 11h30 - 13h: Pending
    if (now >= startPendingTime && now < endPendingTime) {
      setStatusMarketHNX(MARKET_STATUS.PENDING);
      return;
    }

    // check time 14h30 - 14h45: ATC
    if (now >= startATCTime && now < endATCTime) {
      setStatusMarketHNX(MARKET_STATUS.ATC);
      return;
    }
    setStatusMarketHNX(MARKET_STATUS.OPEN);
  };

  const handleStatusUPCOM = () => {
    const hnxConfig = systemConfig?.HASTC;
    const now = new Date();

    let startPendingTime = parseTime(hnxConfig[1]?.FromTime);
    let endPendingTime = parseTime(hnxConfig[1]?.ToTime);

    let startOpenTime = parseTime(hnxConfig[0]?.FromTime);
    let endOpenTime = parseTime(hnxConfig[4]?.ToTime);

    // check time and 0h - 9h and 15h - 0h: Close
    if (now >= endOpenTime || now < startOpenTime) {
      setStatusMarketUPCOM(MARKET_STATUS.CLOSE);
      return;
    }

    // check time 11h30 - 13h: Pending
    if (now >= startPendingTime && now < endPendingTime) {
      setStatusMarketUPCOM(MARKET_STATUS.PENDING);
      return;
    }

    setStatusMarketUPCOM(MARKET_STATUS.OPEN);
  };

  const handleStatus = () => {
    const now = new Date();
    const saturday = 6;
    const sunday = 0;

    // check time satuday - sunday: Đóng cửa -> HOSE, HNX
    if (now.getDay() === saturday || now.getDay() === sunday) {
      setStatusMarketHOSE(MARKET_STATUS.CLOSE);
      setStatusMarketHNX(MARKET_STATUS.CLOSE);
      setStatusMarketUPCOM(MARKET_STATUS.CLOSE);
      return;
    }

    handleStatusHOSE();
    handleStatusHNX();
    handleStatusUPCOM();
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      handleStatus();
    }, 1000);
    return () => clearInterval(intervalId);
  }, [systemConfig]);

  const getStatuByMarket = (id: string): MarketStatusType => {
    if (id === STOCK_MARKET_ID.HOSE) {
      return statusMarketHOSE;
    }
    if (id === STOCK_MARKET_ID.HNX) {
      return statusMarketHNX;
    }
    if (id === STOCK_MARKET_ID.UPCOM) {
      return statusMarketUPCOM;
    }
    return MARKET_STATUS.CLOSE;
  };

  const getMarketName = (id?: string): string => {
    if (!id) {
      return '';
    }

    if (id === 'HASTC') {
      return 'HNX';
    }

    return id;
  };

  const value: ContextType = {
    statusMarketHNX,
    statusMarketHOSE,
    statusMarketUPCOM,
    getStatuByMarket,
    getMarketName
  };
  return <Context.Provider {...{ value, children }} />;
};

export function useMarket() {
  return React.useContext(Context);
}
