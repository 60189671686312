import React from 'react';

type ArrowStockIconProps = {
  color?: string;
  isIncrease?: boolean;
};

export const ArrowStockIcon = ({ color, isIncrease }: ArrowStockIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className={`${isIncrease ? '' : 'transform rotate-180'}`}
    >
      <path
        d="M8.66675 8.00008V13.3334H7.33341V8.00008H2.66675L8.00008 2.66675L13.3334 8.00008H8.66675Z"
        fill={color}
      />
    </svg>
  );
};
