import React, { useEffect, useState } from 'react';
import TcbLogo from 'assets/images/tcb_logo.png';
import { RiCheckboxCircleFill, RiFileCopyFill } from 'react-icons/ri';
import AccountsCashChartSection from 'components/money/AccountsCashChartSection';
import { useAccount } from 'core/contexts';
import { removeAccents } from 'core/utils';
import { toast } from 'react-toastify';
import AccountSelect from 'components/money/AccountSelect';
import { EKYC } from 'core/constant';
import { AccountNotification, TitleSection } from 'components';
import { divide } from 'lodash';

const DepositPage = () => {
  const { account, accountInfo } = useAccount();
  const [accountSelected, setAccountSelected] = useState<any>();
  const [onCopy, setOnCopy] = useState<boolean>(false);
  const [isReloadAccountCashChart, setIsReloadAccountCashChart] = useState<boolean>(false);
  const [isDisablePage, setIsDisablePage] = useState<boolean>(false);

  useEffect(() => {
    if (!accountInfo || accountInfo?.accounts?.length < 2) {
      setIsDisablePage(true);
    }

    if (account) {
      setAccountSelected(account);
    } else {
      const initAccount =
        accountInfo?.accounts?.find((item: any) => {
          return item.data?.account_type_id == 'C';
        }) || accountInfo?.accounts[0];

      setAccountSelected(initAccount);
    }
  }, []);

  const handleAccountSelectChange = (data: any) => {
    setAccountSelected(data);
    setIsReloadAccountCashChart(!isReloadAccountCashChart);
  };

  const CopyToClipboard = (value: string, type: string) => {
    setOnCopy(true);
    navigator.clipboard.writeText(value);
    const message =
      type == 'BankNumber'
        ? 'Đã sao chép thông tin số tài khoản'
        : 'Đã sao chép thông tin đơn vị thụ hưởng';
    toast(message, {
      type: 'success',
      icon: <RiCheckboxCircleFill className="text-[#00C802]" />,
    });
    setOnCopy(false);
  };

  return (
    <>
      <div className="flex gap-2 p-2">
        <div className="w-[62%] border-style !border-t-0 rounded-lg h-auto min-h-[60vh] bg-black">
          <TitleSection title="Nạp tiền" />
          {account ? (
            <div className="flex flex-col gap-4 px-8 py-4 ">
              <div className="flex gap-2 items-center border border-primary rounded-lg w-[315px] h-[74px] p-4">
                <img src={TcbLogo} alt="TcbLogo" width={55.67} height={42} />
                <div className=" text-sm font-normal leading-5">
                  <span className=" text-base font-semibold leading-[22px]">TCB</span>
                  <br />
                  Ngân hàng Kỹ Thương VN
                </div>
              </div>
              <div className="form-input">
                <label htmlFor="" className="form-input__label">
                  Tiểu khoản nhận tiền
                </label>
                <AccountSelect
                  buttonContainerClass="w-full"
                  itemContainerClass="w-full"
                  handleAccountSelectChange={handleAccountSelectChange}
                  accountSelected={accountSelected}
                  isDisablePage={isDisablePage}
                />
              </div>
              <div className="form-input">
                <label htmlFor="" className="form-input__label">
                  Số tài khoản
                </label>
                <div className="relative">
                  <input
                    className="form-input__input form-input__input-editable"
                    value={accountSelected?.account_id}
                    readOnly
                  ></input>
                  <div
                    className="absolute right-4 top-[10px] cursor-pointer"
                    onClick={() => {
                      if (onCopy) return;
                      CopyToClipboard(accountSelected?.account_id?.toString() || '', 'BankNumber');
                    }}
                  >
                    <RiFileCopyFill size={24} />
                  </div>
                </div>
              </div>
              <div className="form-input">
                <label htmlFor="" className="form-input__label">
                  Đơn vị thụ hưởng
                </label>
                <div className="relative">
                  <input
                    className="form-input__input form-input__input-editable"
                    value={
                      accountSelected?.local_name
                        ? removeAccents(accountSelected?.local_name)
                        : '--'
                    }
                    readOnly
                  ></input>
                  <div
                    className="absolute right-4 top-[10px] cursor-pointer"
                    onClick={() => {
                      if (onCopy) return;
                      CopyToClipboard(
                        accountSelected?.local_name
                          ? removeAccents(accountSelected?.local_name)
                          : '--',
                        'BankName'
                      );
                    }}
                  >
                    <RiFileCopyFill size={24} />
                  </div>
                </div>
              </div>
              <div className="form-input">
                <label htmlFor="" className="form-input__label">
                  Nội dung chuyển khoản
                </label>
                <input
                  className="form-input__input form-input__input-editable"
                  readOnly
                  placeholder="Không bắt buộc"
                ></input>
              </div>
            </div>
          ) : (
            <div className="flex justify-center items-center h-full">
              <AccountNotification />
            </div>
          )}
        </div>
        <div className="w-[38%]">
          <AccountsCashChartSection isReload={isReloadAccountCashChart} />
        </div>
      </div>
    </>
  );
};

export default DepositPage;
