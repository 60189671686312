import React from 'react';
import { SortConfig } from 'core/types';

export const useSortableData = (config?: SortConfig) => {
  const [sortConfig, setSortConfig] = React.useState<SortConfig | undefined>(
    config
  );

  const requestSort = (key: string) => {
    let direction: 'asc' | 'desc' = 'asc';
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === 'asc'
    ) {
      direction = 'desc';
    }
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === 'desc'
    ) {
      setSortConfig(undefined);
      return;
    }
    const newConfig: SortConfig = { key, direction };
    setSortConfig(newConfig);
  };

  return { requestSort, sortConfig, setSortConfig };
};
