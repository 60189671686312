import React, { Fragment, useEffect, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import TransactionTypeItem from 'components/TransactionTypeItem';
import { useAccount } from 'core/contexts';
import { RiArrowDownSFill, RiCloseFill, RiErrorWarningLine } from 'react-icons/ri';
import { DatePicker, Radio } from 'antd';
import OrderHistoryTable from 'components/orderHistory/OrderHistoryTable';
import { AccountNotification, SpinnerIcon, TitleSection } from 'components';
import { AssetsService } from 'core/services';
import { toast } from 'react-toastify';
import { MTA_ACCOUNT_DESCRIPTION_MARGIN, MTA_ACCOUNT_DESCRIPTION_NORMAL } from 'core/constant';
import { CashStatementData } from 'core/types';
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;
const FillterItemStyle =
  'px-2 py-1 flex gap-2 items-center rounded border border-solid border-[#1D2939] cursor-pointer';

const commandTypeData = [
  {
    id: 1,
    value: '',
    label: 'Tất cả',
  },
  {
    id: 2,
    value: 'Deposit_Withdraw',
    label: 'Nạp/Rút tiền',
  },
  {
    id: 3,
    value: 'Stock',
    label: 'Giao dịch chứng khoán',
  },
  {
    id: 4,
    value: 'Debt_PayADebt',
    label: 'Vay/Trả nợ',
  },
  {
    id: 5,
    value: 'Devidend_Interest',
    label: 'Cổ tức/Trái tức/Lãi tiền gửi',
  },
  {
    id: 6,
    value: 'Other',
    label: 'Khác',
  },
];

const MoneyHistory = () => {
  const [transactionType, setTransactionType] = useState<string>();
  const { account, accountInfo } = useAccount();
  const [accountData, setAccountData] = useState<any>();
  const [fromDate, setFromDate] = useState<any>(
    dayjs().subtract(1, 'months').format('YYYY-MM-DDT00:00:00')
  );
  const [toDate, setToDate] = useState<any>(
    dayjs().subtract(1, 'days').format('YYYY-MM-DDT23:59:59')
  );
  const [orderType, setOrderType] = useState<any>();
  const [cashHistory, setCashHistory] = useState<CashStatementData>();
  const [loading, setLoading] = useState<boolean>(false);
  const [isShowFilterDate, setIsShowFilterDate] = useState<boolean>(false);
  const [isShowFilterType, setIsShowFilterType] = useState<boolean>(false);

  const getCashHistory = async (params: any) => {
    setLoading(true);
    const res = await AssetsService.getCashStatement(params);
    if (res?.data?.status === 'Success') {
      setCashHistory(res?.data?.data);
    }
    setLoading(false);
  };

  const onSelectItem = (stockCode: string) => {
    if (stockCode === 'M') {
      setAccountData(accountInfo?.accounts?.find((item: any) => item.account_type_id === 'M'));
      setTransactionType('M');
    } else {
      setAccountData(accountInfo?.accounts?.find((item: any) => item.account_type_id === 'C'));
      setTransactionType('C');
    }
  };

  const handChangeOrderType = (e: any) => {
    setOrderType(e.target.value);
  };

  const handleClearFillter = () => {
    setOrderType(null);
    setFromDate(dayjs().subtract(1, 'months').format('YYYY-MM-DDT00:00:00'));
    setToDate(dayjs().subtract(1, 'days').format('YYYY-MM-DDT23:59:59'));
    setIsShowFilterDate(false);
  };

  useEffect(() => {
    if (account) {
      setAccountData(account);
      setTransactionType(account?.account_type_id);
    }
  }, []);

  useEffect(() => {
    const fromDateMoment = dayjs(fromDate);
    const toDateMoment = dayjs(toDate);
    const limitDate = dayjs().subtract(6, 'months');
    const diffDays = toDateMoment.diff(fromDateMoment, 'days');
    if (fromDateMoment.isBefore(limitDate) || toDateMoment.isBefore(limitDate)) {
      toast(
        'Hệ thống chỉ hỗ trợ tra cứu trong 6 tháng gần nhất, mỗi lần tra cứu không quá 1 tháng.',
        {
          type: 'error',
          icon: <RiErrorWarningLine className="text-[#F04438]" />,
        }
      );
    } else if (diffDays > 30) {
      toast('Thời gian chọn không được lớn hơn 30 ngày. Vui lòng thử lại.', {
        type: 'error',
        icon: <RiErrorWarningLine className="text-[#F04438]" />,
      });
      return;
    } else {
      if (accountData || account) {
        let params = {
          accountNo: accountData?.account_id || account?.account_id,
          fromDate,
          toDate,
          types: orderType?.value,
        };

        if (orderType?.value === '') {
          delete params.types;
        }

        getCashHistory(params);
      }
    }
  }, [accountData?.account_id, fromDate, toDate, orderType]);

  const handleRemoveDateFilter = () => {
    setFromDate(dayjs().subtract(1, 'months').format('YYYY-MM-DDT00:00:00'));
    setToDate(dayjs().subtract(1, 'days').format('YYYY-MM-DDT23:59:59'));
    setIsShowFilterDate(false);
  };

  return (
    <div
      className="h-full p-2"
      onClick={() => {
        setIsShowFilterType(false);
      }}
    >
      <div className="bg-black rounded-lg border-style !border-t-0 h-full">
        <TitleSection title="Sao kê tiền" />
        <div className="my-4">
          <div className="py-2 px-4 flex gap-2">
            <Menu as="div" className="relative inline-block">
              <Menu.Button>
                <div className={`${FillterItemStyle} bg-primary`}>
                  <p className="text-sm font-semibold leading-5">
                    {transactionType === 'M' ? 'Ký quỹ' : 'Thường'}
                  </p>
                  <RiArrowDownSFill className="text-sm" />
                </div>
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <div className="absolute top-[33px] left-0 w-[370px] bg-black p-2 rounded-md text-text_main_dark border border-solid border-gray">
                  <TransactionTypeItem
                    stockCode="M"
                    stockName="Ký quỹ"
                    description={MTA_ACCOUNT_DESCRIPTION_MARGIN}
                    isSelected={transactionType === 'M'}
                    onSelectItem={onSelectItem}
                  />
                  <TransactionTypeItem
                    stockCode="C"
                    stockName="Thường"
                    description={MTA_ACCOUNT_DESCRIPTION_NORMAL}
                    isSelected={transactionType === 'C'}
                    onSelectItem={onSelectItem}
                  />
                </div>
              </Transition>
            </Menu>
            <div className="datepicker relative">
              <RangePicker
                onChange={(dates: any, dateStrings: any) => {
                  if (dateStrings[0] && dateStrings[1]) {
                    setFromDate(dateStrings[0] + 'T00:00:00');
                    setToDate(dateStrings[1] + 'T23:59:59');
                    setIsShowFilterDate(true);
                  }
                }}
                disabledDate={current => {
                  return current && current > dayjs().startOf('day');
                }}
                placeholder={['Từ ngày', 'Đến ngày']}
                className="datepicker__input opacity-0 absolute top-0 left-0 w-full h-full cursor-pointer"
              />
              <div className="flex items-center gap-[2px] px-2 py-1 border border-solid border-tab_background bg-black rounded">
                <p className="text-sm font-semibold leading-5">
                  {'Ngày GD: ' +
                    (fromDate ? dayjs(fromDate).format('DD/MM/YYYY') : 'Từ ngày') +
                    ' - ' +
                    (toDate ? dayjs(toDate).format('DD/MM/YYYY') : 'Đến ngày')}
                </p>
                <RiArrowDownSFill className="text-sm" />
              </div>
            </div>
            <Menu as="div" className="relative inline-block">
              <Menu.Button>
                <div
                  className={FillterItemStyle}
                  onClick={e => {
                    e.stopPropagation();
                    setIsShowFilterType(!isShowFilterType);
                  }}
                >
                  <p className="text-sm font-semibold leading-5">Loại lệnh</p>
                  <RiArrowDownSFill className="text-sm" />
                </div>
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
                show={isShowFilterType}
              >
                <div
                  className="absolute top-[33px] left-0 w-[230px] bg-black p-2 rounded-md text-text_main_dark border border-solid border-tab_background"
                  onClick={() => {
                    setIsShowFilterType(false);
                  }}
                >
                  <Radio.Group
                    value={orderType}
                    onChange={handChangeOrderType}
                    className="flex flex-col z-50"
                  >
                    {commandTypeData?.map(item => {
                      return (
                        <Radio
                          key={item.id}
                          value={item}
                          className="radio-button-style !text-xs font-normal !leading-[18px] tracking-[0.12px]"
                        >
                          {item.label}
                        </Radio>
                      );
                    })}
                  </Radio.Group>
                </div>
              </Transition>
            </Menu>
          </div>
          <div className="py-2 px-4 flex gap-2 text-sm font-semibold leading-5">
            {(orderType || isShowFilterDate) && (
              <div
                className="px-2 h-7 text-primary flex items-center rounded border border-solid border-primary cursor-pointer"
                onClick={handleClearFillter}
              >
                <p>Xóa hết</p>
              </div>
            )}
            {orderType && (
              <div className="px-2 h-7 text-text_main_light flex gap-2 justify-center items-center rounded bg-blackBlue cursor-pointer">
                <p>{orderType?.label}</p>
                <div
                  onClick={() => {
                    setOrderType(null);
                  }}
                >
                  <RiCloseFill className="text-sm" />
                </div>
              </div>
            )}
            {isShowFilterDate && (
              <div className="px-2 h-7 text-text_main_light flex gap-2 justify-center items-center rounded bg-blackBlue cursor-pointer">
                <p>
                  {dayjs(fromDate).format('DD/MM/YYYY') +
                    ' - ' +
                    dayjs(toDate).format('DD/MM/YYYY')}
                </p>
                <div
                  onClick={() => {
                    handleRemoveDateFilter();
                  }}
                >
                  <RiCloseFill className="text-sm" />
                </div>
              </div>
            )}
          </div>
        </div>
        {loading ? (
          <div className="flex justify-center items-center">
            <SpinnerIcon className="scale-125" />
          </div>
        ) : (
          <>
            <div className="px-4 py-2 flex gap-[48px] my-4">
              <div className="flex flex-col gap-2 min-w-[205px]">
                <p className="text-sm font-normal leading-5">Số dư đầu kì</p>
                <span
                  className={`text-base font-semibold leading-[22px] ${
                    cashHistory?.open_balance && cashHistory?.open_balance > 0
                      ? 'text-vividMalachite'
                      : ''
                  }`}
                >
                  {cashHistory?.open_balance.toLocaleString('en-US') || '-'}
                </span>
              </div>
              <div className="flex flex-col gap-2">
                <p className="text-sm font-normal leading-5">Số dư cuối kì</p>
                <span
                  className={`text-base font-semibold leading-[22px] ${
                    cashHistory?.close_balance && cashHistory?.close_balance > 0
                      ? 'text-vividMalachite'
                      : ''
                  }`}
                >
                  {cashHistory?.close_balance.toLocaleString('en-US') || '-'}
                </span>
              </div>
            </div>
            <div className={`${cashHistory?.transactions ? 'border-bottom-style' : ''}`}>
              <OrderHistoryTable cashHistory={cashHistory?.transactions} />
            </div>
            {!account && (
              <div className="flex justify-center items-center pt-[157px]">
                <AccountNotification />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default MoneyHistory;
