import React, { ReactNode } from 'react';
import CurrencyFormat from 'react-currency-format';
import { RiAddLine, RiSubtractLine } from 'react-icons/ri';

type Props = {
  value?: number;
  initValue?: number;
  stepSize: number;
  className?: string;
  placeholder?: string;
  onChange?: (value: number) => void;
  disabled?: boolean;
  disabledIncrease?: boolean;
  type?: 'price' | 'quanlity';
};

export const InputPrice = ({
  stepSize = 10,
  value,
  initValue = 0,
  placeholder,
  disabled,
  disabledIncrease,
  type = 'quanlity',
  onChange,
  className
}: Props) => {
  const step = type === 'price' ? stepSize / 1000 : stepSize;
  const decimalScale = type === 'price' ? (stepSize < 100 ? 2 : 1) : 1;

  const onIncrease = () => {
    const v = (value ? value : initValue) + step;
    onChange && onChange(v);
  };

  const onDecrease = () => {
    if ((!value && type === 'quanlity') || value === 0) {
      return;
    }
    const v = (value ? value : initValue) - step;
    onChange && onChange(v);
  };

  const onValueChange = (v: any) => {
    const floatValue = v?.floatValue || undefined;
    onChange && onChange(floatValue);
  };


  return (
    <div
      className={`rounded-md border border-yankeesBlue flex overflow-hidden ${className}`}
    >
      <button
        className="block bg-yankeesBlue p-2 h-[38px] w-10 rounded-tl-sm rounded-bl-sm flex items-center justify-center text-text_main_med disabled:bg-[#1D2939] disabled:text-[#98A2B3]"
        disabled={disabled || value === 0 || (!value && type === 'quanlity')}
        onClick={onDecrease}
      >
        <RiSubtractLine className="text-[28px]" />
      </button>
      <CurrencyFormat
        className="bg-[transparent] p-2 flex-1 text-center text-sm placeholder:text-cadetGrey"
        value={value}
        placeholder={placeholder}
        onValueChange={onValueChange}
        thousandSeparator={true}
        allowNegative={false}
        decimalScale={decimalScale}
      />
      <button
        className="block bg-yankeesBlue p-2 h-[38px] w-10 rounded-tr-sm rounded-br-sm flex items-center justify-center text-text_main_med disabled:bg-[#1D2939] disabled:text-[#98A2B3]"
        disabled={disabledIncrease}
        onClick={onIncrease}
      >
        <RiAddLine className="text-[28px]" />
      </button>
    </div>
  );
};
