import React from 'react';

export const BellIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="26"
      viewBox="0 0 25 26"
      fill="none"
    >
      <path
        d="M20.8333 18.2083H22.9166V20.2916H2.08325V18.2083H4.16659V10.9166C4.16659 8.70645 5.04456 6.58683 6.60736 5.02403C8.17017 3.46123 10.2898 2.58325 12.4999 2.58325C14.7101 2.58325 16.8297 3.46123 18.3925 5.02403C19.9553 6.58683 20.8333 8.70645 20.8333 10.9166V18.2083ZM18.7499 18.2083V10.9166C18.7499 9.25898 18.0914 7.66927 16.9193 6.49717C15.7472 5.32507 14.1575 4.66659 12.4999 4.66659C10.8423 4.66659 9.2526 5.32507 8.0805 6.49717C6.9084 7.66927 6.24992 9.25898 6.24992 10.9166V18.2083H18.7499ZM9.37492 22.3749H15.6249V24.4583H9.37492V22.3749Z"
        fill="#98A2B3"
      />
    </svg>
  );
};