import React, { useRef, useState, useCallback, useEffect } from 'react';
import Webcam from 'react-webcam';
import { CameraOptions, useFaceDetection } from 'react-use-face-detection';
import FaceDetection from '@mediapipe/face_detection';
import { Camera } from '@mediapipe/camera_utils';
import { Button } from 'components';

type Props = {
  onSuccess: (data: any[]) => void;
  className?: string;
};

const width = 250;
const height = 250;

const enum STEP_CHECK_FACE {
  FACE_1,
  FACE_2,
  FACE_3
}

export const CameraLivenessCheck = ({ onSuccess, className }: Props) => {
  const [actionCheckFace, setActionCheckFace] = useState(
    STEP_CHECK_FACE.FACE_1
  );
  const [isCheckFace, setIsCheckFace] = useState(false);
  const [img1, setImg1] = useState<string>();
  const [img2, setImg2] = useState<string>();
  const [img3, setImg3] = useState<string>();

  const { webcamRef, boundingBox, isLoading, detected, facesDetected } =
    useFaceDetection({
      faceDetectionOptions: {
        model: 'short'
      },
      faceDetection: new FaceDetection.FaceDetection({
        locateFile: file =>
          `https://cdn.jsdelivr.net/npm/@mediapipe/face_detection/${file}`
      }),
      camera: ({ mediaSrc, onFrame }: CameraOptions) =>
        new Camera(mediaSrc, {
          onFrame,
          width,
          height
        })
    });

  const captureFace1 = useCallback(() => {
    const cam = webcamRef as any;
    const imageSrc = cam?.current?.getScreenshot();
    setImg1(imageSrc);
  }, [webcamRef, setImg1]);

  const captureFace2 = useCallback(() => {
    const cam = webcamRef as any;
    const imageSrc = cam?.current?.getScreenshot();
    setImg2(imageSrc);
  }, [webcamRef, setImg2]);

  const captureFace3 = useCallback(async () => {
    const cam = webcamRef as any;
    const imageSrc = cam?.current?.getScreenshot();
    setImg3(imageSrc);
  }, [webcamRef, setImg3]);

  useEffect(() => {
    if (!!img1 && !!img2 && !!img3) {
      const data = [
        {
          image: img1?.replace(/^data:image\/\w+;base64,/, ''),
          index: 1
        },
        {
          image: img2?.replace(/^data:image\/\w+;base64,/, ''),
          index: 2
        },
        {
          image: img3?.replace(/^data:image\/\w+;base64,/, ''),
          index: 3
        }
      ];
      onSuccess(data);
    }
  }, [img1, img2, img3]);

  useEffect(() => {
    if (isCheckFace && actionCheckFace === STEP_CHECK_FACE.FACE_1) {
      captureFace1();
      setTimeout(() => {
        setActionCheckFace(STEP_CHECK_FACE.FACE_2);
      }, 1000);
    }
  }, [isCheckFace]);

  useEffect(() => {
    if (isCheckFace && actionCheckFace === STEP_CHECK_FACE.FACE_2) {
      captureFace2();
      setTimeout(() => {
        setActionCheckFace(STEP_CHECK_FACE.FACE_3);
      }, 3000);
    }
  }, [actionCheckFace]);

  useEffect(() => {
    if (isCheckFace && actionCheckFace === STEP_CHECK_FACE.FACE_3) {
      setTimeout(() => {
        captureFace3();
      }, 2000);
    }
  }, [actionCheckFace]);

  const onVerify = () => {
    setIsCheckFace(true);
  };

  const getActionText = () => {
    if (facesDetected > 1) {
      return (
        <p className="text-base font-medium text-[#F04438]">
          Vui lòng chỉ có một khuôn mặt trong khung hình.
        </p>
      );
    }
    if (actionCheckFace === STEP_CHECK_FACE.FACE_1) {
      return (
        <p className="text-base font-medium text-primary">
          Nhìn thẳng vào camera.
        </p>
      );
    }
    if (actionCheckFace === STEP_CHECK_FACE.FACE_2) {
      return (
        <p className="text-base font-medium text-primary">
          Quay mặt từ từ sang trái.
        </p>
      );
    }
    if (actionCheckFace === STEP_CHECK_FACE.FACE_3) {
      return (
        <p className="text-base font-medium text-primary">
          Quay mặt từ từ sang phải.
        </p>
      );
    }
  };

  return (
    <div className={`flex flex-col items-center ${className ? className : ''}`}>
      <div className="min-h-[40px] mb-3">{getActionText()}</div>
      <Webcam
        ref={webcamRef}
        screenshotQuality={1}
        audio={false}
        screenshotFormat="image/jpeg"
        forceScreenshotSourceSize
        style={{
          width,
          height,
          borderRadius: '100%',
          objectFit: 'cover',
          transform: 'scaleX(-1)'
        }}
      />
      <Button
        disabled={isCheckFace}
        className="min-w-[199px] mt-8"
        onClick={() => {
          onVerify();
        }}
      >
        Bấm để bắt đầu xác thực
      </Button>
    </div>
  );
};
