import React from 'react';

export const SubtractIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M3.33325 7.33325H12.6666V8.66659H3.33325V7.33325Z"
        fill="#EAAA08"
      />
    </svg>
  );
};
