import React from 'react';

type PercentBoxProps = {
  percent: number;
};

export const PercentBox = ({ percent }: PercentBoxProps) => {
  return (
    <div
      className={`h-[18px] rounded-[2px] px-[2px] ${
        percent > 0
          ? 'bg-darkGreen text-vividMalachite'
          : percent < 0
          ? 'bg-red_light text-carminePink'
          : 'bg-yellow_light text-mustardYellow'
      }`}
    >
      <p className="text-xs font-semibold leading-[18px]">
        {percent >= 0 ? '+' : ''}
        {(percent === 0 || !percent) ? '0.0' : percent?.toFixed(1)}%
      </p>
    </div>
  );
};
