import { AccountNotification, ErrorToast, SpinnerIcon, TitleSection } from 'components';
import { Button } from 'components/buttons/Button';
import AccountSelect from 'components/money/AccountSelect';
import AccountsCashChartSection from 'components/money/AccountsCashChartSection';
import AddBankUserModal from 'components/money/AddBankUserModal';
import ConfirmPinModal from 'components/money/ConfirmPinModal';
import { AccountService, ConfigService } from 'core';
import { EKYC } from 'core/constant';
import {
  F_TRANSFER_MAX_WITHDRAW_AMOUNT,
  F_TRANSFER_MIN_WITHDRAW_AMOUNT,
} from 'core/constant/storage.constant';
import { useAccount } from 'core/contexts';
import { BankService } from 'core/services/bank.service';
import { WalletCashBalReponseData, WalletService } from 'core/services/wallet.service';
import { BankUserType } from 'core/types/BankUser.type';
import { getDigitCertRequest, handleErrorMessage } from 'core/utils';
import { sha256 } from 'js-sha256';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { RiCheckboxCircleFill, RiDeleteBinLine, RiErrorWarningLine } from 'react-icons/ri';
import { NumericFormat } from 'react-number-format';
import { toast } from 'react-toastify';

const myWindow = window as any;

const WithdrawPage = () => {
  const { account, accountInfo } = useAccount();
  const [systemConfig, setSystemConfig] = useState<any>();
  const [fromAccountSelected, setFromAccountSelected] = useState<any>();
  const [onToAccountChange, setOnToAccountChange] = useState<boolean>(false);
  const [withdrawBalance, setWithdrawBalance] = useState<WalletCashBalReponseData>();
  const [amountInput, setAmountInput] = useState<any>();
  const [isShowConfirmPinModal, setIsShowConfirmPinModal] = useState<boolean>(false);
  const [banksUser, setBanksUser] = useState<BankUserType[]>();
  const [bankSelected, setBankSelected] = useState<BankUserType>();
  const [isDisablePage, setIsDisablePage] = useState<boolean>(false);

  const [isShowAddBankUserPopup, setIsShowAddBankUserPopup] = useState<any>(false);

  const [isReloadAccountCashChart, setIsReloadAccountCashChart] = useState<boolean>(false);

  const [fee, setFee] = useState<any>(0);
  const [factorAuthenType, setFactorAuthenType] = useState<
    'PIN' | 'SMART_OTP' | 'SMS_OTP' | 'DIGIT_CERT'
  >();
  const [certInfo, setCertInfo] = useState<any>();

  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    mode: 'onChange',
  });

  const getAccountCashBalance = async (accountNo: any) => {
    setOnToAccountChange(true);
    const res = await WalletService.getCashBal(accountNo);
    if (res?.data?.status === 'Success') {
      setWithdrawBalance(res?.data?.data);
      setOnToAccountChange(false);
      if (!res?.data?.data?.withdrawal_bal) {
        setIsDisablePage(true);
      } else {
        setIsDisablePage(false);
      }
    } else {
      setWithdrawBalance(undefined);
      setOnToAccountChange(false);
      toast(handleErrorMessage(res?.data?.error_message) || 'Lỗi kết nối mạng, vui lòng thử lại', {
        type: 'error',
        icon: <RiErrorWarningLine className="text-[#F04438]" />,
      });
    }
  };

  const getBanksUser = async () => {
    const res = await BankService.getBankUser();

    if (res?.data?.status === 'Success') {
      setBanksUser(res?.data?.data);
    }
  };

  const deleteBankUser = async (bankUserId: string) => {
    try {
      const params = {
        user_bank_id: bankUserId,
      };
      const res = await BankService.deleteBankUser(params);

      if (res?.data?.status === 'Success') {
        toast('Xóa ngân hàng thành công!', {
          type: 'success',
          icon: <RiCheckboxCircleFill className="text-[#00C802]" />,
        });
        getBanksUser();
      } else {
        toast(
          handleErrorMessage(res?.data?.error_message) || 'Lỗi kết nối mạng, vui lòng thử lại',
          {
            type: 'error',
            icon: <RiErrorWarningLine className="text-[#F04438]" />,
          }
        );
      }
    } catch (error) {
      console.log(error);
      toast('Lỗi kết nối mạng, vui lòng thử lại', {
        type: 'error',
        icon: <RiErrorWarningLine className="text-[#F04438]" />,
      });
    }
  };

  useEffect(() => {
    const fetchSystemConfig = async () => {
      const data = await ConfigService.getSystemConfig();
      setSystemConfig(data);
    };

    fetchSystemConfig();
    getBanksUser();

    if (!accountInfo || accountInfo?.accounts?.length < 2) {
      setIsDisablePage(true);
    }

    if (account) {
      setFromAccountSelected(account);
    } else {
      const initAccount =
        accountInfo?.accounts?.find((item: any) => {
          return item.data?.account_type_id == 'C';
        }) || accountInfo?.accounts[0];

      setFromAccountSelected(initAccount);
    }

    getSettings();
  }, []);

  useEffect(() => {
    if (fromAccountSelected) {
      getAccountCashBalance(fromAccountSelected.account_id);
    }
  }, [fromAccountSelected]);

  const submitFormWithdraw = (formValues: any) => {
    setIsShowConfirmPinModal(true);
  };

  const handleAccountSelectChange = (value: any) => {
    if (amountInput) {
      setAmountInput(0);
    }
    setFromAccountSelected(value);
    setIsReloadAccountCashChart(!isReloadAccountCashChart);
    setValue('transfer_amount', undefined, { shouldValidate: false });
    setValue('bank_key', undefined, { shouldValidate: false });
    setBankSelected(undefined);
    setFee(0);
  };

  const getSystemConfig = (key: string) => {
    return systemConfig ? JSON.parse(systemConfig?.[key]) : '';
  };

  const validateWithdrawAmount = (input: string) => {
    if (input == undefined) {
      return true;
    }

    if (!input || input.trim() === '') {
      return 'Nhập số tiền cần rút';
    }

    const minConfig = getSystemConfig(F_TRANSFER_MIN_WITHDRAW_AMOUNT) || 0;
    const maxConfig = getSystemConfig(F_TRANSFER_MAX_WITHDRAW_AMOUNT) || 0;

    if (parseInt(input) < minConfig) {
      return `Số tiền cần nhập tối thiểu là ${minConfig?.toLocaleString('en-US')}đ`;
    }

    let max = 0;
    let max_mes = '';

    if (maxConfig > (withdrawBalance?.withdrawal_bal || 0)) {
      max = withdrawBalance?.withdrawal_bal || 0;
      max_mes = 'Số tiền muốn rút vượt quá số dư khả dụng';
    } else {
      max = maxConfig;
      max_mes = `Số tiền rút tối đa là ${maxConfig?.toLocaleString('en-US')}đ`;
    }

    if (parseInt(input) > max) {
      return max_mes;
    }

    return true;
  };

  const onAmountChange = (value: any) => {
    if (!+value?.value) {
      return;
    }
    const inputValue = value?.value;
    setAmountInput(inputValue);
    setValue('transfer_amount', inputValue, { shouldValidate: true });
  };

  const resetForm = () => {
    setAmountInput(0);
    setValue('transfer_amount', undefined, { shouldValidate: false });
    setValue('bank_key', undefined, { shouldValidate: false });
    setBankSelected(undefined);
    setFee(0);
  };

  const getSettings = async () => {
    const res = await AccountService.getSettings('TwoFactorAuthentication');
    const type = res?.data.data?.value;
    if (!type) {
      ErrorToast('Không thể lấy phương thức xác thực');
      return;
    }

    setFactorAuthenType(type);
  };

  const handleSubmitWithdraw = async (data: any) => {
    let res = null;

    try {
      let params: any = {
        factor_authen_enum_type: factorAuthenType,
        pin: sha256(data),
        otp: '',
        account_no: fromAccountSelected?.account_id,
        bank_key: bankSelected?.bank_key,
        branch_id: '',
        account_number: bankSelected?.account_number,
        account_name: bankSelected?.account_name,
        transfer_amount: parseInt(getValues('transfer_amount')),
      };

      if (data && (factorAuthenType === 'SMART_OTP' || factorAuthenType === 'SMS_OTP')) {
        params.otp = data;
        params.pin = '';
      } else {
        const data = {
          account_no: fromAccountSelected?.account_id,
          bank_key: bankSelected?.bank_key,
          branch_id: '',
          account_number: bankSelected?.account_number,
          account_name: bankSelected?.account_name,
          transfer_amount: parseInt(getValues('transfer_amount')),
        };

        const digit_cert_request = await getDigitCertRequest(data, certInfo);
        params.digit_cert_request = digit_cert_request;

        // let signedData = '';

        // const handleSignCMS = async (event: any) => {
        //   signedData = event.detail;
        //   params.digit_cert_request = {
        //     original_data: JSON.stringify(data),
        //     signed_data: JSON.parse(signedData).data,
        //     cert_base64: certInfo?.base64,
        //     signature_base64: '',
        //     serial_id: certInfo?.serial,
        //     subject_cn: certInfo?.subjectCN,
        //     issuer_cn: certInfo?.issuerCN,
        //     not_before: dayjs(certInfo?.notBefore).add(7, 'hours').toISOString(),
        //     not_after: dayjs(certInfo?.notAfter).add(7, 'hours').toISOString(),
        //     tax_number: certInfo?.taxNumber,
        //     personal_id: certInfo?.personalId || '',
        //     passport: certInfo?.passport,
        //   };

        //   params.pin = '';
        //   params.otp = '';

        //   res = await WalletService.withdrawal(params);
        //   window.removeEventListener('SignCMSCompleted', handleSignCMS);
        //   return handleCheckResponse(res);
        // };

        // window.addEventListener('SignCMSCompleted', handleSignCMS);

        // try {
        //   await myWindow?.signCMS(JSON.stringify(data));
        // } catch (error) {
        //   console.log('SignCMS Error:', error);
        // }
      }

      res = await WalletService.withdrawal(params);
      return handleCheckResponse(res);
    } catch (error) {
      toast('Lỗi kết nối mạng, vui lòng thử lại', {
        type: 'error',
        icon: <RiErrorWarningLine className="text-[#F04438]" />,
      });
      return false;
    }
  };

  const handleCheckResponse = (res: any) => {
    if (res?.data?.status === 'Success') {
      setTimeout(() => {
        getAccountCashBalance(fromAccountSelected?.account_id);
        setIsReloadAccountCashChart(!isReloadAccountCashChart);
        resetForm();
      }, 300);
      return true;
    } else {
      toast(handleErrorMessage(res?.data?.error_message) || 'Lỗi kết nối mạng, vui lòng thử lại', {
        type: 'error',
        icon: <RiErrorWarningLine className="text-[#F04438]" />,
      });
      return false;
    }
  };

  const handleClickBankItem = (bank: any) => {
    setBankSelected(bank);
    setValue('bank_key', bank?.bank_key, { shouldValidate: true });
    if (bank?.bank_code === 'TCB') {
      setFee(0);
    } else {
      setFee(5000);
    }
  };

  const handleClickDeleteBank = (event: any, bankUserId: string) => {
    event.stopPropagation();
    deleteBankUser(bankUserId);
  };

  const handleClickAddBankBtn = () => {
    if ((banksUser?.length || 0) >= 3 || (accountInfo?.accounts?.length || 0) < 2) {
      return;
    }

    setIsShowAddBankUserPopup(true);
  };

  return (
    <>
      <div className="flex p-2 gap-2">
        <div className="w-[62%] bg-black rounded-lg border-style !border-t-0 h-auto min-h-[60vh]">
          <TitleSection title="Rút tiền" />
          {account ? (
            <form
              onSubmit={handleSubmit(submitFormWithdraw)}
              autoComplete="off"
              className="h-[calc(100%-40px)]"
            >
              <div className="flex flex-col px-8 py-4 h-full">
                <div className="flex flex-col gap-4 flex-1">
                  <div className="flex justify-between">
                    <div className="form-input w-[276px]">
                      <label htmlFor="" className="form-input__label">
                        Chọn tiểu khoản rút tiền
                      </label>
                      <AccountSelect
                        buttonContainerClass="w-full"
                        itemContainerClass="w-full"
                        handleAccountSelectChange={handleAccountSelectChange}
                        accountSelected={fromAccountSelected}
                        isDisablePage={false}
                      />
                    </div>
                    <div className=" min-w-[160px] text-end">
                      <label htmlFor="" className="form-input__label w-full">
                        Số dư khả dụng
                      </label>
                      {!onToAccountChange && (
                        <div className="mt-[13px] text-[20px] font-semibold leading-[26px] text-end">
                          {`${
                            withdrawBalance?.withdrawal_bal
                              ? withdrawBalance?.withdrawal_bal?.toLocaleString('en-US')
                              : 0
                          }đ`}
                        </div>
                      )}
                      {onToAccountChange && (
                        <div className="mt-[10px] flex justify-center">
                          <SpinnerIcon size="small" />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-input">
                    <label htmlFor="" className="form-input__label">
                      Số tiền muốn rút
                    </label>
                    <NumericFormat
                      className="form-input__input form-input__input-editable"
                      placeholder={'Nhập số tiền muốn rút'}
                      onValueChange={onAmountChange}
                      thousandSeparator={true}
                      allowNegative={false}
                      allowLeadingZeros={false}
                      decimalScale={0}
                      value={amountInput}
                      disabled={isDisablePage}
                    />
                    <input
                      type="hidden"
                      {...register('transfer_amount', {
                        required: 'Nhập số tiền cần rút',
                        validate: validateWithdrawAmount,
                      })}
                    />
                    {errors?.transfer_amount && (
                      <p className="form-errors mt-1">{errors.transfer_amount.message as string}</p>
                    )}
                  </div>
                  <div className="form-input">
                    <label htmlFor="" className="form-input__label">
                      Chọn tài khoản nhận tiền
                    </label>
                    <div className="flex gap-2">
                      {banksUser?.map((bank: BankUserType) => (
                        <div
                          key={bank.user_bank_id}
                          onClick={() => handleClickBankItem(bank)}
                          className={`max-w-[25%] flex-1 h-[129px] rounded-[10px] p-4 cursor-pointer border ${
                            bank?.user_bank_id == bankSelected?.user_bank_id
                              ? 'border-primary'
                              : 'border-lineMed'
                          } `}
                        >
                          <div className="flex justify-between items-center">
                            <img src={bank?.logo} alt="banklogo" className="w-[33px] h-[25px]" />
                            <div
                              onClick={(event: any) =>
                                handleClickDeleteBank(event, bank?.user_bank_id)
                              }
                              className=" z-10"
                            >
                              <RiDeleteBinLine size={20} color="#475467" />
                            </div>
                          </div>
                          <div className=" text-xs font-normal leading-[18px] tracking-[-0.48px] text-text_main_light mt-2">
                            {bank?.bank_code}
                          </div>
                          <div className=" text-xs font-normal leading-[18px] tracking-[-0.48px] text-text_main_light mt-1">
                            {bank?.account_number}
                          </div>
                          <div className=" text-sm font-semibold leading-5 text-white mt-1">
                            {bank?.account_name}
                          </div>
                        </div>
                      ))}
                      {/* <div
                        onClick={() => handleClickAddBankBtn()}
                        className={`max-w-[25%] flex-1 h-[129px] cursor-pointer border border-dashed rounded-[10px] flex flex-col gap-1 items-center justify-center
                        ${
                          (banksUser?.length || 0) >= 3 || (accountInfo?.accounts?.length || 0) < 2
                            ? 'border-lineMed'
                            : 'border-primary'
                        }`}
                      >
                        <RiAddLine
                          size={33}
                          color={
                            (banksUser?.length || 0) >= 3 ||
                            (accountInfo?.accounts?.length || 0) < 2
                              ? '#98A2B3'
                              : '#0BA5EC'
                          }
                        />
                        <div
                          className={`text-sm font-normal leading-5 ${
                            (banksUser?.length || 0) >= 3 ||
                            (accountInfo?.accounts?.length || 0) < 2
                              ? 'text-gray'
                              : 'text-primary'
                          }`}
                        >
                          Thêm tài khoản nhận tiền
                        </div>
                      </div> */}
                    </div>
                    <input
                      type="hidden"
                      {...register('bank_key', {
                        required: 'Vui lòng chọn ngân hàng',
                      })}
                    />
                    {errors?.bank_key && (
                      <p className="form-errors mt-1">{errors.bank_key.message as string}</p>
                    )}
                  </div>
                  <div className="flex gap-2 text-primary bg-blackBlueLight p-4 rounded">
                    <RiErrorWarningLine className="text-2xl" />
                    <div className="text-sm">
                      Lưu ý: Để thêm tài khoản ngân hàng nhận tiền, Quý khách vui lòng liên hệ trực
                      tiếp với SBBS để đăng ký.
                    </div>
                  </div>
                </div>
                <div className="flex justify-between items-center">
                  <div className="">
                    <label htmlFor="" className="form-input__label">
                      Phí rút tiền
                    </label>
                    <div className="mt-1 text-sm font-semibold leading-5">{`${fee?.toLocaleString(
                      'en-US'
                    )}đ`}</div>
                  </div>
                  <div className="">
                    <Button
                      type={'submit'}
                      className="flex-1 bg-primary font-semibold text-white w-[247px] h-[44px] max-h-[44px]"
                      disabled={isDisablePage}
                      onClick={handleSubmit(submitFormWithdraw)}
                    >
                      Xác nhận
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          ) : (
            <div className="flex justify-center items-center h-full">
              <AccountNotification />
            </div>
          )}
        </div>
        <div className="w-[38%]">
          <AccountsCashChartSection isReload={isReloadAccountCashChart} />
        </div>
      </div>
      <ConfirmPinModal
        isShowModal={isShowConfirmPinModal}
        certInfo={certInfo}
        setIsShowModal={show => {
          setIsShowConfirmPinModal(show);
          setTimeout(() => {
            setCertInfo(null);
          }, 300);
        }}
        handleSubmit={handleSubmitWithdraw}
        authenType={factorAuthenType}
        handleGetCertInfo={data => {
          setCertInfo(data);
        }}
        successTitle="Tạo yêu cầu rút tiền thành công"
        successSubTitle="Yêu cầu rút tiền của Bạn được xử lý trong 02 ngày làm việc. Bạn có thể theo dõi trạng thái chuyển tiền trong “Sao kê tiền”."
      />
      <AddBankUserModal
        isShowModal={isShowAddBankUserPopup}
        setIsShowModal={setIsShowAddBankUserPopup}
        getBanksUser={getBanksUser}
      />
    </>
  );
};

export default WithdrawPage;
