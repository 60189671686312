import MultiCircleSupportBox from 'components/MultiCircleSupportBox';
import { Modal } from 'components/modal/Modal';
import React from 'react';
import { RiPhoneFill } from 'react-icons/ri';

type PhoneSupportModelProps = {
  isShowPhoneSupportModal: boolean;
  setIsShowPhoneSupportModal: (isShow: boolean) => void;
};

export const PhoneSupportModel = ({
  isShowPhoneSupportModal,
  setIsShowPhoneSupportModal,
}: PhoneSupportModelProps) => {
  return (
    <div>
      <Modal
        isOpen={isShowPhoneSupportModal}
        hasCloseBtn
        onClose={() => setIsShowPhoneSupportModal(false)}
        title={'Hỗ trợ qua điện thoại'}
        classNameTitle={'text-center !text-base !leading-[22px]'}
        className={`max-w-[640px]`}
        closeBtnClassName="!text-2xl"
      >
        <div className="mt-4 flex flex-col items-center justify-center gap-6">
          <div>
            <MultiCircleSupportBox
              sizeCircle_1="w-[78.97px] h-[78.97px]"
              sizeCircle_2="w-[134.43px] h-[134.43px]"
              sizeCircle_3="w-[200px] h-[200px]"
              colorCircle_1="bg-primary"
              colorCircle_2="bg-[#09395F]"
              colorCircle_3="bg-[#0B1D35]"
            >
              <RiPhoneFill color="#fff" className="w-10 h-10" />
            </MultiCircleSupportBox>
          </div>
          <p className="text-base text-center font-normal leading-[22px] text-text_main_light">
            Bạn cần hỗ trợ trực tiếp, hãy gọi cho tổng đài của SBBS <br /> số{' '}
            <span className="font-bold text-white">19002648</span> ngay nhé.
          </p>
        </div>
      </Modal>
    </div>
  );
};
