import React, { useEffect } from 'react';
import { Spinner } from 'components/spinner/Spinner';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';
import {
  IS_HOLIDAY_NEXT_DATE,
  IS_HOLIDAY_TODAY
} from 'core/constant/storage.constant';

const LogoutPage = () => {
  const auth = useAuth();
  const navigator = useNavigate();

  useEffect(() => {
    if (auth.isAuthenticated) {
      localStorage.removeItem('IS_HIDE_PIN_POPUP');
      localStorage.removeItem('PC');
      localStorage.removeItem('IS_SAVE_P');
      localStorage.removeItem('SYMBOL');
      localStorage.removeItem('DE_START');
      localStorage.removeItem('DE_END');
      localStorage.removeItem(IS_HOLIDAY_TODAY);
      localStorage.removeItem(IS_HOLIDAY_NEXT_DATE);
      auth.signoutRedirect();
      // auth.removeUser();
      // sessionStorage.clear();
    } else {
      //auth.signinRedirect();
    }
  }, [
    auth.isAuthenticated,
    auth.activeNavigator,
    auth.isLoading,
    auth.signinRedirect,
    auth.signinRedirect
  ]);

  return (
    <>
      <div className="flex">
        <Spinner />
      </div>
    </>
  );
};

export default LogoutPage;

// redirectMethod?: "replace" | "assign";
// redirectTarget?: "top" | "self";
