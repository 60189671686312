import React, { useState, useEffect } from 'react';
import { OrderType } from 'core/types';
import { StockOrderRow, OrderModal, ErrorToast, SuccessToast } from 'components';
import { MarketStockService } from 'core';
import { checkSavePin, getDigitCertRequest, isHidePopupConfirm } from 'core/utils';
import { useAccount } from 'core/contexts/AccountContext';
import dayjs from 'dayjs';
import { Base64 } from 'js-base64';
const { XMLParser } = require('fast-xml-parser');

const myWindow = window as any;

export const StockOrderTable = ({
  data,
  onRefresh,
  onUpdateOrder,
}: {
  data: OrderType[];
  onUpdateOrder: (order: OrderType) => void;
  onRefresh: () => void;
}) => {
  const { userInfo } = useAccount();
  const [orderCancel, setOrderCancel] = useState<OrderType>();
  const [isShowCancelOrder, setIsShowCancelOrder] = useState(false);
  const [certInfo, setCertInfo] = useState<any>();

  const onCancelOrder = async (
    pin: string,
    type?: 'PIN' | 'SMART_OTP' | 'SMS_OTP' | 'DIGIT_CERT',
    isCheckSavePinCode?: boolean
  ) => {
    let paramsdata: any = {
      order_no: orderCancel?.order_no,
      factor_authen_enum_type: type,
      pin: pin,
      otp: '',
    };

    if (pin && (type === 'SMART_OTP' || type === 'SMS_OTP')) {
      paramsdata = {
        ...paramsdata,
        otp: pin,
      };
    } else {
      const data = {
        order_no: orderCancel?.order_no,
      };

      const digit_cert_request = await getDigitCertRequest(data, certInfo);
      paramsdata = {
        ...paramsdata,
        digit_cert_request,
      };

      // const handleSignCMS = (event: any) => {
      //   signedData = event.detail;
      //   paramsdata = {
      //     ...paramsdata,
      //     digit_cert_request: {
      //       original_data: JSON.stringify(data),
      //       signed_data: signedData ? JSON.parse(signedData)?.data : '',
      //       cert_base64: certInfo?.base64,
      //       signature_base64: '',
      //       serial_id: certInfo?.serial,
      //       subject_cn: certInfo?.subjectCN,
      //       issuer_cn: certInfo?.issuerCN,
      //       not_before: dayjs(certInfo?.notBefore).add(7, 'hours').toISOString(),
      //       not_after: dayjs(certInfo?.notAfter).add(7, 'hours').toISOString(),
      //       tax_number: certInfo?.taxNumber,
      //       personal_id: certInfo?.personalId || '',
      //       passport: certInfo?.passport,
      //     },
      //   };

      //   handleCancelOrder(isCheckSavePinCode || false, paramsdata, pin, type);
      //   window.removeEventListener('SignCMSCompleted', handleSignCMS);
      // };

      // window.addEventListener('SignCMSCompleted', handleSignCMS);

      // try {
      //   await myWindow?.signCMS(JSON.stringify(data));
      // } catch (error) {
      //   console.log('SignCMS Error:', error);
      // }
    }
    await handleCancelOrder(isCheckSavePinCode || false, paramsdata, pin, type);
  };

  const handleCancelOrder = async (
    isCheckSavePinCode: boolean,
    paramsdata: any,
    pin: string,
    type?: 'PIN' | 'SMART_OTP' | 'SMS_OTP' | 'DIGIT_CERT'
  ) => {
    try {
      const res = await MarketStockService.cancelOrder(userInfo?.user_id || '', paramsdata);
      const data = res?.data;

      if (data?.status === 'Fail') {
        ErrorToast(data?.error_message.split('|')[1]);
        return;
      }
      if (data?.status === 'Success') {
        SuccessToast('Huỷ lệnh thành công');
        if (isShowCancelOrder) {
          setIsShowCancelOrder(false);
        }
        if (isCheckSavePinCode && type === 'PIN') {
          await checkSavePin(pin);
        }
        setOrderCancel(undefined);
        onRefresh();
        return;
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const init = async () => {
    if (orderCancel) {
      const pinSaved = await isHidePopupConfirm();
      if (pinSaved) {
        onCancelOrder(pinSaved);
        return;
      }
      setIsShowCancelOrder(true);
    } else {
      setIsShowCancelOrder(false);
    }
  };

  useEffect(() => {
    init();
  }, [orderCancel]);

  return (
    <>
      <table className="w-full order-book-table">
        <thead>
          <tr className="!font-semibold align-top">
            <th className={'font-semibold'}>
              <p>Mã</p>
              <p className="text-xs font-normal leading-[18px] tracking-[-0.12px]">Thời gian</p>
            </th>
            <th className={'font-semibold'}>Lệnh</th>
            <th className={'font-semibold'}>
              <p>KL khớp</p>
              <p className="text-xs font-normal leading-[18px] tracking-[-0.12px]">KL đặt</p>
            </th>
            <th className={'font-semibold'}>
              <p>Giá khớp</p>
              <p className="text-xs font-normal leading-[18px] tracking-[-0.12px]">Giá đặt</p>
            </th>
            <th className={'font-semibold'}>
              <p>Tổng giá trị khớp</p>
              <p className="text-xs font-normal leading-[18px] tracking-[-0.12px]">
                Tổng giá trị đặt
              </p>
            </th>
            <th className={'font-semibold text-right !pr-[11px]'}>Trạng thái</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data?.map((i: any) => (
            <StockOrderRow
              key={i?.order_no}
              order={i}
              onUpdate={order => onUpdateOrder(order)}
              onCancel={order => setOrderCancel(order)}
            />
          ))}
        </tbody>
      </table>
      {orderCancel && (
        <OrderModal
          isOpen={true}
          title="Huỷ lệnh"
          subTitle={'Bạn có chắc chắn muốn huỷ lệnh này không?'}
          certInfo={certInfo}
          stockInfo={{
            action: orderCancel.bid_ask_type,
            symbol: orderCancel.stock_symbol,
            price: orderCancel.order_price,
            quanlity: orderCancel.order_quantity,
          }}
          handleGetCertInfo={data => {
            setCertInfo(data);
          }}
          closeModal={() => {
            setIsShowCancelOrder(false);
            setTimeout(() => {
              setCertInfo(null);
            }, 300);
          }}
          onSubmit={(pin, type) => onCancelOrder(pin, type, true)}
        />
      )}
    </>
  );
};
