import { LoadingBarProcess, MasterLayout } from 'components'
import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { RequireAuth } from './Routes'
import TransferPage from 'pages/money/TransferPage'

const TransferCashRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<MasterLayout />}>
        <Route
          index
          element={
            <React.Suspense fallback={<LoadingBarProcess />}>
              <RequireAuth>
                <TransferPage />
              </RequireAuth>
            </React.Suspense>
          }
        />
      </Route>
    </Routes>
  )
}

export default TransferCashRoutes