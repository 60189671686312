import React from 'react';
import { toast } from 'react-toastify';
import { RiCheckboxCircleFill } from 'react-icons/ri';

export const SuccessToast = (message: string) => {
  return toast(message, {
    type: 'success',
    icon: <RiCheckboxCircleFill className="text-green" />,
  });
};
