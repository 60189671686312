import React from 'react';
import EventItem from './EventItem';
import { ComingSoonFeature } from 'components/ComingSoonFeature';

export const StockEvents = () => {
  return (
    <div className="flex justify-between gap-y-4 flex-wrap">
      {/* <EventItem /> */}
      <ComingSoonFeature className="justify-center w-full mt-[78px]" />
    </div>
  );
};
