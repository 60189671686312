import React, { useEffect, useState } from 'react';
import { useMarket } from 'core/contexts';
import { FaArrowsRotate, FaWifi } from 'react-icons/fa6';
import dayjs from 'dayjs';
import { STOCK_MARKET_ID } from 'core';

export const Footer = () => {
  const [formattedDate, setFormattedDate] = useState('');
  const [formattedTime, setFormattedTime] = useState('');
  const { statusMarketHNX, statusMarketHOSE, statusMarketUPCOM } = useMarket();
  const { getStatuByMarket } = useMarket();

  useEffect(() => {
    const updateDateTime = () => {
      const currentDate = dayjs();
      const formatted = currentDate.format('DD/MM/YYYY');
      const formattedTime = currentDate.format('HH:mm:ss');
      setFormattedDate(formatted);
      setFormattedTime(formattedTime);
    };

    const intervalId = setInterval(updateDateTime, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="bg-black px-4 flex justify-between items-center h-[29px] fixed z-30 bottom-0 left-0 right-0">
      <div className="flex gap-4">
        <FaArrowsRotate />
        <span className="text-vividMalachite">
          <FaWifi />
        </span>
      </div>
      <div className="flex items-center text-sm font-normal leading-5 text-[#fff] h-full">
        <div className="flex h-full">
          <div className="flex gap-7 px-3 border-r border-solid border-tab_background h-full justify-center items-center">
            <p>HOSE</p>
            <div className="flex gap-1 items-center">
              <p>{statusMarketHOSE.label}</p>
              <span
                className="w-3 h-3 block rounded-full"
                style={{
                  backgroundColor: getStatuByMarket(STOCK_MARKET_ID.HOSE).color
                }}
              ></span>
            </div>
          </div>
          <div className="flex gap-7 px-3 border-r border-solid border-tab_background h-full justify-center items-center">
            <p>HNX</p>
            <div className="flex gap-1 items-center">
              <p>{statusMarketHNX.label}</p>
              <span
                className="w-3 h-3 block rounded-full"
                style={{
                  backgroundColor: getStatuByMarket(STOCK_MARKET_ID.HNX).color
                }}
              ></span>
            </div>
          </div>
          <div className="flex gap-7 px-3 border-r border-solid border-tab_background h-full justify-center items-center">
            <p>UPCOM</p>
            <div className="flex gap-1 items-center">
              <p>{statusMarketUPCOM.label}</p>
              <span
                className="w-3 h-3 block rounded-full"
                style={{
                  backgroundColor: getStatuByMarket(STOCK_MARKET_ID.UPCOM).color
                }}
              ></span>
            </div>
          </div>
        </div>
        <div className="flex pr-2 gap-4 pl-3">
          <p>Giờ Việt Nam</p>
          <p className="max-w-[92px] w-[92px] text-right">{formattedDate}</p>
          <p className="max-w-[70px] w-[70px]">{formattedTime}</p>
        </div>
      </div>
    </div>
  );
};
