import React from 'react';

export const UserFillIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M2.6665 14.6665C2.6665 13.252 3.22841 11.8955 4.2286 10.8953C5.2288 9.89507 6.58535 9.33317 7.99984 9.33317C9.41432 9.33317 10.7709 9.89507 11.7711 10.8953C12.7713 11.8955 13.3332 13.252 13.3332 14.6665H11.9998C11.9998 13.6056 11.5784 12.5882 10.8283 11.8381C10.0781 11.0879 9.0607 10.6665 7.99984 10.6665C6.93897 10.6665 5.92156 11.0879 5.17141 11.8381C4.42126 12.5882 3.99984 13.6056 3.99984 14.6665H2.6665ZM7.99984 8.6665C5.78984 8.6665 3.99984 6.8765 3.99984 4.6665C3.99984 2.4565 5.78984 0.666504 7.99984 0.666504C10.2098 0.666504 11.9998 2.4565 11.9998 4.6665C11.9998 6.8765 10.2098 8.6665 7.99984 8.6665ZM7.99984 7.33317C9.47317 7.33317 10.6665 6.13984 10.6665 4.6665C10.6665 3.19317 9.47317 1.99984 7.99984 1.99984C6.5265 1.99984 5.33317 3.19317 5.33317 4.6665C5.33317 6.13984 6.5265 7.33317 7.99984 7.33317Z"
        fill="white"
      />
    </svg>
  );
};