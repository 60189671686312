import React, { useState, useEffect } from 'react';
import { useAccount } from 'core/contexts/AccountContext';
import { AssetsService } from 'core/services';
import { formatCurrency, formatDate } from 'core/utils';
import { CashStatementItem } from 'core/types';

const CashHistories = () => {
  const { account } = useAccount();
  const [cashHistoryData, setCashHistoryData] = useState<CashStatementItem[]>();

  const getCashHistory = async () => {
    if (account) {
      const params = {
        accountNo: account.account_id
      };
      const res = await AssetsService.getCashStatement(params);
      const data = res?.data?.data;
      setCashHistoryData(data?.transactions?.slice(0, 15));
    }
  };

  useEffect(() => {
    getCashHistory();
  }, [account]);

  return (
    <div className="p-1 pb-[145px]">
      <div className="bg-blackBlue px-4 py-2 flex text-sm">
        <div className="w-[30%]">Ngày</div>
        <div className="w-[30%]">Loại giao dịch</div>
        <div className="flex-1 text-right">Giá trị giao dịch</div>
      </div>
      <ul className="px-4">
        {cashHistoryData ? (
          cashHistoryData?.map((i: any) => (
            <li
              key={i.index}
              className="flex justify-between border-b border-gray py-3 text-sm"
            >
              <span className="text-sm w-[30%]">{formatDate(i.date)}</span>
              <span className="w-[30%] font-medium">{i.sub_type_name}</span>

              <span className="flex-1 text-right font-medium">
                {i.amount > 0 ? '+' : ''}
                {formatCurrency(i.amount)}
              </span>
            </li>
          ))
        ) : (
          <li className="flex justify-between border-b border-gray py-3 text-sm">
            <span className="text-sm w-[30%]">-</span>
            <span className="w-[30%] font-medium">-</span>

            <span className="flex-1 text-right font-medium">-</span>
          </li>
        )}
        <div className="mt-3 bg-blackBlue p-4">
          <p className="text-text_main_light text-sm font-normal leading-5 mb-2">
            Nếu có bất kỳ sai sót gì, quý khách vui lòng liên hệ đến phòng
            Nghiệp Vụ của SBBS để được hỗ trợ một cách kịp thời nhất.
          </p>
        </div>
      </ul>
    </div>
  );
};

export default CashHistories;
