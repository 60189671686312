import axios, { AxiosError, AxiosResponse, AxiosInstance } from 'axios';
import {
  HOST_URL,
  HOST_URL_V3,
  HOST_URL_NEWS,
  AUTH_HOST_URL,
  IS_PRODUCTION
} from 'core/constant/api.constant';
import { User } from 'oidc-client-ts';
import JSEncrypt from 'jsencrypt';
import { FirestoreService } from './firestore.service';
var CryptoJS = require('crypto-js');

const axiosInstance = axios.create({
  baseURL: HOST_URL,
  headers:
    IS_PRODUCTION === 'true'
      ? {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Accept-Language': 'vi,vi-VN;q=0.9,en;q=0.8,en-US;q=0.7'
        }
      : {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-signature': 1,
          'Accept-Language': 'vi,vi-VN;q=0.9,en;q=0.8,en-US;q=0.7'
        }
});

const axiosInstanceV3 = axios.create({
  baseURL: HOST_URL_V3,
  headers:
    IS_PRODUCTION === 'true'
      ? {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Accept-Language': 'vi,vi-VN;q=0.9,en;q=0.8,en-US;q=0.7'
        }
      : {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-signature': 1,
          'Accept-Language': 'vi,vi-VN;q=0.9,en;q=0.8,en-US;q=0.7'
        }
});

const axiosInstanceIdentity = axios.create({
  baseURL: AUTH_HOST_URL,
  headers:
    IS_PRODUCTION === 'true'
      ? {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Accept-Language': 'vi,vi-VN;q=0.9,en;q=0.8,en-US;q=0.7'
        }
      : {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-signature': 1,
          'Accept-Language': 'vi,vi-VN;q=0.9,en;q=0.8,en-US;q=0.7'
        }
});

const axiosInstanceNews = axios.create({
  baseURL: HOST_URL_NEWS,
  headers: {
    Accept: 'application/json',
    'Accept-Language': 'vi,vi-VN;q=0.9,en;q=0.8,en-US;q=0.7'
    // 'Content-Type': 'application/json',
    // 'X-Request-Origin': '*',
    // 'x-signature': 1
  }
});

export const axiosInstanceCloud = axios.create({
  headers: {
    'Content-Type': 'image/png'
  }
});

axiosInstanceCloud.interceptors.response.use(
  function (response: any) {
    return response;
  },
  function (error: AxiosError) {
    const { data, status } =
      error?.response || ({ data: null, status: null } as any);

    if (status === 401 || data?.error?.code === 401) {
      window.location.reload();
    }
    if (status === 404 || data?.error?.code === 404) {
      window.location.href = '/404';
    }
    if (status === 403 || data?.error?.code === 403) {
    }
    return Promise.reject(error);
  }
);

export function getUser() {
  const oidcStorage =
    sessionStorage.getItem(
      `oidc.user:${process.env.REACT_APP_authority}:${process.env.REACT_APP_client_id}`
    ) ||
    sessionStorage.getItem(
      `oidc.user:${process.env.REACT_APP_authority}:${process.env.REACT_APP_client_id}`
    );
  if (!oidcStorage) {
    return null;
  }
  return User.fromStorageString(oidcStorage);
}

axiosInstance.interceptors.request.use(async config => {
  const user = getUser();
  const token = user?.access_token;
  // add token to request headers
  config.headers['Authorization'] = 'Bearer ' + token;

  if (
    IS_PRODUCTION === 'true' &&
    config.headers['Content-Type'] === 'application/json' &&
    (config.method === 'post' ||
      config.method === 'put' ||
      config.method === 'delete')
  ) {
    config.data = await encryptData(config.data);
  }

  return config;
});

axiosInstanceV3.interceptors.request.use(async config => {
  const user = getUser();
  const token = user?.access_token;
  // add token to request headers
  config.headers['Authorization'] = 'Bearer ' + token;

  if (
    IS_PRODUCTION === 'true' &&
    config.headers['Content-Type'] === 'application/json' &&
    (config.method === 'post' ||
      config.method === 'put' ||
      config.method === 'delete')
  ) {
    config.data = await encryptData(config.data);
  }

  return config;
});

axiosInstanceIdentity.interceptors.request.use(async config => {
  const user = getUser();
  const token = user?.access_token;
  // add token to request headers
  config.headers['Authorization'] = 'Bearer ' + token;

  if (
    IS_PRODUCTION === 'true' &&
    config.headers['Content-Type'] === 'application/json' &&
    (config.method === 'post' ||
      config.method === 'put' ||
      config.method === 'delete')
  ) {
    config.data = await encryptData(config.data);
  }

  return config;
});

axiosInstanceNews.interceptors.request.use(async config => {
  const user = getUser();
  const token = user?.access_token;
  // add token to request headers
  config.headers['Authorization'] = 'Bearer ' + token;

  if (
    config.headers['Content-Type'] === 'application/json' &&
    (config.method === 'post' ||
      config.method === 'put' ||
      config.method === 'delete')
  ) {
    config.data = await encryptData(config.data);
  }

  return config;
});

axiosInstance.interceptors.response.use(
  function (response: any) {
    return response;
  },
  function (error: AxiosError) {
    const { data, status } =
      error?.response || ({ data: null, status: null } as any);

    if (status === 401 || data?.error?.code === 401) {
      window.location.reload();
    }
    if (status === 404 || data?.error?.code === 404) {
      //window.location.href = "/404";
    }
    if (status === 403 || data?.error?.code === 403) {
    }
    return Promise.reject(error);
  }
);

axiosInstanceV3.interceptors.response.use(
  function (response: any) {
    return response;
  },
  function (error: AxiosError) {
    const { data, status } =
      error?.response || ({ data: null, status: null } as any);

    if (status === 401 || data?.error?.code === 401) {
      window.location.reload();
    }
    if (status === 404 || data?.error?.code === 404) {
      //window.location.href = "/404";
    }
    if (status === 403 || data?.error?.code === 403) {
    }
    return Promise.reject(error);
  }
);

axiosInstanceIdentity.interceptors.response.use(
  function (response: any) {
    return response;
  },
  function (error: AxiosError) {
    const { data, status } =
      error?.response || ({ data: null, status: null } as any);

    if (status === 401 || data?.error?.code === 401) {
      window.location.reload();
    }
    if (status === 404 || data?.error?.code === 404) {
      //window.location.href = "/404";
    }
    if (status === 403 || data?.error?.code === 403) {
    }
    return Promise.reject(error);
  }
);

axiosInstanceNews.interceptors.response.use(
  function (response: any) {
    return response;
  },
  function (error: AxiosError) {
    const { data, status } =
      error?.response || ({ data: null, status: null } as any);

    if (status === 401 || data?.error?.code === 401) {
      window.location.reload();
    }
    if (status === 404 || data?.error?.code === 404) {
      //window.location.href = "/404";
    }
    if (status === 403 || data?.error?.code === 403) {
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
export { axiosInstanceV3 };
export { axiosInstanceNews };
export { axiosInstanceIdentity };

const encryptData = async (payload: any) => {
  if (!payload) {
    return null;
  }

  try {
    let apiPublicKey = await FirestoreService.getAPIPublicKey();
    const jsonData = JSON.stringify(payload);

    var key = CryptoJS.lib.WordArray.random(16);
    var iv = CryptoJS.lib.WordArray.random(8);

    let encryptedData = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(jsonData),
      CryptoJS.enc.Utf8.parse(key.toString()),
      { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7, iv: iv }
    );

    var crypt = new JSEncrypt();
    crypt.setPublicKey(apiPublicKey);
    const encryptedKey = crypt.encrypt(key.toString());

    return {
      key: encryptedKey,
      data: CryptoJS.enc.Base64.stringify(encryptedData.ciphertext)
    };
  } catch (err) {
    console.log(err);
    return null;
  }
};

export class RestfulService {
  static deleteApi = (
    url: string,
    body?: null
  ): Promise<AxiosResponse<null>> => {
    return axiosInstance
      .delete(url, {
        data: body
      })
      .then(res => res)
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          return error.response;
        }
      });
  };

  static postApi = (url: string, params?: any): Promise<AxiosResponse<any>> => {
    return (
      axiosInstance
        .post(url, params)
        //.post(url, { key: encryptedKey, data: encryptedData.ciphertext })
        .then(res => res)
        .catch(function (error) {
          if (error.response) {
            return error.response;
          }
        })
    );
  };

  static postApiFormData = (url: string, bodyFormData: FormData) => {
    return axiosInstance
      .post(url, bodyFormData)
      .then(res => res.data)
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          return error.response.data;
        }
      });
  };

  static putApi = (url: string, params?: any): Promise<AxiosResponse<any>> => {
    return axiosInstance
      .put(url, params)
      .then(res => res)
      .catch(function (error) {
        if (error.response) {
          return error.response;
        }
      });
  };
}
