import React from 'react';
import { NewsItem } from './NewsItem';
import { NoNewsIcon } from 'assets/icons';
import { NewsItemType } from 'core/types';

type StockNewsProps = {
  data: NewsItemType[];
};

export const StockNews = ({ data }: StockNewsProps) => {
  return data?.length > 0 ? (
    <div className="flex gap-y-4 flex-wrap">
      {data?.map((item: NewsItemType) => {
        return <NewsItem data={item} key={item?.id} />;
      })}
    </div>
  ) : (
    <div className="flex justify-center items-center mt-[78px] flex-col gap-4">
      <NoNewsIcon />
      <p className="text-base text-gray_dark font-normal leading-[22px]">
        Chưa có bài viết
      </p>
    </div>
  );
};
