import React from 'react';
import { RiEyeLine, RiEyeOffLine } from 'react-icons/ri';

type PasswordVisibilityToggleProps = {
  visible: boolean;
  toggleVisibility: () => void;
};

export const PasswordVisibilityToggle = ({
  visible,
  toggleVisibility
}: PasswordVisibilityToggleProps) => {
  return (
    <div
      className="absolute right-4 top-[34px] cursor-pointer"
      onClick={() => {
        toggleVisibility();
      }}
    >
      {visible ? (
        <RiEyeLine className="text-2xl text-text_main_light" />
      ) : (
        <RiEyeOffLine className="text-2xl text-text_main_light" />
      )}
    </div>
  );
};
