import React from 'react';
import { RiCloseLine } from 'react-icons/ri';

type Props = {
  title: string;
  onClose?: () => void;
};

export const TitleSection = ({ title, onClose }: Props) => {
  return (
    <div className="py-2 px-4 flex border-b border-t border-tab_background justify-between items-center rounded-t-lg">
      <span className="flex-1 text-base font-semibold leading-[22px]">{title}</span>
      {onClose ? (
        <button onClick={onClose}>
          <RiCloseLine />
        </button>
      ) : null}
    </div>
  );
};
