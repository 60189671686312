import React, { useEffect, useState } from 'react';
import { Modal } from './Modal';
import { Button } from '../buttons/Button';
import { Countdown } from '../countdown';
import { ACTION_ORDER, ACTION_ORDER_TYPE } from 'core/constant';
import { RiInformationLine } from 'react-icons/ri';
import { sha256 } from 'js-sha256';
import { RiEyeLine, RiEyeOffLine } from 'react-icons/ri';
import { decryptData } from 'core/utils';
import { ForgotPinButton } from '../ForgotPinButton';
import { AccountService } from 'core';
import { useAccount } from 'core/contexts/AccountContext';
import { SmartOtpGuideButton } from '../SmartOtpGuideButton';
import { TIME_VALID_OTP, TIME_RESEND_OTP, COUNTDOWN_STATUS } from 'core/constant';
import { ErrorToast, SpinnerIcon } from 'components';

type ModalProps = {
  title: string;
  subTitle?: string;
  isOpen: boolean;
  loading?: boolean;
  closeModal: () => void;
  handleGetCertInfo?: (data: any) => void;
  certInfo?: any;
  onSubmit: (pin: string, type?: 'PIN' | 'SMART_OTP' | 'SMS_OTP' | 'DIGIT_CERT') => void;
  stockInfo: {
    action: ACTION_ORDER_TYPE;
    symbol: string;
    price?: number | string;
    quanlity: number;
  };
  className?: string;
};

const myWindow = window as any;

export const OrderModal = ({
  title,
  loading,
  subTitle,
  isOpen,
  certInfo,
  onSubmit,
  closeModal,
  handleGetCertInfo,
  stockInfo,
}: ModalProps) => {
  const { userInfo } = useAccount();
  const [pinCode, setPinCode] = useState<string>();
  const [isShow, setIsShow] = useState(false);
  const [showPin, setShowPin] = useState(false);
  const [countdownOTPStatus, setCountdownOTPStatus] = useState<COUNTDOWN_STATUS>(
    COUNTDOWN_STATUS.END
  );
  const [countdownResendOTPStatus, setCountdownResendOTPStatus] = useState<COUNTDOWN_STATUS>(
    COUNTDOWN_STATUS.END
  );
  const [pin, setPin] = useState<string>();
  const [factorAuthenType, setFactorAuthenType] = useState<
    'PIN' | 'SMART_OTP' | 'SMS_OTP' | 'DIGIT_CERT'
  >();
  const pinCache = localStorage.getItem('PC') || undefined;

  const getPinCache = async () => {
    const pinData = (await decryptData(pinCache)) || '';
    setPin(pinData);
  };

  const getSettings = async () => {
    const res = await AccountService.getSettings('TwoFactorAuthentication');
    const type = res?.data?.data?.value;
    if (!type) {
      ErrorToast('Không thể lấy phương thức xác thực');
      return;
    }
    if (type === 'SMS_OTP' && userInfo) {
      getSmsOtp(userInfo?.user_id);
    }
    if (type === 'DIGIT_CERT') {
      myWindow.checkVNPTPlugin();
    }
    setFactorAuthenType(type);
    setIsShow(true);
  };

  const getSmsOtp = async (userId: string) => {
    const res = await AccountService.getSmsOtp(userId);
    const data = res?.data;
    if (data?.status === 'Fail') {
      ErrorToast(data?.error_message.split('|')[1] || data?.error_message);
    }
    if (data?.status === 'Success') {
      setCountdownOTPStatus(COUNTDOWN_STATUS.START);
    }
  };

  const handleSign = async () => {
    const response = await myWindow.GetCertInfo();
    if (response) {
      handleGetCertInfo && handleGetCertInfo(response);
    }
  };

  const reSendOtp = () => {
    if (userInfo && countdownResendOTPStatus === COUNTDOWN_STATUS.END) {
      setCountdownResendOTPStatus(COUNTDOWN_STATUS.START);
      getSmsOtp(userInfo?.user_id);
    }
  };

  useEffect(() => {
    if (isOpen) {
      getSettings();
      getPinCache();
    }
  }, [isOpen]);

  const handleCloseModal = () => {
    setIsShow(false);
    setPinCode(undefined);
    setShowPin(false);
    setCountdownResendOTPStatus(COUNTDOWN_STATUS.END);
    closeModal();
  };

  const checkFactorAuthenType = () => {
    if (
      factorAuthenType === 'SMART_OTP' ||
      factorAuthenType === 'SMS_OTP' ||
      factorAuthenType === 'PIN'
    ) {
      return true;
    }
    return false;
  };

  return (
    <>
      <Modal
        isOpen={isShow}
        title={title}
        onClose={handleCloseModal}
        className={`${!checkFactorAuthenType() ? 'max-w-[700px]' : 'max-w-[500px]'}`}
      >
        <div>
          <p className="text-sm mt-[-4px] mb-6">{subTitle}</p>
          <div className="text-[#98A2B3] text-sm rounded-md border border-[#D0D5DD] px-4 py-3">
            <p>
              Mã: <span className="font-semibold">{stockInfo.symbol}</span>
            </p>
            <p>
              Loại lệnh:{' '}
              <span
                className={`font-semibold ${
                  stockInfo.action === ACTION_ORDER.B.value
                    ? 'text-[#00C802]'
                    : stockInfo.action === ACTION_ORDER.S.value
                    ? 'text-[#F04438]'
                    : ''
                }`}
              >
                {ACTION_ORDER[stockInfo.action]?.label}
              </span>
            </p>
            <p>
              Giá đặt:{' '}
              <span className="font-semibold">
                {typeof stockInfo.price === 'number'
                  ? stockInfo.price.toLocaleString('en-US')
                  : stockInfo.price}
              </span>
            </p>
            <p>
              KL đặt:{' '}
              <span className="font-semibold">{stockInfo.quanlity.toLocaleString('en-US')}</span>
            </p>
          </div>
          {factorAuthenType === 'PIN' ? (
            <div className="flex items-center gap-2 mt-4">
              <div className="checkbox">
                <input
                  id="show-pin-checkbox"
                  type="checkbox"
                  className={'w-[20px] h-[20px] checkbox__input'}
                  onChange={e => {
                    if (e.target.checked) {
                      localStorage.setItem('IS_HIDE_PIN_POPUP', 'TRUE');
                    } else {
                      localStorage.removeItem('IS_HIDE_PIN_POPUP');
                    }
                  }}
                />
                <label htmlFor="show-pin-checkbox" className={'text-xs checkbox__label'}>
                  Không hiển thị xác nhận cho các lệnh tiếp theo
                </label>
              </div>

              <RiInformationLine className="text-[#667085] text-[18px]" />
            </div>
          ) : null}
          {checkFactorAuthenType() ? (
            <>
              <div className="flex items-center justify-between gap-2 mt-8 mb-4">
                <div className="text-sm">
                  {factorAuthenType === 'SMART_OTP'
                    ? 'Mã Smart OTP'
                    : factorAuthenType === 'SMS_OTP'
                    ? 'Mã SMS OTP'
                    : 'Mã PIN giao dịch'}
                </div>
                {factorAuthenType === 'PIN' ? (
                  <div className="checkbox">
                    <input
                      id="save-pin-checkbox"
                      type="checkbox"
                      defaultChecked={!!pin}
                      disabled={!!pin}
                      className={'w-[20px] h-[20px] checkbox__input'}
                      onChange={e => {
                        if (e.target.checked) {
                          localStorage.setItem('IS_SAVE_P', 'TRUE');
                        } else {
                          localStorage.removeItem('IS_SAVE_P');
                        }
                      }}
                    />
                    <label htmlFor="save-pin-checkbox" className={'text-sm checkbox__label'}>
                      Lưu mã PIN
                    </label>
                  </div>
                ) : factorAuthenType === 'SMS_OTP' ? (
                  <div className="text-sm">
                    Mã có hiệu lực trong{' '}
                    <span className="font-semibold inline-block min-w-[45px]">
                      <Countdown
                        status={countdownOTPStatus}
                        endTime={TIME_VALID_OTP}
                        onStart={() => setCountdownOTPStatus(COUNTDOWN_STATUS.COUNTING)}
                        onEnd={() => setCountdownOTPStatus(COUNTDOWN_STATUS.END)}
                      />
                    </span>
                  </div>
                ) : null}
              </div>
              <div className="relative">
                <input
                  value={pinCode}
                  type={showPin ? 'text' : 'password'}
                  disabled={!!pin}
                  className={`w-full !bg-[#0C111D] border border-[#D0D5DD] rounded-md text-sm pl-3 pr-10 py-2 placeholder:text-[#98A2B3]`}
                  placeholder={
                    factorAuthenType === 'SMART_OTP'
                      ? 'Nhập mã Smart OTP'
                      : factorAuthenType === 'SMS_OTP'
                      ? 'Nhập mã SMS OTP'
                      : 'Nhập mã pin'
                  }
                  defaultValue={pin ? '******' : ''}
                  maxLength={6}
                  onChange={e => {
                    const v = e.target.value;
                    setPinCode(v);
                  }}
                />
                <button
                  onClick={() => setShowPin(!showPin)}
                  disabled={!!pin}
                  type="button"
                  className="absolute right-4 top-1/2 transform -translate-y-1/2 disabled:opacity-50"
                >
                  {showPin ? <RiEyeLine /> : <RiEyeOffLine />}
                </button>
              </div>
              {factorAuthenType === 'PIN' ? (
                <div className="text-right">
                  <ForgotPinButton />
                </div>
              ) : factorAuthenType === 'SMART_OTP' ? (
                <div className="text-right">
                  <SmartOtpGuideButton />
                </div>
              ) : factorAuthenType === 'SMS_OTP' ? (
                <div className="text-xs mt-4">
                  <div className="mb-1">
                    Vui lòng nhập mã OTP vừa gửi tới {userInfo?.phone_number}
                  </div>
                  <div>
                    Bạn chưa nhận được mã?
                    <button
                      className="font-semibold text-primary ml-2 disabled:opacity-50"
                      onClick={() => reSendOtp()}
                      disabled={countdownResendOTPStatus === COUNTDOWN_STATUS.COUNTING}
                    >
                      Gửi lại mã OTP
                    </button>
                    <span className="hidden">
                      <Countdown
                        status={countdownResendOTPStatus}
                        endTime={TIME_RESEND_OTP}
                        onStart={() => setCountdownResendOTPStatus(COUNTDOWN_STATUS.COUNTING)}
                        onEnd={() => setCountdownResendOTPStatus(COUNTDOWN_STATUS.END)}
                      />
                    </span>
                  </div>
                </div>
              ) : null}
            </>
          ) : (
            <div className="mt-4">
              {certInfo && (
                <p
                  className="text-base float-right font-semibold leading-[22px] text-floorPriceColor cursor-pointer -mb-[2px] mr-4"
                  onClick={handleSign}
                >
                  Chọn lại
                </p>
              )}
              <table className="cert-table">
                <thead>
                  <th>Tên chứng thư số</th>
                  <th>Serial</th>
                  <th className="!text-right">Ngày hết hạn</th>
                </thead>
                <tbody>
                  {certInfo && (
                    <tr>
                      <td>{certInfo?.subjectCN}</td>
                      <td>{certInfo?.serial}</td>
                      <td className="text-right">{certInfo?.notAfter}</td>
                    </tr>
                  )}
                </tbody>
              </table>
              {!certInfo && (
                <tr className="pt-5 flex flex-col items-center justify-center w-full">
                  <p className="text-sm leading-5 text-text_main_light">
                    Vui lòng tìm chứng thư số để xác nhận.
                  </p>
                  <Button
                    className="font-semibold mt-8 w-[200px]"
                    variant="secondary"
                    onClick={handleSign}
                  >
                    <span className="text-base leading-[22px]">Chọn chứng thư số</span>
                  </Button>
                </tr>
              )}
              {/* {loading && <SpinnerIcon type="secondary" size="small" />} */}
            </div>
          )}
          <div className="flex items-center gap-3 mt-10">
            <Button
              className="flex-1"
              variant="secondary"
              onClick={() => handleCloseModal()}
              disabled={loading}
            >
              Đóng
            </Button>
            <Button
              variant="primary"
              className="flex-1"
              disabled={loading}
              onClick={() => {
                if (factorAuthenType === 'DIGIT_CERT' && !certInfo) {
                  ErrorToast('Vui lòng chọn chứng thư số');
                  return;
                }

                if (
                  (factorAuthenType === 'SMART_OTP' || factorAuthenType === 'SMS_OTP') &&
                  pinCode
                ) {
                  onSubmit(pinCode, factorAuthenType);
                  return;
                }
                if (pin) {
                  onSubmit(pin, factorAuthenType);
                  return;
                }
                if (pinCode) {
                  onSubmit(sha256(pinCode), factorAuthenType);
                }
                if (factorAuthenType === 'DIGIT_CERT') {
                  onSubmit('', factorAuthenType);
                }
              }}
            >
              Xác nhận
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};
