import React, { useEffect, useState } from 'react';
import { AccountService } from 'core';
import { useAccount } from 'core/contexts/AccountContext';
import { StartIcon } from 'assets/icons';

export const FavoriteSymbol = ({ symbol }: { symbol: string }) => {
  const { watchlist, updateWatchlist } = useAccount();
  const [isFavorited, setIsFavorited] = useState(false);

  const update = async () => {
    let symbols = [...watchlist];

    if (isFavorited) {
      symbols.splice(symbols.indexOf(symbol), 1);
    } else {
      symbols.push(symbol);
    }
    setIsFavorited(o => !o);

    const res = await AccountService.updateWatchlist(symbols);
    const data = res?.data?.data;
    if (data) {
      updateWatchlist(data);
    }
  };

  useEffect(() => {
    if (watchlist?.length <= 0 || !symbol) {
      return;
    }
    setIsFavorited(watchlist.includes(symbol));
  }, [watchlist, symbol]);

  return (
    <button
      className={isFavorited ? 'text-primary' : 'text-[#A4A4A4]'}
      onClick={() => {
        update();
      }}
    >
      {isFavorited ? <StartIcon /> : <StartIcon />}
    </button>
  );
};
