import React, { useEffect } from 'react';
import { Spinner } from 'components/spinner/Spinner';
import { useAuth, hasAuthParams } from 'react-oidc-context';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAccount } from 'core/contexts/AccountContext';

const CallBackPage = () => {
  const auth = useAuth();
  const navigator = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (auth.isLoading) {
      return;
    }

    if (auth.isAuthenticated) {
      onCallBack();
    } else {
      auth.signinRedirect();
    }
  }, [
    auth.isAuthenticated,
    auth.activeNavigator,
    auth.isLoading,
    auth.signinRedirect
  ]);

  const onCallBack = async () => {
    navigator(auth.user?.state || '/', { replace: true });
  };

  return (
    <>
      <div className="flex">
        <Spinner />
      </div>
    </>
  );
};

export default CallBackPage;
