import React from 'react';

export const NoNewsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="146"
      height="146"
      viewBox="0 0 146 146"
      fill="none"
    >
      <path
        d="M132.86 34.6165H122.421V26.0755C122.417 23.5443 121.41 21.1179 119.62 19.328C117.83 17.5382 115.404 16.531 112.873 16.5271H102.477V12.8479C102.477 10.5246 101.554 8.29645 99.9117 6.65364C98.2688 5.01082 96.0407 4.08789 93.7174 4.08789H12.4392C10.1159 4.08789 7.88776 5.01082 6.24494 6.65364C4.60213 8.29645 3.6792 10.5246 3.6792 12.8479V133.152C3.6792 135.475 4.60213 137.703 6.24494 139.346C7.88776 140.989 10.1159 141.912 12.4392 141.912H120.275C126.122 141.904 131.728 139.58 135.866 135.448C140.003 131.316 142.335 125.713 142.35 119.866V44.1649C142.35 41.6426 141.352 39.2227 139.574 37.4337C137.796 35.6447 135.382 34.6319 132.86 34.6165ZM12.4392 137.649C11.2466 137.649 10.1028 137.175 9.25948 136.332C8.41617 135.488 7.9424 134.345 7.9424 133.152V12.8479C7.9424 11.6553 8.41617 10.5115 9.25948 9.66817C10.1028 8.82486 11.2466 8.35109 12.4392 8.35109H93.6882C94.8808 8.35109 96.0246 8.82486 96.8679 9.66817C97.7112 10.5115 98.185 11.6553 98.185 12.8479V119.866C98.1882 123.33 99.0066 126.745 100.574 129.834C102.142 132.923 104.414 135.6 107.208 137.649H12.4392ZM137.999 119.866C137.867 124.496 135.934 128.892 132.612 132.12C129.29 135.348 124.841 137.154 120.209 137.154C115.577 137.154 111.128 135.348 107.806 132.12C104.484 128.892 102.552 124.496 102.419 119.866V20.8195H112.814C113.504 20.8195 114.188 20.9554 114.826 21.2196C115.463 21.4837 116.043 21.8709 116.531 22.3589C117.019 22.847 117.406 23.4264 117.67 24.0641C117.934 24.7018 118.07 25.3853 118.07 26.0755V93.4399H122.363V38.9089H132.86C134.254 38.9089 135.591 39.4627 136.577 40.4483C137.562 41.434 138.116 42.7709 138.116 44.1649L137.999 119.866Z"
        fill="#667085"
      />
      <path
        d="M52.5598 49.4062H15.0962V86.9428H52.5598V49.4062ZM48.3404 82.6505H19.3886V53.6986H48.3404V82.6505Z"
        fill="#667085"
      />
      <path d="M15.666 23.9438H88.009V28.2362H15.666V23.9438Z" fill="#667085" />
      <path d="M15.666 35.8281H88.009V40.1205H15.666V35.8281Z" fill="#667085" />
      <path
        d="M58.4146 47.6982H88.0087V51.9906H58.4146V47.6982Z"
        fill="#667085"
      />
      <path
        d="M58.4146 59.5679H88.0087V63.8603H58.4146V59.5679Z"
        fill="#667085"
      />
      <path
        d="M58.4146 71.4521H88.0087V75.7446H58.4146V71.4521Z"
        fill="#667085"
      />
      <path
        d="M58.4146 83.3223H88.0087V87.6147H58.4146V83.3223Z"
        fill="#667085"
      />
      <path
        d="M17.2427 95.1919H87.9943V99.4843H17.2427V95.1919Z"
        fill="#667085"
      />
      <path
        d="M17.2427 107.076H87.9943V111.369H17.2427V107.076Z"
        fill="#667085"
      />
      <path
        d="M17.2427 118.946H87.9943V123.239H17.2427V118.946Z"
        fill="#667085"
      />
    </svg>
  );
};