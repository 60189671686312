import React, { Fragment, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { RiArrowDownSFill } from 'react-icons/ri';
import TransactionTypeItem from 'components/TransactionTypeItem';
import { useAccount } from 'core/contexts';
import { getAccountName } from 'core/utils';

type AccountSelectProps = {
  buttonContainerClass: string;
  itemContainerClass: string;
  handleAccountSelectChange: (account: any) => void;
  accountSelected: any;
  isDisablePage?: boolean;
};

const AccountSelect = ({
  buttonContainerClass,
  itemContainerClass,
  handleAccountSelectChange,
  accountSelected,
  isDisablePage
}: AccountSelectProps) => {
  const { accountInfo } = useAccount();

  const onSelectItem = (stockCode: string) => {
    if (stockCode === 'M') {
      handleAccountSelectChange(
        accountInfo?.accounts?.find((item: any) => item.account_type_id === 'M')
      );
    } else {
      handleAccountSelectChange(
        accountInfo?.accounts?.find((item: any) => item.account_type_id === 'C')
      );
    }
  };

  return (
    <>
      <Menu as="div" className="relative inline-block w-auto">
        <Menu.Button className={'w-full'} disabled={isDisablePage || false}>
          <div
            className={`form-input__input form-input__input-editable h-[44px] flex justify-between items-center ${buttonContainerClass}`}
          >
            <p className="text-sm font-normal leading-5 ">
              {accountSelected?.account_type_id
                ? accountSelected?.account_type_id === 'M'
                  ? 'Tài khoản ký quỹ'
                  : 'Tài khoản thường'
                : '--'}
            </p>
            <RiArrowDownSFill size={24} color="#FFFFFF" />
          </div>
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <div
            className={`absolute mt-[2px] z-10 left-0 bg-black p-2 rounded-md text-text_main_dark border border-solid border-gray ${itemContainerClass}`}
          >
            {accountInfo?.accounts?.map((account: any) => (
              <TransactionTypeItem
                stockCode={account?.account_type_id}
                stockName={getAccountName(account?.account_type_id)}
                description={account?.account_id}
                isSelected={
                  account?.account_type_id === accountSelected?.account_type_id
                }
                onSelectItem={onSelectItem}
                key={account?.account_type_id}
              />
            ))}
          </div>
        </Transition>
      </Menu>
    </>
  );
};

export default AccountSelect;
