import React from 'react';

export const StartIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M7.99991 11.3334L4.08125 13.7267L5.14658 9.26008L1.65991 6.27341L6.23658 5.90675L7.99991 1.66675L9.76325 5.90675L14.3406 6.27341L10.8532 9.26008L11.9186 13.7267L7.99991 11.3334Z"
        fill="currentColor"
      />
    </svg>
  );
};
