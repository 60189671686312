import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useAuth, hasAuthParams } from 'react-oidc-context';
import { LoadingBarProcess, MasterLayout, SpinnerIcon } from 'components';
import CallBackPage from 'pages/callBack/CallBackPage';
import { PageNotFound } from 'pages/error/PageNotFound';
import { Page505 } from 'pages/error/Page505';
import { Maintain } from 'pages/error/maintain';
import { AccountService } from 'core/services/account.service';
import { ACCOUNT_ID_TYPE } from 'core/constant';
import { useAccount } from 'core/contexts/AccountContext';
import { useLoading } from 'core/contexts';
import { LOGIN_KEY } from 'core/constant/storage.constant';
import LogoutPage from 'pages/logout/LogoutPage';
import { AccountRoutes } from './AccountRoutes';
import { OrderHistoryRoutes } from './OrderHistoryRoutes';
import { CashHistoryRoutes } from './CashHistoryRoutes';
import DepositRoutes from './DepositRoutes';
import { ProfitRoutes } from './ProfitRoutes';
import { MarginRoutes } from './MarginRoutes';
import { CashRoutes } from './CashRoutes';
import TransferCashRoutes from './TransferCashRoutes';
import { RegisterRoutes } from './RegisterRoutes';
import CashAdvanceRoutes from './CashAdvanceRoutes';
import WithdrawRoutes from './WithdrawRoutes';
import TwoFactorAuthenticationPage from 'pages/settings/TwoFactorAuthentication';
import AssetsPage from 'pages/assets';
import StockOrderPage from 'pages/stock-order';
import MarketPage from 'pages/market/MarketPage';
import { toast } from 'react-toastify';
import { RiErrorWarningLine } from 'react-icons/ri';

const myWindow = window as any;

export const RequireAuth: React.FC<{ children: JSX.Element }> = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const auth = useAuth();
  const { account, accountInfo, updateAccountInfo, updateAccount } = useAccount();

  const init = async () => {
    // const maintainFlag = (await FirestoreService.getAppMaintainFlag()) === 'true';

    // if (maintainFlag) {
    //   navigate('/maintain');
    //   return;
    // }

    if (auth.isAuthenticated) {
      await handleUserInfoStatus();
    }
  };

  useEffect(() => {
    if (!auth.isAuthenticated) {
      return;
    }

    init();
  }, [auth.isAuthenticated]);

  useEffect(() => {
    // the `return` is important - addAccessTokenExpiring() returns a cleanup function
    return auth.events.addAccessTokenExpiring(() => {
      auth.signinRedirect();
    });
  }, [auth.events, auth.signinSilent]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (auth.activeNavigator === 'signinRedirect') {
      localStorage.setItem(LOGIN_KEY, 'signin');
    }
    if (!hasAuthParams() && !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading) {
      auth.signinRedirect();

      return;
    }
  }, [auth.isAuthenticated, auth.activeNavigator, auth.isLoading, auth.signinRedirect]);

  const handleUserInfoStatus = async () => {
    if (account && accountInfo) {
      return;
    }
    const accountRes = await AccountService.getAccountInfo();
    const accountData = accountRes?.data?.data;
    if (accountData) {
      const initAccount =
        accountData?.accounts?.find((i: any) => i.account_type_id === ACCOUNT_ID_TYPE.NORMAL) ||
        accountData?.accounts?.[0];

      updateAccountInfo(accountData);
      updateAccount(initAccount);
      if (accountData?.pin && accountData?.sign_contract) {
        return;
      }
    }
  };

  return children;
};

const PageRoutes = () => {
  const { isLoading } = useLoading();

  // useEffect(() => {
  //   ConfigService.getConfigAllType();
  // }, []);

  return (
    <>
      <Routes>
        <Route path="/callback" element={<CallBackPage />}></Route>
        <Route path="/logout" element={<LogoutPage />}></Route>
        <Route path="/" element={<MasterLayout />}>
          <Route
            index
            element={
              <React.Suspense fallback={<LoadingBarProcess />}>
                <RequireAuth>
                  <AssetsPage />
                </RequireAuth>
              </React.Suspense>
            }
          />
          <Route path="505" element={<Page505 />} />
          <Route path="404" element={<PageNotFound />} />
          <Route
            path="two-factor-authentication"
            element={
              <React.Suspense fallback={<LoadingBarProcess />}>
                <RequireAuth>
                  <TwoFactorAuthenticationPage />
                </RequireAuth>
              </React.Suspense>
            }
          />
          <Route
            path="stock-order"
            element={
              <React.Suspense fallback={<LoadingBarProcess />}>
                <RequireAuth>
                  <StockOrderPage />
                </RequireAuth>
              </React.Suspense>
            }
          />
          <Route
            path="market"
            element={
              <React.Suspense fallback={<LoadingBarProcess />}>
                <RequireAuth>
                  <MarketPage />
                </RequireAuth>
              </React.Suspense>
            }
          />
        </Route>
        <Route>
          <Route path="/maintain" element={<Maintain />} />
        </Route>
        {/* <Route path="/order-book" element={<OrderBookRoutes />}></Route> */}
        <Route path="/cash" element={<CashRoutes />}></Route>
        <Route path="/profit" element={<ProfitRoutes />}></Route>
        <Route path="/margin" element={<MarginRoutes />}></Route>
        <Route path="/account/*" element={<AccountRoutes />}></Route>
        <Route path="/order-history/*" element={<OrderHistoryRoutes />}></Route>
        <Route path="/cash-history/*" element={<CashHistoryRoutes />}></Route>
        <Route path="/deposit/*" element={<DepositRoutes />}></Route>
        <Route path="/withdraw/*" element={<WithdrawRoutes />}></Route>
        <Route path="/transfer-cash/*" element={<TransferCashRoutes />}></Route>
        <Route path="/register" element={<RegisterRoutes />}></Route>
        <Route path="/advance-cash/*" element={<CashAdvanceRoutes />}></Route>
      </Routes>
      {isLoading && (
        <div className="fixed top-0 left-0 w-full h-full bg-white/40 z-[101] flex items-center justify-center">
          <SpinnerIcon className="scale-125" />
        </div>
      )}
    </>
  );
};

export default PageRoutes;
