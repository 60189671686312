import React, { useEffect, useState, Fragment, useCallback } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { RiArrowDownSFill } from 'react-icons/ri';
import { Radio } from 'antd';
import { useAccount } from 'core/contexts/AccountContext';
import StockTableMarket from 'components/StockTableMarket';
import { STOCK_MARKET_PERIOD_LIST, STOCK_MARKET_TOP_LIST } from 'core/constant';
import StockItem from 'components/StockItem';
import { useSearchParams } from 'react-router-dom';
import { MarketStockService } from 'core';
import useComponentVisible from 'core/hooks/UseComponentVisible';
import { TitleSection, SpinnerIcon } from 'components';
import { useAuth } from 'react-oidc-context';

const FillterItemStyle =
  'px-2 py-1 flex gap-2 items-center justify-between rounded border border-solid border-[#1D2939] cursor-pointer min-w-[88px] max-h-7';

const MarketFilter = [
  {
    id: 2,
    value: 'Favorite',
    label: 'Theo dõi',
  },
  {
    id: 3,
    value: 'VN30',
    label: 'VN30',
  },
  {
    id: 4,
    value: 'HNX30',
    label: 'HNX30',
  },
  {
    id: 5,
    value: 'HOSE',
    label: 'HOSE',
  },
  {
    id: 6,
    value: 'HNX',
    label: 'HNX',
  },
  {
    id: 7,
    value: 'UPCOM',
    label: 'UPCOM',
  },
  {
    id: 8,
    value: 'ETF',
    label: 'ETF',
  },
];

const StockList = () => {
  const {
    ref: refFilterType,
    isComponentVisible: isShowFilterType,
    setIsComponentVisible: setIsShowStockType,
  } = useComponentVisible(false);
  const {
    ref: refFilterDate,
    isComponentVisible: isShowFilterDate,
    setIsComponentVisible: setIsShowFilterDate,
  } = useComponentVisible(false);

  const auth = useAuth();
  const isLoggedIn = auth.isAuthenticated;
  const { updateWatchlist } = useAccount();

  const [searchParams, setSearchParams] = useSearchParams();
  const [market, setMarket] = useState<any>(MarketFilter[0]);
  const [period, setPeriod] = useState<any>(STOCK_MARKET_PERIOD_LIST[0]);
  const [marketMovementData, setMarketMovementData] = useState<any>([]);
  const [marketMovement, setMarketMovement] = useState<any>(STOCK_MARKET_TOP_LIST[1]);

  const handChangeStockType = (e: any) => {
    setMarketMovement(e.target.value);
    setIsShowStockType(false);
  };

  const handChangeDateFilter = (e: any) => {
    setPeriod(e.target.value);
  };

  const fetchMarketStock = useCallback(async () => {
    if (!marketMovement) {
      setMarketMovementData([]);
      return;
    }
    const res = await MarketStockService.getMarketStock(
      marketMovement.value,
      period?.value,
      30,
      market?.value
    );
    const data = res?.data?.data;
    setMarketMovementData(data);
  }, [marketMovement, market, period]);

  useEffect(() => {
    if (isLoggedIn) {
      updateWatchlist();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn) {
      fetchMarketStock();
    }
  }, [marketMovement, market, period, isLoggedIn]);

  return (
    <div className="flex flex-col gap-4 rounded-lg border-style !border-t-0 bg-black">
      <TitleSection title="Danh sách cổ phiếu" />
      <div className="px-4 py-1 flex gap-x-2">
        <Menu ref={refFilterType} as="div" className="relative inline-block z-10">
          <Menu.Button>
            <div
              className={FillterItemStyle}
              onClick={e => {
                setIsShowStockType(!isShowFilterType);
              }}
            >
              <p className="text-sm font-semibold leading-5">{marketMovement?.label}</p>
              <RiArrowDownSFill className="text-sm" />
            </div>
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
            show={isShowFilterType}
          >
            <div className="absolute top-[33px] left-0 w-[230px] bg-black p-2 rounded-md text-text_main_dark border border-solid border-tab_background">
              <Radio.Group
                value={marketMovement}
                onChange={handChangeStockType}
                className="flex flex-col z-50"
              >
                {STOCK_MARKET_TOP_LIST.map(item => {
                  return (
                    <Radio
                      key={item.value}
                      value={item}
                      className="radio-button-style !text-xs font-normal !leading-[18px] tracking-[0.12px]"
                    >
                      {item.label}
                    </Radio>
                  );
                })}
              </Radio.Group>
            </div>
          </Transition>
        </Menu>
        <Menu ref={refFilterDate} as="div" className="relative inline-block z-10">
          <Menu.Button>
            <div
              className={FillterItemStyle}
              onClick={e => {
                e.stopPropagation();
                setIsShowFilterDate(!isShowFilterDate);
              }}
            >
              <p className="text-sm font-semibold leading-5">{period?.label}</p>
              <RiArrowDownSFill className="text-sm" />
            </div>
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
            show={isShowFilterDate}
          >
            <div
              className="absolute top-[33px] left-0 w-[230px] bg-black p-2 rounded-md text-text_main_dark border border-solid border-tab_background"
              onClick={() => {
                setIsShowFilterDate(false);
              }}
            >
              <Radio.Group
                value={period}
                onChange={handChangeDateFilter}
                className="flex flex-col z-50"
              >
                {STOCK_MARKET_PERIOD_LIST.map(item => {
                  return (
                    <Radio
                      key={item.value}
                      value={item}
                      className="radio-button-style !text-xs font-normal !leading-[18px] tracking-[0.12px]"
                    >
                      {item.label}
                    </Radio>
                  );
                })}
              </Radio.Group>
            </div>
          </Transition>
        </Menu>
      </div>
      <div className="pl-4 py-1">
        <div className="gap-x-2 flex">
          {MarketFilter.map(i => (
            <StockItem
              key={i.value}
              isActived={market.value === i.value}
              handleClickStock={() => {
                setMarket(i);
              }}
            >
              {i.label}
            </StockItem>
          ))}
        </div>
      </div>
      <div className="h-[630px]">
        <StockTableMarket
          data={marketMovementData?.items}
          handleClickStock={(stockCode: string) => {
            searchParams.append('stock', stockCode);
            setSearchParams(searchParams);
          }}
        />
      </div>
    </div>
  );
};

export default StockList;
