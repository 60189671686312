import React from 'react';
import { useMarket } from 'core/contexts';

const StockOptionSelect = ({
  symbol,
  onSelectItem
}: {
  symbol?: string;
  onSelectItem: (symbol: string) => void;
}) => {
  const { getMarketName } = useMarket();
  const listStocksCache = localStorage.getItem('STOCKS') || '[]';
  const listStocks = JSON.parse(listStocksCache);

  const data = symbol
    ? listStocks
        .filter((i: any) => {
          const s = symbol.toLowerCase();
          return i?.symbol?.toLowerCase()?.indexOf(s) != -1;
        })
        ?.sort((a: any, b: any) => a.symbol.length - b.symbol.length)
    : listStocks;

  return (
    <div className="border-style p-4 rounded-md max-h-[550px] overflow-auto scrollbar-custom absolute bg-black top-[42px] w-full z-30">
      {data.map((item: any) => (
        <div
          key={item?.id}
          className="border-bottom-style py-2 pr-2 cursor-pointer"
          onClick={() => {
            onSelectItem(item?.symbol);
          }}
        >
          <div className="flex gap-2 items-center">
            <div className="text-sm font-semibold leading-5">
              {item?.symbol}
            </div>
            <div className="text-xs font-bold leading-[18px] tracking-[-0.48px] text-cadetGrey px-[2px] bg-[#101828]">
              {getMarketName(item?.market_id)}
            </div>
          </div>
          <div className="mt-2 text-xs font-normal leading-[18px] text-cadetGrey">
            {item?.company_name}
          </div>
        </div>
      ))}
    </div>
  );
};

export default StockOptionSelect;
