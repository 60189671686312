import { SelectIcon } from 'assets/icons';
import React from 'react';

type TransactionTypeItemProps = {
  stockCode: string;
  stockName: string;
  description: string;
  onSelectItem?: (stockCode: string) => void;
  isSelected: boolean;
};

const TransactionTypeItem = ({
  stockCode,
  stockName,
  description,
  onSelectItem,
  isSelected
}: TransactionTypeItemProps) => {
  const handleSelectItem = (stockCode: string) => {
    onSelectItem && onSelectItem(stockCode);
  };

  return (
    <div
      className={`p-2 flex gap-2 relative text-gray rounded-md cursor-pointer ${
        isSelected ? 'isSelectStyle' : ''
      }`}
      onClick={() => handleSelectItem(stockCode)}
    >
      <div className="min-w-[40px] h-10 rounded shadow-Shadow/xs flex justify-center items-center bg-gray command-code">
        <p className="text-yankeesBlue text-sm font-semibold leading-4 text-center">
          {stockCode}
        </p>
      </div>
      <div className="flex flex-col gap-1 ">
        <p className="text-sm font-semibold leading-5">{stockName}</p>
        <p className="text-xs font-normal leading-[18px]">{description}</p>
      </div>
      {isSelected && (
        <div className="absolute right-2 top-2">
          <SelectIcon />
        </div>
      )}
    </div>
  );
};

export default TransactionTypeItem;
