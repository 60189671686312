import React from 'react';

export const NoDataIcon = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="146"
        height="146"
        viewBox="0 0 146 146"
        fill="none"
      >
        <g clipPath="url(#clip0_2131_24670)">
          <path
            d="M101.202 18.2257C96.5797 12.8359 89.8336 9.73395 82.7331 9.7334C81.3892 9.7334 80.2998 10.8228 80.2998 12.1667C80.2998 13.5106 81.3892 14.6001 82.7331 14.6001C88.415 14.6068 93.8103 17.0956 97.5035 21.4134C97.9658 21.9539 98.6415 22.2651 99.3528 22.2651C99.9359 22.2584 100.497 22.0425 100.934 21.6567C101.425 21.2373 101.729 20.6401 101.779 19.9967C101.829 19.3532 101.622 18.7162 101.202 18.2257Z"
            fill="#667085"
          />
          <path
            d="M116.8 34.0669C116.766 17.6212 104.989 3.54731 88.8066 0.615404C72.6244 -2.3165 56.6578 6.7307 50.8565 22.1192C43.1054 22.9615 36.2371 27.492 32.4118 34.2859C31.3462 34.1523 30.2738 34.0792 29.1998 34.0669C13.9737 34.0813 1.31436 45.7932 0.117851 60.9723C-1.07866 76.1513 9.58931 89.702 24.6252 92.1019C24.429 93.8388 24.3315 95.5855 24.3332 97.3335C24.3332 124.211 46.122 146 72.9998 146C99.8777 146 121.666 124.211 121.666 97.3335C121.668 95.5855 121.571 93.8388 121.375 92.1019C136.41 89.702 147.078 76.1513 145.882 60.9723C144.685 45.7932 132.026 34.0813 116.8 34.0669ZM72.9998 141.134C48.8098 141.134 29.1998 121.524 29.1998 97.3335C29.1998 73.1435 48.8098 53.5335 72.9998 53.5335C97.1899 53.5335 116.8 73.1435 116.8 97.3335C116.8 121.524 97.1899 141.134 72.9998 141.134ZM120.62 87.2839C115.875 64.7691 96.0093 48.6545 72.9998 48.6545C49.9904 48.6545 30.1251 64.7691 25.3795 87.2839C12.8828 85.2495 4.03431 73.9719 5.03161 61.35C6.02891 48.7281 16.5387 38.9801 29.1998 38.9335C34.001 38.9181 38.6966 40.3421 42.6805 43.0215C43.4108 43.5084 44.3452 43.5686 45.132 43.1796C45.9187 42.7906 46.4382 42.0115 46.4947 41.1356C46.5511 40.2598 46.1361 39.4204 45.4058 38.9335C42.9669 37.2953 40.2917 36.0398 37.4732 35.2105C40.9636 30.1213 46.6603 26.9886 52.8275 26.7669H53.5332C57.748 26.7491 61.8519 28.117 65.2132 30.6602C66.2883 31.4665 67.8135 31.2486 68.6198 30.1735C69.4262 29.0984 69.2083 27.5732 68.1332 26.7669C64.6369 24.1338 60.4899 22.5019 56.1368 22.0462C61.8103 9.52703 75.4464 2.65937 88.8829 5.5541C102.319 8.44882 111.918 20.3221 111.933 34.0669V34.4805C107.879 35.1374 104.012 36.6559 100.594 38.9335C99.4784 39.6861 99.1842 41.2004 99.9368 42.3159C100.689 43.4313 102.204 43.7254 103.319 42.9729C107.309 40.3106 112.004 38.9037 116.8 38.9335C129.461 38.9801 139.971 48.7281 140.968 61.35C141.965 73.9719 133.117 85.2495 120.62 87.2839Z"
            fill="#667085"
          />
          <path
            d="M99.7667 77.867C99.765 75.9316 98.9947 74.076 97.6253 72.7083C94.7335 69.9644 90.1998 69.9644 87.308 72.7083L73 87.0163L58.692 72.7083C55.8002 69.9644 51.2665 69.9644 48.3747 72.7083C45.5281 75.5584 45.5281 80.1756 48.3747 83.0256L62.6827 97.3336L48.3747 111.642C45.5281 114.492 45.5281 119.109 48.3747 121.959C51.2665 124.703 55.8002 124.703 58.692 121.959L73 107.651L87.308 121.959C89.1278 123.912 91.8686 124.716 94.4551 124.055C97.0415 123.395 99.0612 121.375 99.7217 118.789C100.382 116.202 99.5783 113.462 97.6253 111.642L83.3173 97.3336L97.6253 83.0256C98.9947 81.6579 99.765 79.8024 99.7667 77.867ZM94.1943 79.5946L78.1587 95.606C77.698 96.0629 77.4389 96.6848 77.4389 97.3336C77.4389 97.9825 77.698 98.6044 78.1587 99.0613L94.1943 115.073C94.655 115.53 94.9141 116.152 94.9141 116.8C94.9141 117.449 94.655 118.071 94.1943 118.528C93.7374 118.989 93.1155 119.248 92.4667 119.248C91.8178 119.248 91.1959 118.989 90.739 118.528L74.7277 102.492C74.2708 102.032 73.6488 101.773 73 101.773C72.3512 101.773 71.7292 102.032 71.2723 102.492L55.261 118.528C54.8041 118.989 54.1821 119.248 53.5333 119.248C52.8845 119.248 52.2626 118.989 51.8057 118.528C51.345 118.071 51.0859 117.449 51.0859 116.8C51.0859 116.152 51.345 115.53 51.8057 115.073L67.8413 99.0613C68.302 98.6044 68.5611 97.9825 68.5611 97.3336C68.5611 96.6848 68.302 96.0629 67.8413 95.606L51.8057 79.5946C51.345 79.1377 51.0859 78.5158 51.0859 77.867C51.0859 77.2182 51.345 76.5962 51.8057 76.1393C52.2626 75.6787 52.8845 75.4195 53.5333 75.4195C54.1821 75.4195 54.8041 75.6787 55.261 76.1393L71.2723 92.175C71.7292 92.6356 72.3512 92.8948 73 92.8948C73.6488 92.8948 74.2708 92.6356 74.7277 92.175L90.739 76.1393C91.1959 75.6787 91.8178 75.4195 92.4667 75.4195C93.1155 75.4195 93.7374 75.6787 94.1943 76.1393C94.655 76.5962 94.9141 77.2182 94.9141 77.867C94.9141 78.5158 94.655 79.1377 94.1943 79.5946Z"
            fill="#667085"
          />
        </g>
        <defs>
          <clipPath id="clip0_2131_24670">
            <rect width="146" height="146" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};