import { MARKET, BIZ, ARTICLE } from '../constant/api.constant';
import axiosInstance, {
  axiosInstanceNews,
  axiosInstanceV3
} from './restful.service';
import { STOCK_MARKET_TOP, STOCK_MARKET_PERIOD } from 'core/constant';
import { UpdateOrderParamsType } from 'core/types';
import { getCurrentBrowserFingerPrint } from '@rajesh896/broprint.js';

const getMarketStock = (
  type: STOCK_MARKET_TOP,
  period?: STOCK_MARKET_PERIOD,
  page_size?: number,
  stock_filter?: string
) => {
  const params = {
    type,
    period,
    page_size,
    stock_filter
  };
  return axiosInstance.get(MARKET.STOCK, { params });
};

const getStockInfo = (symbol: string) => {
  return axiosInstance.get(BIZ.STOCK_INFO(symbol));
};

const getStockBuyingPower = (params: any) => {
  return axiosInstance.get(BIZ.STOCK_BUYING_POWER, { params });
};

const getOrderBook = (params: any) => {
  return axiosInstance.get(BIZ.ORDER_BOOK, { params });
};

const getStockHolding = (params: any) => {
  return axiosInstance.get(BIZ.STOCK_HOLDING, { params });
};

const buyStock = (userId: string, params: any) => {
  axiosInstanceV3.interceptors.request.use(async config => {
    const fingerprint = await getCurrentBrowserFingerPrint();
    config.headers['X-DEVICE-ID'] = fingerprint + '_' + userId;
    return config;
  });
  return axiosInstanceV3.post(BIZ.BUY_STOCK, params);
};

const sellStock = (userId: string, params: any) => {
  axiosInstanceV3.interceptors.request.use(async config => {
    const fingerprint = await getCurrentBrowserFingerPrint();
    config.headers['X-DEVICE-ID'] = fingerprint + '_' + userId;
    return config;
  });
  return axiosInstanceV3.post(BIZ.SELL_STOCK, params);
};

const updateOrder = (userId: string, params: UpdateOrderParamsType) => {
  axiosInstanceV3.interceptors.request.use(async config => {
    const fingerprint = await getCurrentBrowserFingerPrint();
    config.headers['X-DEVICE-ID'] = fingerprint + '_' + userId;
    return config;
  });
  return axiosInstanceV3.post(BIZ.UPDATE_STOCK, params);
};

const cancelOrder = (userId: string, params: any) => {
  axiosInstanceV3.interceptors.request.use(async config => {
    const fingerprint = await getCurrentBrowserFingerPrint();
    config.headers['X-DEVICE-ID'] = fingerprint + '_' + userId;
    return config;
  });
  return axiosInstanceV3.post(BIZ.CANCEL_ORDER, params);
};

const getCashOrder = (accountNo: string) => {
  const params = {
    accountNo: accountNo
  };
  return axiosInstance.get(BIZ.CASH_PORTFOLIO, { params });
};

const getCashTransactionHistory = (params: any) => {
  return axiosInstance.get(BIZ.CASH_TRANSACTION_HISTORY, { params });
};

const getProfileStock = (params: any) => {
  return axiosInstance.get(MARKET.PROFILE_STOCK, { params });
};

const getStockNews = (params: any) => {
  return axiosInstanceNews.get(ARTICLE.SEARCH, { params });
};

const getStockPortfolio = (params: any) => {
  return axiosInstance.get(BIZ.STOCK_PORTFOLIO, { params });
};

const getStockAnalysis = (params: any) => {
  return axiosInstance.get(MARKET.ANALYSIS, { params });
};

export const MarketStockService = {
  getMarketStock,
  getStockInfo,
  getStockBuyingPower,
  getOrderBook,
  getStockHolding,
  sellStock,
  buyStock,
  cancelOrder,
  updateOrder,
  getCashOrder,
  getCashTransactionHistory,
  getProfileStock,
  getStockNews,
  getStockPortfolio,
  getStockAnalysis
};
