import React from 'react';

type TabItemProps = {
  title: string;
  isActive?: boolean;
  handleClick?: () => void;
};

export const TabItem = ({
  title,
  isActive = false,
  handleClick
}: TabItemProps) => {
  return (
    <div
      className={`py-1 px-2 rounded cursor-pointer text-sm font-semibold leading-5 ${
        isActive
          ? 'text-white bg-primary'
          : 'text-text_main_light bg-tab_background'
      }`}
      onClick={handleClick}
    >
      {title}
    </div>
  );
};
