export const BASIC_ORDERS = [
  {
    code: 'MP_LO',
    nameCode: 'Lệnh thường',
    name: 'Lệnh thường',
    description: 'MTA_ORDER_DESCRIPTION_NORMAL',
    tooltip: 'MTA_ORDER_TOOLTIP_NORMAL'
  }
];

export const CONDITION_ORDERS = [
  {
    code: 'GTD',
    nameCode: 'GTD',
    name: 'GTD (Lệnh điều kiện thời gian)',
    description: 'MTA_ORDER_DESCRIPTION_GTD',
    tooltip: 'MTA_ORDER_TOOLTIP_GTD'
  },
  {
    code: 'STP',
    nameCode: 'Stop',
    name: 'Stop (Lệnh thị trường dừng)',
    description: 'MTA_ORDER_DESCRIPTION_STOP',
    tooltip: 'MTA_ORDER_TOOLTIP_STOP'
  },
  {
    code: 'STL',
    nameCode: 'Stop Limit',
    name: 'Stop Limit (Lệnh giới hạn dừng)',
    description: 'MTA_ORDER_DESCRIPTION_STOP_LIMIT',
    tooltip: 'MTA_ORDER_TOOLTIP_STOP_LIMIT'
  },
  {
    code: 'TS',
    nameCode: 'Trailing Stop',
    name: 'Trailing Stop (Lệnh thị trường xu hướng)',
    description: 'MTA_ORDER_DESCRIPTION_TRAILING_STOP',
    tooltip: 'MTA_ORDER_TOOLTIP_TRAILING_STOP'
  },
  {
    code: 'TSL',
    nameCode: 'Trailing Stop Limit',
    name: 'Trailing Stop Limit (Lệnh giới hạn xu hướng)',
    description: 'MTA_ORDER_DESCRIPTION_TRAILING_STOP_LIMIT',
    tooltip: 'MTA_ORDER_TOOLTIP_TRAILING_STOP_LIMIT'
  },
  {
    code: 'OCO',
    nameCode: 'OCO',
    name: 'One Cancels Other',
    description: 'MTA_ORDER_DESCRIPTION_ONE_CANCELS_OTHER',
    tooltip: 'MTA_ORDER_TOOLTIP_ONE_CANCELS_OTHER'
  },
  {
    code: 'SL_TP',
    nameCode: 'Stop Loss/Take Profit',
    name: 'Stop Loss/Take Profit (Chốt lời/Cắt lỗ)',
    description: 'MTA_ORDER_DESCRIPTION_STOPLOSS_TAKEPROFIT',
    tooltip: 'MTA_ORDER_TOOLTIP_STOPLOSS_TAKEPROFIT'
  }
];
