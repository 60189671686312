import React, { useEffect, useState } from 'react';
import { TabItem } from './components/TabItem';
import { StockNews } from './components/StockNews';
import { StockEvents } from './components/StockEvents';
import { MarketStockService } from 'core';
import { SpinnerIcon } from 'components/spinner/SpinnerIcon';
import { ResponseType } from 'core/types';

enum DetailStockTab {
  NEWS,
  EVENTS,
}

type DetailStockNewsProps = {
  stockCode: string;
};

export const DetailStockNews = ({ stockCode }: DetailStockNewsProps) => {
  const [tab, setTab] = useState(DetailStockTab.NEWS);
  const [stockNews, setStockNews] = useState<ResponseType>({} as ResponseType);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchNews = async () => {
    setLoading(true);
    const params = {
      Tag: stockCode,
      PageIndex: 1,
      PageSize: 1000,
    };
    const res = await MarketStockService.getStockNews(params);
    if (res?.data?.status === 1) {
      setStockNews(res?.data?.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchNews();
  }, []);

  return (
    <>
      <div className="py-1 flex gap-2 text-sm font-semibold leading-5">
        <TabItem
          title="Tin cổ phiếu"
          isActive={tab === DetailStockTab.NEWS}
          handleClick={() => {
            setTab(DetailStockTab.NEWS);
          }}
        />
        <TabItem
          title="Lịch sự kiện"
          isActive={tab === DetailStockTab.EVENTS}
          handleClick={() => {
            setTab(DetailStockTab.EVENTS);
          }}
        />
      </div>
      <div className="mt-4 pb-6">
        {tab === DetailStockTab.NEWS ? (
          loading ? (
            <div className="w-full h-full flex justify-center items-center">
              <SpinnerIcon className="scale-125" />
            </div>
          ) : (
            <StockNews data={stockNews?.items} />
          )
        ) : (
          <StockEvents />
        )}
      </div>
    </>
  );
};
