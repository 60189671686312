import React from 'react';
import { AiOutlineCaretUp, AiOutlineCaretDown } from 'react-icons/ai';

const SortTable = ({ sortDirection }: { sortDirection?: 'asc' | 'desc' }) => {
  return (
    <div>
      <div
        className={`h-[6px] w-[14px] px-[2px] flex items-center justify-center overflow-hidden ${
          sortDirection === 'asc' ? 'text-primary' : ''
        }`}
      >
        <AiOutlineCaretUp fontSize={10} />
      </div>
      <div
        className={`h-[6px] w-[14px] px-[2px] flex items-center justify-center overflow-hidden ${
          sortDirection === 'desc' ? 'text-primary' : ''
        }`}
      >
        <AiOutlineCaretDown fontSize={11} />
      </div>
    </div>
  );
};

export default SortTable;
