import { useRef, useEffect } from 'react';

export const usePrevious = <T>(value: T): T => {
  const ref: any = useRef<T>();

  useEffect(() => {
    if (value) {
      ref.current = JSON.parse(JSON.stringify(value));
    }
  }, [value]);

  return ref.current;
};
