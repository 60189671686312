export enum STOCK_MARKET_ID {
  WATCH_LIST = 'TheoDoi',
  VN30 = 'vn30',
  HNX30 = 'hnx30',
  HOSE = 'HOSE',
  HNX = 'HASTC',
  UPCOM = 'UPCOM',
  ETF = 'ETF'
}

export interface MarketStatusType {
  value: 'OPEN' | 'PENDING' | 'CLOSE' | 'ATO' | 'ATC';
  label: string;
  color: string;
}
export interface MarketStatus {
  OPEN: MarketStatusType;
  PENDING: MarketStatusType;
  CLOSE: MarketStatusType;
  ATO: MarketStatusType;
  ATC: MarketStatusType;
}

export const MARKET_STATUS: MarketStatus = {
  OPEN: {
    value: 'OPEN',
    label: 'Liên tục',
    color: '#00C802'
  },
  PENDING: {
    value: 'PENDING',
    label: 'Tạm nghỉ',
    color: '#EAAA08'
  },
  CLOSE: {
    value: 'CLOSE',
    label: 'Đóng cửa',
    color: '#F04438'
  },
  ATO: {
    value: 'ATO',
    label: 'ATO',
    color: '#EAAA08'
  },
  ATC: {
    value: 'ATC',
    label: 'ATC',
    color: '#EAAA08'
  }
};

export type ACTION_ORDER_TYPE = 'B' | 'S' | 'U';

export type ACTION_OPTION = {
  label: string;
  value: ACTION_ORDER_TYPE;
};

export const ACTION_ORDER: {
  B: ACTION_OPTION;
  S: ACTION_OPTION;
  U: ACTION_OPTION;
} = {
  B: {
    value: 'B',
    label: 'Mua'
  },
  S: {
    value: 'S',
    label: 'Bán'
  },
  U: {
    value: 'U',
    label: 'Sửa lệnh'
  }
};

export enum STOCK_MARKET_TOP {
  TOP_INCREASE = 'TOP_INCREASE',
  TOP_DECREASE = 'TOP_DECREASE',
  TOP_VOLUME = 'TOP_VOLUME',
  TOP_VALUE = 'TOP_VALUE',
  REACH_PEAK = 'REACH_PEAK',
  GREATER_PEAK = 'GREATER_PEAK',
  REACH_BOTTOM = 'REACH_BOTTOM',
  LESS_THAN_BOTTOM = 'LESS_THAN_BOTTOM',
  PEAK_52_WEEKS = 'PEAK_52_WEEKS',
  BOTTOM_52_WEEK = 'BOTTOM_52_WEEK'
}

export enum STOCK_MARKET_PERIOD {
  ONE_DAY = 'ONE_DAY',
  ONE_WEEK = 'ONE_WEEK',
  ONE_MONTH = 'ONE_MONTH',
  THREE_MONTHS = 'THREE_MONTHS',
  SIX_MONTHS = 'SIX_MONTHS',
  ONE_YEAR = 'ONE_YEAR'
}

export const STOCK_MARKET_PERIOD_LIST = [
  { label: '1 Ngày', value: STOCK_MARKET_PERIOD.ONE_DAY },
  { label: '1 tuần', value: STOCK_MARKET_PERIOD.ONE_WEEK },
  { label: '1 tháng', value: STOCK_MARKET_PERIOD.ONE_MONTH },
  { label: '3 tháng', value: STOCK_MARKET_PERIOD.THREE_MONTHS },
  { label: '6 tháng', value: STOCK_MARKET_PERIOD.SIX_MONTHS },
  { label: '1 năm', value: STOCK_MARKET_PERIOD.ONE_YEAR }
];

export const STOCK_MARKET_PEAK_OPTION = [
  { label: 'Chạm đỉnh', value: STOCK_MARKET_TOP.REACH_PEAK },
  { label: 'Vượt đỉnh', value: STOCK_MARKET_TOP.GREATER_PEAK }
];

export const STOCK_MARKET_BOTTOM_OPTION = [
  { label: 'Chạm đáy', value: STOCK_MARKET_TOP.REACH_BOTTOM },
  { label: 'Thủng đáy', value: STOCK_MARKET_TOP.LESS_THAN_BOTTOM }
];

export const STOCK_MARKET_TOP_LIST = [
  {
    label: 'Tất cả',
    value: null
  },
  {
    label: 'Top tăng',
    value: STOCK_MARKET_TOP.TOP_INCREASE
  },
  {
    label: 'Top giảm',
    value: STOCK_MARKET_TOP.TOP_DECREASE
  },
  {
    label: 'Top giá trị',
    value: STOCK_MARKET_TOP.TOP_VALUE
  },
  {
    label: 'Top khối lượng',
    value: STOCK_MARKET_TOP.TOP_VOLUME
  },
  {
    label: 'Đỉnh 52T',
    value: STOCK_MARKET_TOP.PEAK_52_WEEKS
  },
  {
    label: 'Đáy 52T',
    value: STOCK_MARKET_TOP.BOTTOM_52_WEEK
  },
  {
    label: 'Không hưởng quyền',
    value: ''
  }
];

export type StockInforProps = {
  change: number;
  stock_name: string;
  price: number;
  total_val: number;
  total_vol: number;
  chart_data: any;
  per_change: number;
};
