import { useAccount } from 'core/contexts';
import React from 'react';
import AccountCashPieChart from './AccountCashPieChart';
import { AccountNotification } from 'components/AccountNotification';
import { TitleSection } from 'components/TitleSection';

const AccountsCashChartSection = ({ isReload }: { isReload: boolean }) => {
  const { accountInfo } = useAccount();

  return (
    <>
      {accountInfo && accountInfo?.accounts.length > 0 ? (
        accountInfo?.accounts
          .slice()
          .sort((a: any, b: any) => {
            if (a.account_type_id === 'C') return -1;
            if (b.account_type_id === 'C') return 1;
            return 0;
          })
          .map((account: any, index: number) => (
            <AccountCashPieChart key={index} account={account} isReload={isReload} />
          ))
      ) : (
        <div className="flex flex-col gap-2">
          <div className="bg-black rounded-lg border-style !border-t-0">
            <TitleSection title="Tiểu khoản thường" />
            <div className="h-[282px] px-6 py-4 flex justify-center items-center">
              <AccountNotification />
            </div>
          </div>
          <div className="bg-black rounded-lg border-style !border-t-0">
            <TitleSection title="Tiểu khoản ký quỹ" />
            <div className="h-[282px] px-6 py-4 flex justify-center items-center">
              <AccountNotification />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AccountsCashChartSection;
