import { Button } from 'components/buttons/Button';
import { AssetStockItem } from 'core/types';
import React from 'react';
import { useNavigate } from 'react-router-dom';

type CategoryTableProps = {
  data?: AssetStockItem[];
};

const CategoryTable = ({ data }: CategoryTableProps) => {
  const navigator = useNavigate();

  return (
    <>
      <table className="w-full order-book-table transaction-history-table">
        <thead>
          <tr className="!font-semibold">
          <th className={'font-semibold'}>
              <div className="flex flex-col items-start">
                <p>Mã</p>
                <p className="text-xs font-normal leading-[18px] tracking-[-0.6px]">
                  Lời/lỗ (%)
                </p>
              </div>
            </th>
            <th className={'font-semibold'}>
              <div className="flex flex-col items-end">
                <p>KL tổng</p>
                <p className="text-xs font-normal leading-[18px] tracking-[-0.6px]">
                  KL khả dụng
                </p>
              </div>
            </th>
            <th className={'font-semibold'}>
              <p>Giá thị trường</p>
              <p className="text-xs font-normal leading-[18px] tracking-[-0.6px]">
                Giá vốn
              </p>
            </th>
            <th className={'font-semibold'}>
              <p>Giá trị thị trường</p>
              <p className="text-xs font-normal leading-[18px] tracking-[-0.6px]">
                Lời/lỗ
              </p>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data?.length === 0 && (
            <tr>
              <td className="text-left">
                <div className="flex flex-col gap-1 font-semibold">
                  <p className="text-sm leading-5">-</p>
                  <p className={`text-xs leading-[18px]`}></p>
                </div>
              </td>
              <td className="text-right">
                <div className="flex flex-col items-end gap-1">
                  <p className="text-sm font-semibold leading-5">-</p>
                  <p className="text-xs font-normal leading-[18px] tracking-[-0.6px] text-text_main_light">-</p>
                </div>
              </td>
              <td className="text-right">
                <div className="flex flex-col gap-1">
                  <p className="text-sm font-semibold leading-5">-</p>
                  <p className="text-xs font-normal leading-[18px] tracking-[-0.6px] text-text_main_light">-</p>
                </div>
              </td>
              <td className="text-right">
                <div className="flex flex-col gap-1 text-xs leading-[18px]">
                  <p className="font-semibold">-</p>
                  <p className={`font-normal `}>-</p>
                </div>
              </td>
              <td>
              </td>
            </tr>
          )}
          {data?.map((item: AssetStockItem, index: number) => {
            return (
              <tr key={index}>
                <td className="text-left">
                  <div className="flex flex-col gap-1 font-semibold">
                    <p className="text-sm leading-5">{item?.stock_symbol}</p>
                    <p
                      className={`text-xs leading-[18px] ${
                        item?.change_percent < 0
                          ? 'text-lowPriceColor'
                          : 'text-high-price'
                      }`}
                    >
                      {item?.change_percent < 0 ? '' : '+'}
                      {(item?.change_percent).toLocaleString('en-US')}%
                    </p>
                  </div>
                </td>
                <td className="text-right">
                  <div className="flex flex-col items-end gap-1">
                    <p className="text-sm font-semibold leading-5">
                      {(item?.total_quantity).toLocaleString('en-US')}
                    </p>
                    <p className="text-xs font-normal leading-[18px] tracking-[-0.6px] text-text_main_light">
                      {(item?.available_quantity).toLocaleString('en-US')}
                    </p>
                  </div>
                </td>
                <td className="text-right">
                  <div className="flex flex-col gap-1">
                    <p className="text-sm font-semibold leading-5">
                      {(item?.market_price).toLocaleString('en-US')}
                    </p>
                    <p className="text-xs font-normal leading-[18px] tracking-[-0.6px] text-text_main_light">
                      {(item?.average_price).toLocaleString('en-US')}
                    </p>
                  </div>
                </td>
                <td className="text-right">
                  <div className="flex flex-col gap-1 text-xs leading-[18px]">
                    <p className="font-semibold">
                      {(item?.market_amount).toLocaleString('en-US')}
                    </p>
                    <p
                      className={`font-normal ${
                        item?.change_amount < 0
                          ? 'text-lowPriceColor'
                          : 'text-high-price'
                      }`}
                    >
                      {item?.change_amount < 0 ? '' : '+'}
                      {(item?.change_amount).toLocaleString('en-US')}
                    </p>
                  </div>
                </td>
                <td>
                  <div className='flex justify-end gap-2'>
                    <Button
                      variant="green"
                      className="w-[50px] h-6 text-xs font-bold leading-[18px] tracking-[-0.48px] border-none"
                      onClick={() =>
                        navigator(`/stock-order?symbol=${item?.stock_symbol}&action=B`)
                      }
                    >
                      Mua
                    </Button>
                    <Button
                      variant="red"
                      className="w-[50px] h-6 text-xs font-bold leading-[18px] tracking-[-0.48px] border-none"
                      onClick={() =>
                        navigator(`/stock-order?symbol=${item?.stock_symbol}&action=S`)
                      }
                    >
                      Bán
                    </Button>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default CategoryTable;
