import React from 'react';
import { toast } from 'react-toastify';
import { GENERAL_SERVER_ERROR } from 'core/constant';
import { RiErrorWarningLine } from 'react-icons/ri';

export const ErrorToast = (message?: string) => {
  return toast(message || GENERAL_SERVER_ERROR, {
    type: 'error',
    icon: <RiErrorWarningLine className="text-error" />,
  });
};
