import { LoadingBarProcess, MasterLayout } from 'components';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { RequireAuth } from './Routes';
import ProfitPage from 'pages/profit';

export const ProfitRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<MasterLayout />}>
        <Route
          index
          element={
            <React.Suspense fallback={<LoadingBarProcess />}>
              <RequireAuth>
                <ProfitPage />
              </RequireAuth>
            </React.Suspense>
          }
        />
      </Route>
    </Routes>
  );
};
