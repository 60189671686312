import React from 'react';
import NavBar from './NavBar';

const HeaderNav = () => {
  return (
    <header className="fixed top-0 left-0 w-full z-50">
      <NavBar />
    </header>
  );
};

export default HeaderNav;
