import React, { useEffect, useState } from 'react';
import CategoryTable from './StockDetailTable/CategoryTable';
import TransactionHistoryTable from './StockDetailTable/TransactionHistoryTable';
import { useAccount } from 'core/contexts';
import { MarketStockService } from 'core';
import { SpinnerIcon } from 'components/spinner/SpinnerIcon';
import { ACCOUNT_ID_TYPE } from 'core/constant';
import { AssetStockItem, ResponseType } from 'core/types';
import { AccountNotification } from 'components/AccountNotification';
import CommandHistoryTable from 'components/OrderHistoryTable';

type DetailStockCategoryProps = {
  stockCode: string;
};

export const DetailStockCategory = ({ stockCode }: DetailStockCategoryProps) => {
  const { accountInfo, account } = useAccount();
  const [stockPortfolio_M, setStockPortfolio_M] = useState<AssetStockItem[]>([]); // Tài khoản ký quỹ
  const [stockPortfolio_C, setStockPortfolio_C] = useState<AssetStockItem[]>([]); // Tài khoản thường
  const [orderBook, setOrderBook] = useState<ResponseType>(); // Lịch sử giao dịch
  const [isLoadingStockPortfolio_M, setIsLoadingStockPortfolio_M] = useState<boolean>(true);
  const [isLoadingStockPortfolio_C, setIsLoadingStockPortfolio_C] = useState<boolean>(true);
  const [isLoadingOrderBook, setIsLoadingOrderBook] = useState<boolean>(true);

  const fetchStockPortfolio = async () => {
    setIsLoadingStockPortfolio_M(true);
    setIsLoadingStockPortfolio_C(true);
    const accountID_M = accountInfo?.accounts?.find(
      item => item.account_type_id === ACCOUNT_ID_TYPE.MARGIN
    )?.account_id;

    const accountID_C = accountInfo?.accounts?.find(
      item => item.account_type_id === ACCOUNT_ID_TYPE.NORMAL
    )?.account_id;

    const params_M = {
      accountNo: accountID_M,
      stockSymbol: stockCode,
    };

    const params_C = {
      accountNo: accountID_C,
      stockSymbol: stockCode,
    };

    const res_m = await MarketStockService.getStockPortfolio(params_M);
    const res_c = await MarketStockService.getStockPortfolio(params_C);

    if (res_m?.data?.status === 'Success') {
      setStockPortfolio_M(res_m?.data?.data);
    }

    if (res_c?.data?.status === 'Success') {
      setStockPortfolio_C(res_c?.data?.data);
    }
    setIsLoadingStockPortfolio_M(false);
    setIsLoadingStockPortfolio_C(false);
  };

  const fetchOrderBook = async () => {
    setIsLoadingOrderBook(true);
    const searchParams = new URLSearchParams();
    searchParams.append('stockSymbol', stockCode);
    searchParams.append('orderStatuses', 'FullFill');
    searchParams.append('orderStatuses', 'PartialFill');
    searchParams.append('pageIndex', '1');
    searchParams.append('pageSize', '1000');

    const res = await MarketStockService.getOrderBook(searchParams);
    if (res?.data?.status === 'Success') {
      setOrderBook(res?.data?.data);
    }
    setIsLoadingOrderBook(false);
  };

  useEffect(() => {
    if (accountInfo && accountInfo?.accounts.length > 0) {
      fetchStockPortfolio();
      fetchOrderBook();
    } else {
      setIsLoadingStockPortfolio_M(false);
      setIsLoadingStockPortfolio_C(false);
      setIsLoadingOrderBook(false);
    }
  }, []);

  return (
    <div className="flex gap-2">
      <div className="flex flex-col gap-2 w-1/2">
        <div className="border-style bg-black rounded-lg">
          <div className="px-4 py-2 border-bottom-style">
            <p className="text-base font-semibold leading-[22px]">Tiểu khoản ký quỹ</p>
          </div>
          {isLoadingStockPortfolio_M ? (
            <div className="flex justify-center items-center">
              <SpinnerIcon />
            </div>
          ) : (
            <div className="px-4 pb-4 pt-2">
              {account ? (
                <CategoryTable data={stockPortfolio_M} />
              ) : (
                <AccountNotification className="my-[68px]" />
              )}
            </div>
          )}
        </div>
        <div className="border-style bg-black rounded-lg">
          <div className="px-4 py-2 border-bottom-style">
            <p className="text-base font-semibold leading-[22px]">Tiểu khoản thường</p>
          </div>
          {isLoadingStockPortfolio_C ? (
            <div className="flex justify-center items-center">
              <SpinnerIcon />
            </div>
          ) : (
            <div className="px-4 pb-4 pt-2">
              {account ? (
                <CategoryTable data={stockPortfolio_C} />
              ) : (
                <AccountNotification className="my-[68px]" />
              )}
            </div>
          )}
        </div>
      </div>
      <div className="w-1/2 border-style bg-black rounded-lg">
        <div className="px-4 py-2 border-bottom-style">
          <p className="text-base font-semibold leading-[22px]">Lịch sử giao dịch</p>
        </div>
        {isLoadingOrderBook ? (
          <div className="flex justify-center items-center">
            <SpinnerIcon />
          </div>
        ) : (
          <div
            className={`px-4 py-4 pt-2 ${account ? '' : 'h-full flex items-center justify-center'}`}
          >
            {account ? <CommandHistoryTable data={orderBook?.items} /> : <AccountNotification />}
          </div>
        )}
      </div>
    </div>
  );
};
