import React from 'react';

export const ArrowDown = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M16.0065 9.23737C16.0556 9.28451 16.0834 9.34965 16.0834 9.41773C16.0834 9.48581 16.0556 9.55095 16.0065 9.59809L9.75649 15.5971C9.65976 15.69 9.50699 15.69 9.41026 15.5971L3.16026 9.59809C3.11114 9.55095 3.08337 9.48581 3.08337 9.41773C3.08337 9.34965 3.11114 9.28451 3.16026 9.23737L3.85584 8.56972C3.95257 8.47687 4.10534 8.47687 4.20207 8.56972L9.58337 13.7349L14.9647 8.56972C15.0614 8.47687 15.2142 8.47687 15.3109 8.56972L16.0065 9.23737Z"
          fill="#0BA5EC"
          stroke="#0BA5EC"
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};
