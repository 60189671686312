import React from 'react';
import { Modal } from './Modal';
import { AccountNotification } from 'components/AccountNotification';

type ModalProps = {
  isOpen: boolean;
  closeModal: () => void;
};

export const RequestCompleteAccountModal = ({ isOpen, closeModal }: ModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      title={''}
      onClose={closeModal}
      hasCloseBtn
      className={`max-w-[640px] h-[362px] !pt-6`}
    >
      <AccountNotification isShowCTA className="mt-[9px]" imgClassName="w-[120px]" />
    </Modal>
  );
};
