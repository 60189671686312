import { LiquityChartItem } from 'core/types';
import { formatValueTrading } from 'core/utils';
import React, { useEffect, useState } from 'react';

type LiquidityChartProps = {
  data: LiquityChartItem[];
};

const LiquidityChart = ({ data }: LiquidityChartProps) => {
  const [max, setMax] = useState(0);
  const checkMax = (data: any[]) => {
    let max = 0;
    data?.forEach(item => {
      if (item.price > max) {
        max = item.price;
      }
    });
    return max;
  };

  useEffect(() => {
    setMax(checkMax(data));
  }, [data]);

  const getColorChart = (type: string) => {
    switch (type) {
      case 'MONTH':
        return 'bg-blue-600';
      case 'WEEK':
        return 'bg-blue-700';
      case 'LAST_DAY':
        return 'bg-blue-800';
      default:
        return 'bg-blue-900';
    }
  };

  return (
    <>
      {data?.map((item: LiquityChartItem, index: number) => {
        return (
          <div
            className="flex justify-between text-xs font-normal leading-[18px] tracking-[-0.6px]"
            key={index}
          >
            <div className="max-w-[390px] w-full">
              <div
                className={`pr-[8%] min-w-fit`}
                style={{
                  width: `${
                    item?.price === max && item?.price !== 0
                      ? 100
                      : item.price
                      ? (item.price / max) * 100
                      : 0
                  }%`
                }}
              >
                <div
                  className={`h-[18px] w-[100%] overflow-hidden text-right rounded-tr-sm rounded-tb-sm px-[6px] progress-bar-animation ${getColorChart(
                    item.type
                  )}`}
                >
                  <p>{formatValueTrading(item.price)} đ</p>
                </div>
              </div>
            </div>
            <div className="min-w-[84px]">
              <p className="text-cadetGrey text-right">
                {item.type === 'MONTH'
                  ? 'Trung bình tháng'
                  : item.type === 'WEEK'
                  ? 'Trung bình tuần'
                  : item.type === 'LAST_DAY'
                  ? 'Hôm trước'
                  : 'Hôm nay'}
              </p>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default LiquidityChart;
