import styled from '@emotion/styled';
import { formatCurrency2Decimal } from 'core/utils';
import React, { useEffect, useState } from 'react';
import { RiArrowDownSFill, RiArrowUpSFill } from 'react-icons/ri';

type ValuationChartProps = {
  highPrice: number;
  averagePrice: number;
  lowPrice: number;
  lastPrice: number;
};

const progressStyle = 'h-3 rounded-sm bg-primary';

export const ValuationChart = ({
  highPrice,
  averagePrice,
  lowPrice,
  lastPrice
}: ValuationChartProps) => {
  const [maxPrice, setMaxPrice] = useState(0);

  const checkMax = (data: number[]) => {
    let max = 0;
    data?.forEach(item => {
      if (item > max) {
        max = item;
      }
    });
    return max;
  };

  useEffect(() => {
    setMaxPrice(checkMax([highPrice, averagePrice, lowPrice]));
  }, [highPrice, averagePrice, lowPrice]);

  return (
    <div className="flex flex-col gap-2 text-sm font-normal leading-5">
      <div className="py-1 flex">
        <div className="min-w-[67px] items-center">
          <p>Cao</p>
        </div>
        <div className="ml-4 w-[422px] mr-[31px] flex items-center relative">
          <div
            className="absolute top-[-8px] flex flex-col items-center"
            style={{
              left:
                lastPrice > maxPrice
                  ? '98.29%'
                  : lastPrice
                  ? `calc(${(lastPrice / maxPrice) * 100}% - 1.71%)`
                  : 0,
            }}
          >
            <div className="w-[14.44px] h-[10.9px] flex items-center justify-center border border-solid border-darkCerulean">
              <RiArrowDownSFill className="text-base text-darkCerulean" />
            </div>
            <div className="h-[90px] w-[1px] border border-dashed border-darkCerulean relative">
              <div className="w-[92px] h-[34px] rounded bg-black_blue_light flex items-center justify-center absolute bottom-[-46px] left-[-46px]">
                <div className="absolute top-[-21px]">
                  <RiArrowUpSFill className="text-4xl text-black_blue_light" />
                </div>
                <p className="text-xs font-normal leading-[18px] tracking-[-0.6px]">
                  Giá TT:{' '}
                  {formatCurrency2Decimal(lastPrice ? lastPrice / 1000 : 0)}
                </p>
              </div>
            </div>
          </div>
          <div
            className="h-3"
            style={{
              width: `${(highPrice / maxPrice) * 100}%`
            }}
          >
            <div
              className={`w-full h-full ${progressStyle} progress-bar-animation`}
            ></div>
          </div>
        </div>
        <div className="min-w-[54px]">
          <p>{formatCurrency2Decimal(highPrice ? highPrice / 1000 : 0)}</p>
        </div>
      </div>
      <div className="py-1 flex items-center">
        <div className="min-w-[67px]">
          <p>Trung bình</p>
        </div>
        <div className="ml-4 w-[422px] mr-[31px] flex items-center">
          <div
            className="h-3"
            style={{
              width: `${(averagePrice / maxPrice) * 100}%`
            }}
          >
            <div
              className={`w-full h-full ${progressStyle} progress-bar-animation`}
            ></div>
          </div>
        </div>
        <div className="min-w-[54px]">
          <p>
            {formatCurrency2Decimal(averagePrice ? averagePrice / 1000 : 0)}
          </p>
        </div>
      </div>
      <div className="py-1 flex items-center">
        <div className="min-w-[67px]">
          <p>Thấp</p>
        </div>
        <div className="ml-4 w-[422px] mr-[31px] flex items-center">
          <div
            className="h-3"
            style={{
              width: `${(lowPrice / maxPrice) * 100}%`
            }}
          >
            <div
              className={`w-full h-full ${progressStyle} progress-bar-animation`}
            ></div>
          </div>
        </div>
        <div className="min-w-[54px]">
          <p>{formatCurrency2Decimal(lowPrice ? lowPrice / 1000 : 0)}</p>
        </div>
      </div>
    </div>
  );
};
