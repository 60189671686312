import React, { useEffect, useState } from 'react';
import { formatStockPrice } from 'core/utils';
import { usePrevious } from 'core/hooks';

type Props = {
  last_price: number;
  ref_price: number;
  ceiling: number;
  floor: number;
  className?: string;
};

enum PRICE_STATUS {
  NO_CHANGE,
  INCREASE,
  DECREASE
}

export const StockPrice = ({
  last_price,
  ceiling,
  floor,
  ref_price,
  className
}: Props) => {
  const [priceChangeStatus, setPriceChangeStatus] = useState(
    PRICE_STATUS.NO_CHANGE
  );
  const prevPrice = usePrevious(last_price);

  useEffect(() => {
    if (!prevPrice) {
      return;
    }
    if (prevPrice < last_price) {
      setPriceChangeStatus(PRICE_STATUS.INCREASE);
    }
    if (prevPrice > last_price) {
      setPriceChangeStatus(PRICE_STATUS.DECREASE);
    }
  });

  useEffect(() => {
    if (priceChangeStatus !== PRICE_STATUS.NO_CHANGE) {
      const timer = setTimeout(() => {
        setPriceChangeStatus(PRICE_STATUS.NO_CHANGE);
        return () => {
          clearTimeout(timer);
        };
      }, 100);
    }
  }, [priceChangeStatus]);

  const getBackgroundPriceChange = () => {
    if (priceChangeStatus === PRICE_STATUS.INCREASE) {
      return 'bg-high-price text-white';
    }
    if (priceChangeStatus === PRICE_STATUS.DECREASE) {
      return 'bg-lowPriceColor text-white';
    }
    return '';
  };

  const getColorText = () => {
    if (!last_price) {
      return 'text-mustardYellow';
    }
    if (last_price === ceiling) {
      return 'text-ceilingPriceColor';
    }
    if (last_price === floor) {
      return 'text-floorPriceColor';
    }
    if (last_price > ref_price) {
      return 'text-high-price';
    }
    if (last_price < ref_price) {
      return 'text-lowPriceColor';
    }
    return 'text-mustardYellow';
  };

  return (
    <span
      className={`${getColorText()} text-center rounded transition m-[-2px] px-[2px] py-1 ${getBackgroundPriceChange()} ${
        className ? className : ''
      }`}
    >
      {formatStockPrice(last_price)}
    </span>
  );
};
