import React from 'react';

type MultiCircleSupportBoxProps = {
  children: React.ReactNode;
  sizeCircle_1: string;
  sizeCircle_2: string;
  sizeCircle_3: string;
  colorCircle_1: string;
  colorCircle_2: string;
  colorCircle_3: string;
};

const MultiCircleSupportBoxStyle =
  'rounded-full flex justify-center items-center';

const MultiCircleSupportBox = ({
  children,
  sizeCircle_1,
  sizeCircle_2,
  sizeCircle_3,
  colorCircle_1,
  colorCircle_2,
  colorCircle_3
}: MultiCircleSupportBoxProps) => {
  return (
    <div
      className={`${sizeCircle_3} ${colorCircle_3} ${MultiCircleSupportBoxStyle}`}
    >
      <div
        className={`${sizeCircle_2} ${colorCircle_2} ${MultiCircleSupportBoxStyle}`}
      >
        <div
          className={`${sizeCircle_1} ${colorCircle_1} ${MultiCircleSupportBoxStyle}`}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default MultiCircleSupportBox;
