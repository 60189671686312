import React, { useState, useRef, useEffect } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { OnboardingService } from 'core/services';

type Props = {
  isSubmit: boolean;
  callBack: (res: any) => void;
};

export const Signature = ({ isSubmit, callBack }: Props) => {
  const sigCanvas = useRef<any>();
  const [resign, setResign] = useState<any>(false);
  const [signPlaceholder, setSignPlaceholder] = useState<any>(true);
  const [signImgUrl, setSignImgUrl] = useState<any>('');

  const handleSignEnd = () => {
    setSignImgUrl(sigCanvas.current.getTrimmedCanvas().toDataURL('image/png'));
  };

  const handleSignalPadClick = (event: any) => {
    setSignPlaceholder(false);
    setResign(true);
  };

  const handleSubmit = async () => {
    if (!signImgUrl) {
      return;
    }
    const res = await OnboardingService.fetchUrlUpload('SignContract');
    const upload_url = res?.url;
    const base64EncodedImageString = signImgUrl.replace(
      /^data:image\/\w+;base64,/,
      ''
    );
    const fileBuffer = Buffer.from(base64EncodedImageString, 'base64');
    fetch(upload_url, {
      method: 'PUT',
      body: fileBuffer
    })
      .then(async () => {
        const result = await OnboardingService.signContract(res?.file_path);
        callBack(result);
      })
      .catch(() => {
        console.log('Upload failed');
      });
  };

  useEffect(() => {
    if (isSubmit) {
      handleSubmit();
    }
  }, [isSubmit]);

  return (
    <>
      <div className="min-h-[348px] flex flex-col">
        <div className="flex flex-col items-center justify-center">
          <div
            className="border border-[#F0F0F0] rounded-[10px] bg-black flex items-center justify-center w-full max-w-[600px] h-[250px] relative"
            onMouseDown={(event: any) => handleSignalPadClick(event)}
          >
            {signPlaceholder && (
              <div className="flex items-center gap-2 absolute top-[49px]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="20"
                  viewBox="0 0 24 20"
                  fill="none"
                >
                  <g clipPath="url(#clip0_1713_30374)">
                    <path
                      d="M5.23685 19.7103H22.147C22.6626 19.7103 23.0845 19.2884 23.0845 18.7728C23.0845 18.2572 22.6626 17.8236 22.147 17.8236H7.13529L5.23685 19.7103ZM4.06497 18.6322L16.8501 5.85873L14.7994 3.79623L2.00247 16.5814L0.88919 19.183C0.783721 19.4642 1.07669 19.7923 1.35794 19.6751L4.06497 18.6322ZM17.8814 4.85092L19.065 3.69076C19.6626 3.09311 19.6861 2.44858 19.1587 1.90951L18.7603 1.51108C18.233 0.983732 17.5884 1.04233 16.9908 1.61655L15.8072 2.78842L17.8814 4.85092Z"
                      fill="#98A2B3"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1713_30374">
                      <rect
                        width="22.2173"
                        height="18.9961"
                        fill="white"
                        transform="translate(0.867188 0.713867)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                <div className="text-[#98A2B3] font-bold text-lg">
                  Ký tên ở đây
                </div>
              </div>
            )}
            <SignatureCanvas
              penColor="#0BA5EC"
              canvasProps={{ width: 600, height: 250 }}
              ref={sigCanvas}
              onEnd={handleSignEnd}
            />
          </div>
        </div>
        {resign && (
          <div className="flex items-center justify-center mt-3">
            <div
              className="flex gap-[9px] cursor-pointer w-fit"
              onClick={() => {
                sigCanvas.current.clear();
                setSignPlaceholder(true);
                setResign(false);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="19"
                viewBox="0 0 24 19"
                fill="none"
              >
                <path
                  d="M5.23685 18.7103H22.147C22.6626 18.7103 23.0845 18.2884 23.0845 17.7728C23.0845 17.2572 22.6626 16.8236 22.147 16.8236H7.13529L5.23685 18.7103ZM4.06497 17.6322L16.8501 4.85873L14.7994 2.79623L2.00247 15.5814L0.88919 18.183C0.783721 18.4642 1.07669 18.7923 1.35794 18.6751L4.06497 17.6322ZM17.8814 3.85092L19.065 2.69076C19.6626 2.09311 19.6861 1.44858 19.1587 0.909511L18.7603 0.511076C18.233 -0.0162677 17.5884 0.0423263 16.9908 0.616545L15.8072 1.78842L17.8814 3.85092Z"
                  fill="#98A2B3"
                />
              </svg>
              <div className="text-[16px] font-bold leading-[22px] text-primary">
                Ký lại
              </div>
            </div>
          </div>
        )}
        <div className="flex justify-center items-end flex-1">
          <p className="text-sm font-medium leading-5 text-center mb-3">
            Bằng cách ký và chọn đồng ý, tôi đồng ý mở tài khoản tại SBB
            Securities
          </p>
        </div>
      </div>
    </>
  );
};
