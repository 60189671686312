import dayjs from 'dayjs';
import {
  ACCOUNT_ID_TYPE,
  MTA_ACCOUNT_DESCRIPTION_NORMAL,
  MTA_ACCOUNT_DESCRIPTION_MARGIN,
  EKYC,
} from 'core/constant';
import { FirestoreService } from 'core/services/firestore.service';
import JSEncrypt from 'jsencrypt';
import { OrderTypeByMarketType } from 'core/types/StockRecords.type';
import { Base64 } from 'js-base64';
const { XMLParser } = require('fast-xml-parser');
var CryptoJS = require('crypto-js');

const myWindow = window as any;

export const DEFAULT_DATE_FORMAT = 'DD/MM/YY';

export const formatCurrency = (number: number, symbol?: string) => {
  if (!number) {
    return '0';
  }
  if (!symbol) {
    symbol = ',';
  }
  var p = number.toFixed(2).split('.');
  return p[0]
    .split('')
    .reverse()
    .reduce(function (acc, num, i, orig) {
      return num + (num != '-' && i && !(i % 3) ? symbol : '') + acc;
    }, '');
};

export const formatCurrency2Decimal = (number: number, symbol?: string) => {
  if (!number) {
    return '0.00';
  }
  if (!symbol) {
    symbol = ',';
  }
  var p = number.toFixed(2).split('.');
  let rawNumber = '0';
  rawNumber = p[0]
    .split('')
    .reverse()
    .reduce(function (acc, num, i, orig) {
      return num + (num != '-' && i && !(i % 3) ? symbol : '') + acc;
    }, '');
  if (p.length > 1) {
    rawNumber = rawNumber + '.' + p[1];
  }
  return rawNumber;
};

export const formatStockPrice = (number: number) => {
  return formatCurrency2Decimal(number / 1000);
};

export const getColorHighLow = (value: number) => {
  if (value == 0) {
    return '!text-[#FFC92F]';
  }
  if (value > 0) {
    return '!text-[#61CB2F]';
  }
  return '!text-[#F04438]';
};

export const removeAccents = (str: string) => {
  var AccentsMap = [
    'aàảãáạăằẳẵắặâầẩẫấậ',
    'AÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬ',
    'dđ',
    'DĐ',
    'eèẻẽéẹêềểễếệ',
    'EÈẺẼÉẸÊỀỂỄẾỆ',
    'iìỉĩíị',
    'IÌỈĨÍỊ',
    'oòỏõóọôồổỗốộơờởỡớợ',
    'OÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢ',
    'uùủũúụưừửữứự',
    'UÙỦŨÚỤƯỪỬỮỨỰ',
    'yỳỷỹýỵ',
    'YỲỶỸÝỴ',
  ];
  for (var i = 0; i < AccentsMap.length; i++) {
    var re = new RegExp('[' + AccentsMap[i].substr(1) + ']', 'g');
    var char = AccentsMap[i][0];
    str = str.replace(re, char);
  }
  return str;
};

export const formatPercent = (value?: number, integer?: boolean) => {
  if (!value) {
    return 0;
  }
  if (integer) {
    return Math.round(value * 100);
  }
  return Math.round(value * 1000) / 10;
};

export const formatQuantityStock = (value?: number, hasSuffix = true) => {
  if (!value) {
    return `0${hasSuffix ? 'CP' : ''}`;
  }

  const valueToFixed = Math.abs(value).toFixed(1);
  const values = valueToFixed.split('.');
  const decimal = values[1] || '0';

  const integer = values[0]
    .split('')
    .reverse()
    .reduce(function (acc: any, num: any, i: any) {
      return num + (num != '-' && i && !(i % 3) ? ',' : '') + acc;
    }, '');

  return integer + '.' + decimal + (hasSuffix ? 'CP' : '');
};

// Lệnh MP, lấy giá trần tính số CP có thể mua
// Lệnh LO, lấy giá hiện tại tính số CP có thể mua
export const calculatorTotalStockCanBuy = (
  currentPrice: number,
  maxPrice: number,
  accountAvailableBalance: number,
  stockOrderType: string,
  feeOfBuy: number
) => {
  const _fee = (+accountAvailableBalance * +feeOfBuy) / 100;
  if (stockOrderType === 'MP' && maxPrice > 0) {
    if (maxPrice > 0) {
      const availableStock = (accountAvailableBalance - _fee) / maxPrice;
      return Math.floor(availableStock * 10) / 10;
    } else {
      return 0;
    }
  } else {
    // lệnh LO
    if (currentPrice > 0) {
      const availableStock = (accountAvailableBalance - _fee) / (currentPrice * 1000);
      return Math.floor(availableStock * 10) / 10;
    } else {
      return 0;
    }
  }
};

export const formatAndRoundNumberDecimal = (value: number, stepRound?: number, symbol?: string) => {
  if (!value) {
    return '0.0';
  }
  if (!stepRound) {
    stepRound = 1;
  }
  if (!symbol) {
    symbol = ',';
  }
  var p = value.toFixed(stepRound).split('.');
  let rawNumber = '0';
  rawNumber = p[0]
    .split('')
    .reverse()
    .reduce(function (acc: any, num: any, i: any) {
      return num + (num != '-' && i && !(i % 3) ? symbol : '') + acc;
    }, '');

  if (p.length > 1) {
    rawNumber = rawNumber + '.' + p[1];
  }
  return rawNumber;
};

export const formatValueTrading = (v?: number) => {
  if (!v) {
    return 0;
  }
  if (v > 1000000000000) {
    const _v = formatAndRoundNumberDecimal(v / 1000000000);
    return `${_v} k tỷ`;
  }
  if (v > 1000000000) {
    const _v = formatAndRoundNumberDecimal(v / 1000000000);
    return `${_v} tỷ`;
  }
  if (v > 1000000) {
    const _v = formatAndRoundNumberDecimal(v / 1000000);
    return `${_v} tr`;
  }
  if (v > 1000) {
    const _v = formatAndRoundNumberDecimal(v / 1000);
    return `${_v} k`;
  }
  return v;
};

export const getAccountDescription = (key: string, metadata: any) => {
  if (key === ACCOUNT_ID_TYPE.NORMAL || key === ACCOUNT_ID_TYPE.NORMAL_X) {
    return metadata?.MTA_ACCOUNT_DESCRIPTION_NORMAL;
  }
  if (key === ACCOUNT_ID_TYPE.MARGIN || key === ACCOUNT_ID_TYPE.MARGIN_N) {
    return metadata?.MTA_ACCOUNT_DESCRIPTION_MARGIN;
  }
  return '';
};

export const getAccountShortDescription = (key: string) => {
  if (key === ACCOUNT_ID_TYPE.NORMAL || key === ACCOUNT_ID_TYPE.NORMAL_X) {
    return MTA_ACCOUNT_DESCRIPTION_NORMAL;
  }
  if (key === ACCOUNT_ID_TYPE.MARGIN || key === ACCOUNT_ID_TYPE.MARGIN_N) {
    return MTA_ACCOUNT_DESCRIPTION_MARGIN;
  }
  return '';
};

export const getAccountName = (key?: ACCOUNT_ID_TYPE) => {
  if (key === ACCOUNT_ID_TYPE.NORMAL || key === ACCOUNT_ID_TYPE.NORMAL_X) {
    return 'Thường';
  }
  if (key === ACCOUNT_ID_TYPE.MARGIN || key === ACCOUNT_ID_TYPE.MARGIN_N) {
    return 'Ký quỹ';
  }
  return '--';
};

export const formatDate = (date: string | null, format?: string) => {
  if (!date) {
    return '';
  }
  return dayjs(new Date(date)).format(format || DEFAULT_DATE_FORMAT);
};

export const handleErrorMessage = (mess: string) => {
  if (!mess) {
    return '';
  }

  const splitMess = mess?.split('|');

  if (splitMess?.length > 1) {
    return splitMess[1];
  } else {
    return splitMess[0];
  }
};

export const getColorStatusOrder = (status: string) => {
  switch (status.toUpperCase()) {
    case 'FF':
    case 'PF':
      return 'text-high-price';
    case 'CA':
    case 'EX':
    case 'AJ':
    case 'BJ':
      return 'text-carminePink';
    case 'N/A':
    case 'TX':
    case 'RE':
    case 'SUB':
    case 'AP':
    case 'PA':
    case 'NO':
    case 'CR':
      return 'text-mustardYellow';
    default:
      return '';
  }
};

export const encryptData = async (text?: string | null) => {
  if (!text) return null;
  let secretPass = await FirestoreService.getEncryptAesKey();

  const data = CryptoJS.AES.encrypt(JSON.stringify(text), secretPass).toString();

  return data;
};

export const decryptData = async (text?: string | null) => {
  if (!text) return null;
  let secretPass = await FirestoreService.getEncryptAesKey();
  const bytes = CryptoJS.AES.decrypt(text, secretPass);
  const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

  return data;
};

export const checkSavePin = async (pin: string) => {
  const isSavePin = localStorage.getItem('IS_SAVE_P');
  const pinCache = localStorage.getItem('PC');
  if (isSavePin && !pinCache) {
    const encryptPin = await encryptData(pin);
    localStorage.setItem('PC', encryptPin);
  }
};

export const isHidePopupConfirm = async () => {
  const isHide = localStorage.getItem('IS_HIDE_PIN_POPUP');
  const pinCache = localStorage.getItem('PC');
  const decryptPin = await decryptData(pinCache);
  if (isHide && decryptPin) {
    return decryptPin;
  }
  return null;
};

export const parseTime = (timeStr: string) => {
  const [hours, minutes] = timeStr?.split(':')?.map(Number);
  const now = new Date();
  const parsedTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), hours, minutes);
  return parsedTime;
};

export const getOrderTypeByTime = (orderTypeByMarket: OrderTypeByMarketType[]) => {
  const now = new Date();
  const orderTypeByTime =
    orderTypeByMarket.find((i: any) => {
      const startTime = parseTime(i.FromTime);
      const endTime = parseTime(i.ToTime);
      if (now >= startTime && now < endTime) {
        return i;
      }
    })?.OrderTypes || [];
  return orderTypeByTime;
};

export const getDigitCertRequest = async (data: any, certInfo: any) => {
  let signedData = '';
  let digit_cert_request = {};
  try {
    const res = await myWindow?.signXML(JSON.stringify(data), certInfo?.serial);
    if (res) {
      signedData = JSON.parse(JSON.parse(res?.split('[')[1]?.split(']')[0]))?.data;
      const base64Data = Base64.decode(signedData);
      const parser = new XMLParser();
      const xmlParserData = parser.parse(base64Data);
      const signatureBase64 = xmlParserData?.p?.Signature?.SignatureValue;

      digit_cert_request = {
        original_data: JSON.stringify(data),
        signed_data: signedData || '',
        cert_base64: certInfo?.base64,
        signature_base64: signatureBase64,
        serial_id: certInfo?.serial,
        subject_cn: certInfo?.subjectCN,
        issuer_cn: certInfo?.issuerCN,
        not_before: dayjs(certInfo?.notBefore).add(7, 'hours').toISOString(),
        not_after: dayjs(certInfo?.notAfter).add(7, 'hours').toISOString(),
        tax_number: certInfo?.taxNumber,
        personal_id: certInfo?.personalId || '',
        passport: certInfo?.passport,
      };
    }
  } catch (error) {
    console.log('SignCMS Error:', error);
  }
  return digit_cert_request;
};
