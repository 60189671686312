import React from 'react';
import { StockSymbol } from 'components/stockSymbol/StockSymbol';
import StockList from './components/StockList';
import { TitleSection } from 'components';

const MarketPage = () => {
  return (
    <div className="min-h-full bg-blackBlue p-2">
      <div className="rounded-lg border-style !border-t-0 bg-black">
        <TitleSection title="Các chỉ số" />
        <div className="p-4 max-[1280px]:overflow-x-scroll scrollbar-horizontal">
          <StockSymbol />
        </div>
      </div>
      <div className="mt-2">
        <StockList />
      </div>
    </div>
  );
};

export default MarketPage;
