import { SearchIcon } from 'assets/icons';
import React, { useState } from 'react';
import StockOptionSelect from './StockOptionSelect';
import useComponentVisible from 'core/hooks/UseComponentVisible';

const StockSearchPopup = ({
  disabled,
  symbolParam,
  onChaneSymbolParam,
  onChangeActionType
}: {
  disabled?: boolean;
  symbolParam?: string;
  onChaneSymbolParam?: (symbol: string) => void;
  onChangeActionType?: () => void;
}) => {
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);
  const [symbol, setSymbol] = useState<string>('');
  return (
    <div ref={ref} className="relative">
      <input
        value={isComponentVisible ? symbol : symbolParam}
        type="text"
        className="input-search-stock border-style"
        onFocus={() => {
          setSymbol('');
          setIsComponentVisible(true);
        }}
        disabled={disabled}
        onChange={e => {
          setSymbol(e.target.value);
        }}
      />
      <div className="p-2 bg-yankeesBlue w-10 h-[38px] rounded-r-[6px] absolute right-[1px] top-[1px] flex justify-center items-center cursor-pointer">
        <SearchIcon className="w-[25px] h-[25px]" color="#F2F4F7" />
      </div>

      {isComponentVisible && (
        <StockOptionSelect
          symbol={symbol}
          onSelectItem={v => {
            setIsComponentVisible(false);
            onChaneSymbolParam && onChaneSymbolParam(v);
            onChangeActionType && onChangeActionType();
          }}
        />
      )}
    </div>
  );
};

export default StockSearchPopup;
