import { PasswordVisibilityToggle } from 'components/PasswordVisibilityToggle';
import { Button } from 'components/buttons/Button';
import { Modal } from 'components/modal/Modal';
import { AccountService } from 'core';
import { sha256 } from 'js-sha256';
import React, { useEffect, useState } from 'react';

enum changePinSteps {
  CURRENT_PIN,
  CHANGE_PIN,
  CHANGE_PIN_SUCCESS,
}

type ChangePinModelProps = {
  isShowChangePINModal: boolean;
  setIsShowChangePINModal: (isShow: boolean) => void;
};

const ChangePinModel = ({ isShowChangePINModal, setIsShowChangePINModal }: ChangePinModelProps) => {
  const [changePINStep, setChangePINStep] = useState(changePinSteps.CURRENT_PIN);
  const [oldPin, setOldPin] = useState('');
  const [newPin, setNewPin] = useState('');
  const [error, setError] = useState('');
  const [isShowPin, setIsShowPin] = useState(false);

  const handleCloseModal = () => {
    setIsShowChangePINModal(false);
    setTimeout(() => {
      setError('');
      setOldPin('');
      setNewPin('');
      setIsShowPin(false);
      setChangePINStep(changePinSteps.CURRENT_PIN);
    }, 800);
  };

  const checkPinIsNumber = (pin: string) => {
    return /^\d+$/.test(pin);
  };

  const handleStepChangePIN = async () => {
    if (changePINStep === changePinSteps.CURRENT_PIN) {
      if (oldPin.length < 6 || !checkPinIsNumber(oldPin)) {
        setError('Mã PIN không hợp lệ');
      } else {
        const params = {
          pin: sha256(oldPin),
        };
        const res = await AccountService.verifyPin(params);
        if (res?.data?.status === 'Fail') {
          setError(res?.data?.error_message.split('|')[1]);
        } else {
          setError('');
          setIsShowPin(false);
          setChangePINStep(changePinSteps.CHANGE_PIN);
        }
      }
    } else if (changePINStep === changePinSteps.CHANGE_PIN) {
      if (newPin.length < 6 || !checkPinIsNumber(newPin)) {
        setError('Mã PIN không hợp lệ');
      } else if (newPin === oldPin) {
        setError('Mã PIN mới không được trùng với mã PIN cũ');
      } else {
        const params = {
          pin_old: sha256(oldPin),
          pin_new: newPin,
        };
        const res = await AccountService.updatePin(params);
        if (res?.data?.status === 'Fail') {
          setError(res?.data?.error_message.split('|')[1]);
        } else {
          setError('');
          setChangePINStep(changePinSteps.CHANGE_PIN_SUCCESS);
          localStorage.removeItem('IS_HIDE_PIN_POPUP');
          localStorage.removeItem('PC');
          localStorage.removeItem('IS_SAVE_P');
        }
      }
    }
  };

  return (
    <div>
      <Modal
        isOpen={isShowChangePINModal}
        title={`${
          changePINStep === changePinSteps.CURRENT_PIN
            ? 'Nhập mã PIN hiện tại'
            : changePINStep === changePinSteps.CHANGE_PIN
            ? 'Nhập mã PIN mới'
            : 'Đổi mã PIN thành công'
        }`}
        classNameTitle={`${
          changePINStep === changePinSteps.CHANGE_PIN_SUCCESS
            ? '!text-xl !font-semibold !leading-[26px]'
            : ''
        }`}
        subtitle={`
        ${
          changePINStep === changePinSteps.CHANGE_PIN_SUCCESS
            ? 'Quý khách vừa đổi mã PIN thành công. Hệ thống sẽ lưu lại mã PIN mới cho những lần giao dịch tiếp theo.'
            : 'Lộ mã PIN có thể ảnh hưởng tới bảo mật tài khoản của bạn.'
        }
        `}
        subtitleClassName={`!text-[14px] !font-normal leading-5 ${
          changePINStep === changePinSteps.CHANGE_PIN_SUCCESS &&
          'text-text_main_light !text-base !leading-[22px]'
        }`}
        onClose={() => {}}
        className={`max-w-[640px]`}
        type={`${changePINStep === changePinSteps.CHANGE_PIN_SUCCESS ? 'success' : 'lock'}`}
      >
        {changePINStep === changePinSteps.CURRENT_PIN && (
          <div className="pt-3">
            <div className="form-input relative">
              <label htmlFor="" className="text-sm font-normal leading-5 required-after">
                Mã PIN hiện tại
              </label>
              <input
                type={isShowPin ? 'text' : 'password'}
                placeholder={'Nhập mã PIN hiện tại'}
                className={`form-input__input ${error && 'form-input__input_error'}`}
                onChange={e => {
                  if (e.target.value.length <= 6) {
                    setOldPin(e.target.value);
                    setError('');
                  }
                }}
                value={oldPin}
              ></input>
              <PasswordVisibilityToggle
                toggleVisibility={() => {
                  setIsShowPin(!isShowPin);
                }}
                visible={isShowPin}
              />
              {error && (
                <div className="mt-[2px]">
                  <p className="text-sm font-normal leading-5 text-carminePink">{error}</p>
                </div>
              )}
            </div>
          </div>
        )}
        {changePINStep === changePinSteps.CHANGE_PIN && (
          <div className="pt-3">
            <div className="form-input relative">
              <label htmlFor="" className="text-sm font-normal leading-5">
                Mã PIN mới
                <span className="text-carminePink">*</span>
              </label>
              <input
                type={isShowPin ? 'text' : 'password'}
                placeholder={'Nhập mã PIN mới'}
                className={`form-input__input ${error && 'form-input__input_error'}`}
                onChange={e => {
                  if (e.target.value.length <= 6) {
                    setNewPin(e.target.value);
                    setError('');
                  }
                }}
                value={newPin}
              ></input>
              <PasswordVisibilityToggle
                toggleVisibility={() => {
                  setIsShowPin(!isShowPin);
                }}
                visible={isShowPin}
              />
              {error && (
                <div className="mt-[2px]">
                  <p className="text-sm font-normal leading-5 text-carminePink">{error}</p>
                </div>
              )}
            </div>
          </div>
        )}

        <div className={`flex items-center gap-3 ${error ? 'mt-9' : 'mt-[64px]'}`}>
          {changePINStep !== changePinSteps.CHANGE_PIN_SUCCESS && (
            <Button className="flex-1" variant="secondary" onClick={handleCloseModal}>
              Đóng
            </Button>
          )}

          {changePINStep === changePinSteps.CHANGE_PIN_SUCCESS ? (
            <Button variant="primary" className="flex-1" onClick={handleCloseModal}>
              Xác nhận
            </Button>
          ) : (
            <Button variant="primary" className="flex-1" onClick={handleStepChangePIN}>
              Tiếp theo
            </Button>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default ChangePinModel;
