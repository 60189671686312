import React, { useEffect, useState, useRef } from 'react';
import { Button } from 'components/buttons/Button';
import {
  StockPercent,
  StockPrice,
  FavoriteSymbol,
  StockVolume
} from 'components';
import { AccountService, FirestoreService } from 'core';
import { formatValueTrading } from 'core/utils';
import { onSnapshot } from 'firebase/firestore';
import { useInViewport } from 'react-in-viewport';
import { useNavigate } from 'react-router-dom';

const StockRow = ({
  stock,
  showFull,
  handleClickStock
}: {
  stock: any;
  showFull?: boolean;
  handleClickStock?: (stock: any) => void;
}) => {
  const navigator = useNavigate();
  const myRef = useRef<any>();
  const { inViewport } = useInViewport(myRef);
  const [data, setData] = useState<any>(stock);

  useEffect(() => {
    if (inViewport && stock) {
      const unsubscribe = onSnapshot(
        FirestoreService.getStockBySymbol(stock?.symbol),
        (querySnapshot: any) => {
          const data: any = querySnapshot?.docs.map((docSnapshot: any) =>
            docSnapshot.data()
          );
          if (data?.length > 0) {
            setData(data[0]);
          }
        },
        (error: any) => {
          console.log(error);
        }
      );
      return () => {
        unsubscribe();
      };
    }
  }, [inViewport]);

  return (
    <div
      ref={myRef}
      className={`flex items-center py-1 gap-2 border-b border-[#1D2939] h-[36px] ${
        showFull ? 'xl:gap-[8%]' : 'xl:gap-[4%] hover-buy-stock'
      }`}
    >
      <div
        className={`flex items-center gap-2 ${
          showFull ? 'w-[14.5%]' : 'w-[20%]'
        }`}
      >
        <FavoriteSymbol symbol={stock?.symbol} />
        <span
          className={`${!!handleClickStock ? 'cursor-pointer w-full' : ''}`}
          onClick={() => {
            if (handleClickStock) {
              handleClickStock(stock?.symbol);
            }
          }}
        >
          {stock?.symbol}
        </span>
      </div>
      <div
        className={`flex items-center justify-end ${
          showFull ? 'w-[13%]' : 'w-[22%]'
        }`}
      >
        <StockPrice
          ceiling={data?.ceiling || 0}
          floor={data?.floor || 0}
          last_price={data?.last_price || 0}
          ref_price={data?.ref_price || 0}
        />
      </div>
      <div
        className={`flex items-center justify-end ${
          showFull ? 'w-[14.5%]' : 'w-[18%]'
        }`}
      >
        <StockPercent
          percent={data?.ratio_change || data?.change_ratio * 100}
          ceiling={data?.ceiling || 0}
          floor={data?.floor || 0}
          last_price={data?.last_price || 0}
          ref_price={data?.ref_price || 0}
        />
      </div>
      <div
        className={`flex items-center justify-end w-[16%] ${
          showFull ? 'w-[16%]' : 'flex-1 hover-stock-volume'
        }`}
      >
        <StockVolume value={data?.total_vol || data?.volume} />
      </div>
      {showFull ? (
        <div className="flex-1 flex items-center justify-end">
          <Button
            variant="green"
            className="w-[50px] h-[26px] text-xs font-bold leading-[18px] tracking-[-0.48px] border-none"
            onClick={() =>
              navigator(`/stock-order?symbol=${stock?.symbol}&action=B`)
            }
          >
            Mua
          </Button>
        </div>
      ) : null}
      <div
        className={`flex-1 flex items-center justify-end ${
          showFull ? 'hidden' : 'hover-buy-button'
        }`}
      >
        <Button
          variant="green"
          className="w-[50px] h-5 text-xs font-bold leading-[18px] tracking-[-0.48px] border-none"
          onClick={() =>
            navigator(`/stock-order?symbol=${stock?.symbol}&action=B`)
          }
        >
          Mua
        </Button>
      </div>
    </div>
  );
};

export default StockRow;
