import React, { useState, ReactNode, useEffect, useMemo } from 'react';

interface LoadingContextType {
  isLoading?: boolean;
  showLoading: () => void;
  hideLoading: () => void;
}

type Props = {
  children: ReactNode;
};

const LoadingContext = React.createContext<LoadingContextType>(
  {} as LoadingContextType
);

export const LoadingProvider: React.FC<Props> = ({ children }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const showLoading = React.useCallback(() => {
    setIsLoading(true);
  }, []);

  const hideLoading = React.useCallback(() => {
    setIsLoading(false);
  }, []);

  const value = useMemo(
    () => ({
      isLoading,
      showLoading,
      hideLoading
    }),
    [isLoading, showLoading, hideLoading]
  );

  return (
    <LoadingContext.Provider value={value}>{children}</LoadingContext.Provider>
  );
};

export function useLoading() {
  return React.useContext(LoadingContext);
}

export default LoadingContext;
