import React, { useEffect, useState } from 'react';
import newsImg from '../../../assets/images/default_news_image.jpg';
import { PercentBox } from './PercentBox';
import { Link } from 'react-router-dom';
import { getDoc, onSnapshot } from 'firebase/firestore';
import { FirestoreService } from 'core';
import { NewsItemType } from 'core/types';

type NewsItemProps = {
  data?: NewsItemType;
};

export const NewsItem = ({ data }: NewsItemProps) => {
  const [stockPercent, setStockPercent] = useState<any>([]);

  const caculatePublicTime = (time: any) => {
    const now = new Date().getTime();
    const publicTime = new Date(time).getTime();
    const distance = now - publicTime;
    const day = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hour = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const min = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    if (day > 0) {
      return `${day} ngày trước`;
    }
    if (hour > 0) {
      return `${hour} giờ trước`;
    }
    if (min > 0) {
      return `${min} phút trước`;
    }
    return 'Vừa xong';
  };

  const handleFetchRatioChange = async (symbol: string) => {
    const res = await getDoc(FirestoreService.readStockSymbol(symbol));
    const ratioChange = res?.data();
    return ratioChange;
  };

  useEffect(() => {
    data?.stocks?.forEach(async (item: string) => {
      // check item is exist in stockPercent
      const isExist = stockPercent?.find((stock: any) => stock.symbol === item);
      if (!isExist) {
        const ratioChange = await handleFetchRatioChange(item);
        setStockPercent((prev: any) => [
          ...prev,
          { symbol: item, ratioChange: ratioChange?.ratio_change }
        ]);
      }
    });
  }, []);

  return (
    <Link
      to={data?.article_url || ''}
      className="flex gap-4 w-1/3 pr-10"
      target="_blank"
    >
      <div className="min-w-[96px] ">
        {data?.image_url && (
          <img
            src={data?.image_url || newsImg}
            alt=""
            className="w-[96px] h-[96px] rounded-xl bg-contain"
          />
        )}
      </div>
      <div className="flex flex-col justify-between">
        <div className={`flex flex-col gap-2`}>
          {data?.stocks.length === 0 && (
            <p className="text-sm font-normal leading-5">
              {data?.category_name}
            </p>
          )}
          {data?.stocks?.length > 0 && (
            <div className="flex flex-wrap gap-2 text-xs font-normal leading-[18px] tracking-[-0.6px]">
              {data?.stocks.map((item: string) => {
                return (
                  <div className="flex gap-1" key={item}>
                    <p>{item}</p>
                    {stockPercent.length > 0 && (
                      <PercentBox
                        percent={
                          stockPercent?.find(
                            (stock: any) => stock.symbol === item
                          )?.ratioChange
                        }
                      />
                    )}
                  </div>
                );
              })}
            </div>
          )}

          <p className="text-lg font-semibold leading-6 line-clamp-2">
            {data?.title}
          </p>
        </div>
        <div
          className={`flex gap-[2px] text-sm font-normal leading-5 text-text_main_light`}
        >
          {data?.soucre_type_url && (
            <>
              <p>{data?.soucre_type_url}</p>
              <p>|</p>
            </>
          )}
          <p>{caculatePublicTime(data?.published_date)}</p>
        </div>
      </div>
    </Link>
  );
};
