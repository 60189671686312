import React from 'react';
import Routes from 'core/routes/Routes';
import { AuthProvider } from 'react-oidc-context';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  AccountProvider,
  LoadingProvider,
  MarketProvider
} from 'core/contexts';
import { ConfigProvider } from 'antd';

const oidcConfig = {
  authority: `${process.env.REACT_APP_authority}`,
  client_id: `${process.env.REACT_APP_client_id}`,
  response_type: 'code',
  scope: `${process.env.REACT_APP_scope}`,
  redirect_uri: `${process.env.REACT_APP_redirect_uri}`,
  post_logout_redirect_uri: `${process.env.REACT_APP_post_logout_redirect_uri}`
};

const App: React.FC = () => (
  <ConfigProvider
    theme={{
      token: {
        fontFamily: 'Plus Jakarta Sans',
        colorPrimary: '#0BA5EC',
        colorBgContainer: '#0C111D',
        colorText: '#fff'
      },
      components: {
        Table: {
          borderColor: '#475467'
        }
      }
    }}
  >
    <AuthProvider {...oidcConfig}>
      <LoadingProvider>
        <AccountProvider>
          <MarketProvider>
            <Routes />
            <ToastContainer autoClose={2000} hideProgressBar={true} />
          </MarketProvider>
        </AccountProvider>
      </LoadingProvider>
    </AuthProvider>
  </ConfigProvider>
);
export default App;
